import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import React from 'react';
import { Timestamp } from 'shared/types/timestamp';

import * as Styled from './list-section-month-header.style';

export type ListSectionMonthHeaderProps = {
  date: Timestamp;
};

export const ListSectionMonthHeader: React.FC<ListSectionMonthHeaderProps> =
  React.memo(({ date }) => (
    <Styled.Container>
      <Typography variant="h6" component="span">
        {format(date, 'LLLL')}
      </Typography>
    </Styled.Container>
  ));
