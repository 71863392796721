import { useMemo } from 'react';
import { characterWidth } from 'shared/assets/styles';

export type Options = {
  ticks: number[];
  formatter?: (tick: number) => string;
  target?: number;
};

export const useYAxisWidth = ({ ticks, formatter, target }: Options) =>
  useMemo(() => {
    if (!formatter) {
      return 0;
    }
    // Gather all possible values (data and target)
    const values = [
      ...ticks.map((value) => formatter(value)),
      target ? formatter(target) : undefined,
    ].filter(Boolean) as string[];

    // Convert to strings and find the longest by character length
    const longestValue =
      values.sort((valueA, valueB) => valueB.length - valueA.length)[0] ?? '';
    return longestValue.length * characterWidth + 4;
  }, [target, formatter, ticks]);
