import { useTimeframeTime } from 'features/user';
import React from 'react';
import { LifeAreaInsightsCard } from 'shared/components/connected/life-area-insights-card';
import { LifeArea } from 'shared/types/life-area';

import { useLifeAreaProgress } from '../../hooks/use-life-area-progress';

export type LifescanInsightsCardProps = {
  lifeArea: LifeArea;
};

export const LifescanMetricInsightsCard: React.FC<
  LifescanInsightsCardProps
> = ({ lifeArea }) => {
  const { startDate, endDate } = useTimeframeTime({
    weekEndsWithStartOfDay: true,
  });
  const { entries, target, average, current } = useLifeAreaProgress(lifeArea, {
    startDate,
    endDate,
  });

  return (
    <LifeAreaInsightsCard
      lifeArea={lifeArea}
      entries={entries}
      startDate={startDate}
      endDate={endDate}
      currentValue={current}
      target={target}
      average={average}
      isLink
    />
  );
};
