import { rgba } from 'polished';
import { BlurHashImage } from 'shared/components/connected/blur-hash-image';
import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export enum DataTypes {
  Title = 'title',
  Deadline = 'deadline',
  Progress = 'progress',
  Tasks = 'tasks',
  LifeArea = 'life-area',
  Interactions = 'interactions',
}

const dataTypeToWidth = (type: DataTypes) => {
  switch (type) {
    case DataTypes.Deadline:
      return css`
        flex: 0 0 15.6rem;
        max-width: 15.6rem;
      `;
    case DataTypes.Progress:
      return css`
        flex: 0 0 8rem;
        max-width: 8rem;
      `;
    case DataTypes.Tasks:
      return css`
        flex: 0 0 7rem;
        max-width: 7rem;
      `;
    case DataTypes.LifeArea:
      return css`
        flex: 0 0 16.6rem;
        max-width: 16.6rem;
      `;
    case DataTypes.Interactions:
      return css`
        flex: 0 0 4.8rem;
        max-width: 4.8rem;
      `;
    default:
      return css`
        min-width: 0;
      `;
  }
};

export const Container = styled.div<{ $isArchived: boolean }>`
  container-type: inline-size;
  display: flex;
  width: 100%;

  border: 1px solid ${({ theme }) => theme.palette.grey['100']};
  border-radius: ${({ theme }) => theme.spacing(3)};
  background: ${({ theme }) => theme.palette.backgroundExtended.card};
  box-shadow: ${({ theme }) => theme.shadowBox.card};

  ${({ $isArchived }) =>
    $isArchived &&
    css`
      opacity: 0.5;
    `}
`;

export const DataContainer = styled.span<{
  $type: DataTypes;
  $isFrozen: boolean;
}>`
  ${({ theme }) => typographyToCss(theme.typography.body2)};
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  gap: ${({ theme, $type }) =>
    theme.spacing($type === DataTypes.Title ? 2 : 1)};

  padding: ${({ theme }) => `0 ${theme.spacing(3)}`};
  height: 4.8rem;

  overflow: hidden;

  ${({ $type }) => dataTypeToWidth($type)};

  ${({ $type, theme }) => {
    switch ($type) {
      case DataTypes.Progress:
        return css`
          color: ${theme.palette.primary.main};
        `;
      case DataTypes.Interactions:
        return css`
          color: ${({ theme }) => theme.palette.grey['500']};
        `;
      case DataTypes.LifeArea:
        return css`
          display: none;

          @container (min-width: 70rem) {
            display: flex;
          }
        `;
      case DataTypes.Tasks:
        return css`
          display: none;

          @container (min-width: 48rem) {
            display: flex;
          }
        `;
      case DataTypes.Deadline:
        return css`
          display: none;

          @container (min-width: 37rem) {
            display: flex;
          }
        `;
    }
  }};

  ${({ $isFrozen, theme }) =>
    $isFrozen &&
    css`
      color: ${theme.palette.text.secondary};
      pointer-events: none;
    `};
`;

export const IllustrationContainer = styled.span`
  position: relative;
  display: block;
`;

export const ImageContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 2.4rem;
  width: 2.4rem;
  min-width: 2.4rem;

  border-radius: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) => theme.palette.brown['100']};
  overflow: hidden;

  font-size: 1.6rem;
  line-height: 62.5%;
  color: ${({ theme }) => theme.palette.brown['300']};
`;

export const Image = styled(BlurHashImage)`
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
`;

export const CompletedContainer = styled.span`
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 1.2rem;
  height: 1.2rem;

  transform: translate(33%, -33%);
  background: ${({ theme }) => theme.palette.success.main};
  border-radius: 50%;

  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.success.contrastText};
`;

export const ColorDot = styled.span<{ $color: string }>`
  position: absolute;
  bottom: ${({ theme }) => `-${theme.spacing(0.75)}`};
  right: ${({ theme }) => `-${theme.spacing(0.75)}`};

  display: block;
  height: 1rem;
  width: 1rem;

  border: ${({ theme }) =>
    `${theme.spacing(0.5)} solid ${theme.palette.background.default};`};
  border-radius: 50%;
  background: ${({ $color }) => $color};
`;

export const Title = styled.span<{ $isCompleted: boolean }>`
  ${({ theme }) => typographyToCss(theme.typography.subtitle2)};

  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ $isCompleted }) =>
    $isCompleted &&
    css`
      text-decoration: line-through;
    `};
`;

export const ProgressButton = styled.button`
  display: flex;
  align-items: center;

  margin: 0;
  padding: ${({ theme }) => theme.spacing(1)};

  border-radius: ${({ theme }) => theme.spacing(3)};
  border: none;
  background: transparent;
  transition: background 200ms;

  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;

  ${({ theme, onClick }) =>
    onClick &&
    css`
      cursor: pointer;

      &:hover {
        background: ${rgba(theme.palette.primary.main, 0.08)};
      }
    `};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: 0;
    `};
`;

export const OverflowSpan = styled.span`
  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const IconContainer = styled.span`
  font-size: 1.6rem;
  line-height: 62.5%;
`;
