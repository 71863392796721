import { useMemo } from 'react';
import { Entry } from 'shared/lib/recharts';

export type Options = {
  startDate?: Date;
  endDate?: Date;
};

export const useXAxisData = (
  data: Entry[],
  { startDate, endDate }: Options,
) => {
  const domain = useMemo<[number, number]>(() => {
    const entryDates = data.map(({ date }) => date.getTime());
    const minDate = Math.min(
      ...entryDates,
      ...(startDate ? [startDate?.getTime()] : []),
    );
    const maxDate = Math.max(
      ...entryDates,
      ...(endDate ? [endDate?.getTime()] : []),
    );
    return [minDate, maxDate];
  }, [data, endDate, startDate]);

  return {
    domain,
  };
};
