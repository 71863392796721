import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfYear,
  isSameMonth,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import { useMemo } from 'react';
import { useToday } from 'shared/contexts/today';
import { useShouldHaveRestOfMonth } from 'shared/hooks/use-should-have-rest-of-month';
import {
  DateSectionOptions,
  overdueSectionID,
  TaskCardDateSection,
} from 'shared/types/orderable-section';
import { formatDateKey } from 'shared/utils/format-date-key';

export const useUpcomingSections = (): TaskCardDateSection[] => {
  const today = useToday();
  const { shouldHaveRestOfMonth, restOfMonthStartDate } =
    useShouldHaveRestOfMonth();

  return useMemo(() => {
    // to prevent restOfTheMonth section and the other months sections to have the same month,
    // we need to see if the `restOfMonthStartDate` is in the same month as the current day.
    // If this is not the case, we need to add an extra month
    const monthCounter =
      shouldHaveRestOfMonth && !isSameMonth(restOfMonthStartDate, today)
        ? 1
        : 0;
    const restOfMonth = shouldHaveRestOfMonth
      ? [
          {
            // 5 days after tomorrow
            id: formatDateKey(endOfDay(addDays(today, 10))),
            type: DateSectionOptions.RestOfMonth,
            startDate: startOfDay(addDays(today, 10)),
            endDate: endOfMonth(addDays(today, 10)),
            deadline: endOfDay(addDays(today, 10)),
            items: [],
            isDroppableOnly: true,
          },
        ]
      : [];

    return [
      {
        // overdue
        id: overdueSectionID,
        type: DateSectionOptions.Day,
        startDate: new Date(0),
        endDate: endOfDay(addDays(today, -1)),
        deadline: endOfDay(addDays(today, -1)),
        items: [],
      },
      {
        // today
        id: formatDateKey(endOfDay(today)),
        type: DateSectionOptions.Day,
        startDate: startOfDay(today),
        endDate: endOfDay(today),
        deadline: endOfDay(today),
        items: [],
      },
      {
        // tomorrow
        id: formatDateKey(endOfDay(addDays(today, 1))),
        type: DateSectionOptions.Day,
        startDate: startOfDay(addDays(today, 1)),
        endDate: endOfDay(addDays(today, 1)),
        deadline: endOfDay(addDays(today, 1)),
        items: [],
      },
      {
        // 3rd day
        id: formatDateKey(endOfDay(addDays(today, 2))),
        type: DateSectionOptions.Day,
        startDate: startOfDay(addDays(today, 2)),
        endDate: endOfDay(addDays(today, 2)),
        deadline: endOfDay(addDays(today, 2)),
        items: [],
      },
      {
        // 4th day
        id: formatDateKey(endOfDay(addDays(today, 3))),
        type: DateSectionOptions.Day,
        startDate: startOfDay(addDays(today, 3)),
        endDate: endOfDay(addDays(today, 3)),
        deadline: endOfDay(addDays(today, 3)),
        items: [],
      },
      {
        // 5th day
        id: formatDateKey(endOfDay(addDays(today, 4))),
        type: DateSectionOptions.Day,
        startDate: startOfDay(addDays(today, 4)),
        endDate: endOfDay(addDays(today, 4)),
        deadline: endOfDay(addDays(today, 4)),
        items: [],
      },
      {
        // 6th day
        id: formatDateKey(endOfDay(addDays(today, 5))),
        type: DateSectionOptions.Day,
        startDate: startOfDay(addDays(today, 5)),
        endDate: endOfDay(addDays(today, 5)),
        deadline: endOfDay(addDays(today, 5)),
        items: [],
      },
      {
        // 7th day
        id: formatDateKey(endOfDay(addDays(today, 6))),
        type: DateSectionOptions.Day,
        startDate: startOfDay(addDays(today, 6)),
        endDate: endOfDay(addDays(today, 6)),
        deadline: endOfDay(addDays(today, 6)),
        items: [],
      },
      {
        // 8th day
        id: formatDateKey(endOfDay(addDays(today, 7))),
        type: DateSectionOptions.Day,
        startDate: startOfDay(addDays(today, 7)),
        endDate: endOfDay(addDays(today, 7)),
        deadline: endOfDay(addDays(today, 7)),
        items: [],
      },
      {
        // 9th day
        id: formatDateKey(endOfDay(addDays(today, 8))),
        type: DateSectionOptions.Day,
        startDate: startOfDay(addDays(today, 8)),
        endDate: endOfDay(addDays(today, 8)),
        deadline: endOfDay(addDays(today, 8)),
        items: [],
      },
      {
        // 10th day
        id: formatDateKey(endOfDay(addDays(today, 9))),
        type: DateSectionOptions.Day,
        startDate: startOfDay(addDays(today, 9)),
        endDate: endOfDay(addDays(today, 9)),
        deadline: endOfDay(addDays(today, 9)),
        items: [],
      },
      ...restOfMonth,
      {
        // 1st month after rest of month
        id: formatDateKey(
          endOfDay(startOfMonth(addMonths(today, monthCounter + 1))),
        ),
        type: DateSectionOptions.Month,
        startDate: startOfMonth(addMonths(today, monthCounter + 1)),
        endDate: endOfMonth(addMonths(today, monthCounter + 1)),
        deadline: endOfDay(startOfMonth(addMonths(today, monthCounter + 1))),
        items: [],
        isDroppableOnly: true,
      },
      {
        // 2nd month after rest of month
        id: formatDateKey(
          endOfDay(startOfMonth(addMonths(today, monthCounter + 2))),
        ),
        type: DateSectionOptions.Month,
        startDate: startOfMonth(addMonths(today, monthCounter + 2)),
        endDate: endOfMonth(addMonths(today, monthCounter + 2)),
        deadline: endOfDay(startOfMonth(addMonths(today, monthCounter + 2))),
        items: [],
        isDroppableOnly: true,
      },
      {
        // 3rd month after rest of month
        id: formatDateKey(
          endOfDay(startOfMonth(addMonths(today, monthCounter + 3))),
        ),
        type: DateSectionOptions.Month,
        startDate: startOfMonth(addMonths(today, monthCounter + 3)),
        endDate: endOfMonth(addMonths(today, monthCounter + 3)),
        deadline: endOfDay(startOfMonth(addMonths(today, monthCounter + 3))),
        items: [],
        isDroppableOnly: true,
      },
      {
        // 4th month after rest of month
        id: formatDateKey(
          endOfDay(startOfMonth(addMonths(today, monthCounter + 4))),
        ),
        type: DateSectionOptions.Month,
        startDate: startOfMonth(addMonths(today, monthCounter + 4)),
        endDate: endOfMonth(addMonths(today, monthCounter + 4)),
        deadline: endOfDay(startOfMonth(addMonths(today, monthCounter + 4))),
        items: [],
        isDroppableOnly: true,
      },
      {
        // 5th month after rest of month
        id: formatDateKey(
          endOfDay(startOfMonth(addMonths(today, monthCounter + 5))),
        ),
        type: DateSectionOptions.Month,
        startDate: startOfMonth(addMonths(today, monthCounter + 5)),
        endDate: endOfMonth(addMonths(today, monthCounter + 5)),
        deadline: endOfDay(startOfMonth(addMonths(today, monthCounter + 5))),
        items: [],
        isDroppableOnly: true,
      },
      {
        // 6th month after rest of month
        id: formatDateKey(
          endOfDay(startOfMonth(addMonths(today, monthCounter + 6))),
        ),
        type: DateSectionOptions.Month,
        startDate: startOfMonth(addMonths(today, monthCounter + 6)),
        endDate: endOfMonth(addMonths(today, monthCounter + 6)),
        deadline: endOfDay(startOfMonth(addMonths(today, monthCounter + 6))),
        items: [],
        isDroppableOnly: true,
      },
      {
        // 7th month after rest of month
        id: formatDateKey(
          endOfDay(startOfMonth(addMonths(today, monthCounter + 7))),
        ),
        type: DateSectionOptions.Month,
        startDate: startOfMonth(addMonths(today, monthCounter + 7)),
        endDate: endOfMonth(addMonths(today, monthCounter + 7)),
        deadline: endOfDay(startOfMonth(addMonths(today, monthCounter + 7))),
        items: [],
        isDroppableOnly: true,
      },
      {
        // 8th month after rest of month
        id: formatDateKey(
          endOfDay(startOfMonth(addMonths(today, monthCounter + 8))),
        ),
        type: DateSectionOptions.Month,
        startDate: startOfMonth(addMonths(today, monthCounter + 8)),
        endDate: endOfMonth(addMonths(today, monthCounter + 8)),
        deadline: endOfDay(startOfMonth(addMonths(today, monthCounter + 8))),
        items: [],
        isDroppableOnly: true,
      },
      {
        // 9th month after rest of month
        id: formatDateKey(
          endOfDay(startOfMonth(addMonths(today, monthCounter + 9))),
        ),
        type: DateSectionOptions.Month,
        startDate: startOfMonth(addMonths(today, monthCounter + 9)),
        endDate: endOfMonth(addMonths(today, monthCounter + 9)),
        deadline: endOfDay(startOfMonth(addMonths(today, monthCounter + 9))),
        items: [],
        isDroppableOnly: true,
      },
      {
        // 10th month after rest of month
        id: formatDateKey(
          endOfDay(startOfMonth(addMonths(today, monthCounter + 10))),
        ),
        type: DateSectionOptions.Month,
        startDate: startOfMonth(addMonths(today, monthCounter + 10)),
        endDate: endOfMonth(addMonths(today, monthCounter + 10)),
        deadline: endOfDay(startOfMonth(addMonths(today, monthCounter + 10))),
        items: [],
        isDroppableOnly: true,
      },
      {
        // rest of year
        id: formatDateKey(
          endOfDay(startOfMonth(addMonths(today, monthCounter + 11))),
        ),
        type: DateSectionOptions.Year,
        startDate: startOfMonth(addMonths(today, monthCounter + 11)),
        endDate: endOfYear(addMonths(today, monthCounter + 11)),
        deadline: endOfDay(startOfMonth(addMonths(today, monthCounter + 11))),
        items: [],
        isDroppableOnly: true,
      },
    ];
  }, [restOfMonthStartDate, shouldHaveRestOfMonth, today]);
};
