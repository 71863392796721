import { InsightsPremiumBanner } from 'pages/insights/components/insights-premium-banner';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from 'shared/components/ui/section';
import { usePremiumFeatureAllowed } from 'shared/hooks/use-premium-feature-allowed';
import { LimitKeys } from 'shared/types/limit-keys';

import { useInsightsHabits } from '../../hooks/use-insights-habits';
import { useListColumns } from '../../hooks/use-list-columns';
import { HabitInsightsCard } from '../habit-insights-card';
import * as Styled from './habits-insights.style';

export const HabitsInsights: React.FC = () => {
  const widthRef = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const habits = useInsightsHabits();
  const { allowed } = usePremiumFeatureAllowed(LimitKeys.Insights, {});

  const listColumns = useListColumns(widthRef);

  return (
    <>
      <Styled.WidthRef ref={widthRef} />
      <Section
        title={t('pages.insights.sections.habits.title')}
        canCollapse
        initialOpen
      >
        <Styled.List $columns={listColumns}>
          {habits.map((habit) => (
            <Styled.ListItem key={habit.id}>
              <HabitInsightsCard habit={habit} />
            </Styled.ListItem>
          ))}

          {!allowed && (
            <Styled.PremiumBannerContainer>
              <InsightsPremiumBanner />
            </Styled.PremiumBannerContainer>
          )}
        </Styled.List>
      </Section>
    </>
  );
};
