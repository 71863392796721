import React from 'react';
import { LifeAreaContextMenu } from 'shared/components/connected/life-area-context-menu';
import { LifeAreaInsightsCard as LifeAreaInsightsCardUi } from 'shared/components/ui/life-area-insights-card';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { useUser } from 'shared/hooks/use-user';
import { initialWeekStartsOn } from 'shared/types/week-days';

export type LifeAreaInsightsCardProps = Omit<
  React.ComponentProps<typeof LifeAreaInsightsCardUi>,
  'onMenuButton' | 'menuButtonRef' | 'weekStartsOn'
> & {};

export const LifeAreaInsightsCard: React.FC<LifeAreaInsightsCardProps> = ({
  lifeArea,
  ...rest
}) => {
  const user = useUser();
  const menuButtonRef = React.useRef<HTMLButtonElement>(null);
  const weekStartsOn = user?.settings?.startOfWeek ?? initialWeekStartsOn;

  const { menuOpen, openMenu, closeMenu } = useOpenMenu();

  return (
    <>
      <LifeAreaInsightsCardUi
        lifeArea={lifeArea}
        onMenuButton={openMenu}
        menuButtonRef={menuButtonRef}
        weekStartsOn={weekStartsOn}
        {...rest}
      />
      {menuOpen && (
        <LifeAreaContextMenu
          lifeArea={lifeArea}
          onClose={closeMenu}
          referenceElement={menuButtonRef}
        />
      )}
    </>
  );
};
