import { scaleTime } from '@visx/scale';
import { useMemo } from 'react';
import { Entry } from 'shared/lib/recharts';

export type Options = {
  data: Entry[];
  width: number;
  yAxisOffset: number;
};

export const useXScale = ({ data, width, yAxisOffset }: Options) =>
  useMemo(() => {
    const dateSortedData = data.toSorted(
      (entryA, entryB) => entryA.date.getTime() - entryB.date.getTime(),
    );
    const startTime = dateSortedData[0].date;
    const endTime = dateSortedData[dateSortedData.length - 1].date;

    const padding = width * 0.05;

    return scaleTime({
      domain: [startTime, endTime],
      range: [padding, width - yAxisOffset - padding],
    });
  }, [data, width, yAxisOffset]);
