import React from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from 'shared/components/ui/section';

import { useInsightsGoals } from '../../hooks/use-insights-goals';
import { useListColumns } from '../../hooks/use-list-columns';
import { GoalInsightsCard } from '../goal-insights-card';
import * as Styled from './goals-insights.style';

export const GoalsInsights: React.FC = () => {
  const widthRef = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const goals = useInsightsGoals();

  const listColumns = useListColumns(widthRef);

  return (
    <>
      <Styled.WidthRef ref={widthRef} />
      <Section
        title={t('pages.insights.sections.goals.title')}
        titleVariant="h5"
        canCollapse
        initialOpen
      >
        <Styled.List $columns={listColumns}>
          {goals.map((goal) => (
            <Styled.ListItem key={goal.id}>
              <GoalInsightsCard goal={goal} />
            </Styled.ListItem>
          ))}
        </Styled.List>
      </Section>
    </>
  );
};
