import Typography from '@mui/material/Typography';
import { format, getMonth } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Timestamp } from 'shared/types/timestamp';

import * as Styled from './list-section-year-header.style';

export type ListSectionYearHeaderProps = {
  date: Timestamp;
};

export const ListSectionYearHeader: React.FC<ListSectionYearHeaderProps> =
  React.memo(({ date }) => {
    const { t } = useTranslation();
    const isBeginningOfYear = getMonth(date) === 0;
    const year = format(date, 'y');

    return (
      <Styled.Container>
        <Typography variant="h6" component="span">
          {isBeginningOfYear
            ? year
            : t('general.labels.rest-of-year.title', { year })}
        </Typography>
      </Styled.Container>
    );
  });
