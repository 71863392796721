import { getDaysInMonth } from 'date-fns';

export enum RoundingMethod {
  Floor = 'floor',
  Ceil = 'ceil',
  Round = 'round',
  Trunc = 'trunc',
}

export type Options = {
  roundingMethod?: RoundingMethod;
};

export const differenceInMonths = (
  laterDate: Date,
  earlierDate: Date,
  { roundingMethod = RoundingMethod.Trunc }: Options = {},
) => {
  const roundingFn = Math[roundingMethod];
  const yearsDiff = laterDate.getFullYear() - earlierDate.getFullYear();
  const monthsDiff = laterDate.getMonth() - earlierDate.getMonth();
  const daysInMonthDiff = laterDate.getDate() - earlierDate.getDate();
  const monthsDiffWithDays =
    monthsDiff + roundingFn(daysInMonthDiff / getDaysInMonth(laterDate));

  return yearsDiff * 12 + monthsDiffWithDays;
};
