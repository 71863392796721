import { and, orderBy, query, where } from 'firebase/firestore';

import { lifescanConverter } from './converters/lifescan';
import { CollectionOptions, getAuthentication, getCollection } from './helpers';

export const getLifescans = async () => {
  const auth = await getAuthentication();

  const conditions = [
    where('roles.all', 'array-contains', auth.currentUser?.uid),
  ];

  return query(
    getCollection(CollectionOptions.Lifescans).withConverter(lifescanConverter),
    and(...conditions),
    orderBy('createdAt', 'desc'),
  );
};
