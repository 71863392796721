import {
  useArchiveLifeArea,
  useCreateCustomLifeAreasAllowed,
  useLifeAreasWithGoals,
} from 'features/life-areas';
import { useUpdateLifeAreaOrder, useUserHadTrial } from 'features/user';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LifeAreaDataGrid,
  SelfSortingLifeAreaDataGrid,
} from 'shared/components/ui/life-area-data-grid';
import { PremiumBanner } from 'shared/components/ui/premium-banner';
import { TitledCollapse } from 'shared/components/ui/titled-collapse';
import { useOpenDeleteLifeAreaDialog } from 'shared/contexts/delete-life-area';
import {
  useOpenCreateLifeAreaDialog,
  useOpenEditLifeAreaDialog,
} from 'shared/contexts/life-area-form';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useUser } from 'shared/hooks/use-user';
import { ID } from 'shared/types/id';
import { sortItems } from 'shared/utils/sort-items';
import { useTheme } from 'styled-components';

import * as Styled from './overview.style';

export const Overview: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { goals, lifeAreas } = useLifeAreasWithGoals();
  const user = useUser();
  const userHadTrial = useUserHadTrial();

  const updateOrder = useUpdateLifeAreaOrder();
  const openEditDialog = useOpenEditLifeAreaDialog();
  const openCreateDialog = useOpenCreateLifeAreaDialog();
  const openDeleteDialog = useOpenDeleteLifeAreaDialog();
  const archiveLifeArea = useArchiveLifeArea();

  const openPremiumDialog = useOpenPremiumDialog();
  const {
    allowed: createLifeAreaAllowed,
    enforced: createLifeAreaEnforced,
    limit: createLifeAreaLimit,
    total: createLifeAreaTotal,
  } = useCreateCustomLifeAreasAllowed();

  const onEdit = (id: ID) => {
    const lifeArea = lifeAreas.find((lifeArea) => lifeArea.id === id);
    !!lifeArea && openEditDialog(lifeArea, id);
  };

  const onDelete = (id: ID) => {
    const lifeArea = lifeAreas.find((lifeArea) => lifeArea.id === id);
    !!lifeArea && openDeleteDialog(lifeArea);
  };

  const onArchive = (id: ID) => {
    const lifeArea = lifeAreas.find((lifeArea) => lifeArea.id === id);
    !!lifeArea && archiveLifeArea(lifeArea);
  };

  const activeLifeAreas = useMemo(
    () =>
      sortItems(
        lifeAreas.filter(
          ({ frozenAt, archivedAt }) => !frozenAt && !archivedAt,
        ),
        user?.settings?.lifeAreaSorting ?? [],
        'id',
      ),
    [lifeAreas, user?.settings?.lifeAreaSorting],
  );

  const archivedLifeAreas = useMemo(
    () =>
      sortItems(
        lifeAreas.filter(({ archivedAt }) => !!archivedAt),
        user?.settings?.lifeAreaSorting ?? [],
        'id',
      ),
    [lifeAreas, user?.settings?.lifeAreaSorting],
  );

  const frozenLifeAreas = useMemo(
    () => lifeAreas.filter(({ frozenAt }) => !!frozenAt),
    [lifeAreas],
  );

  const onCreateLifeArea = () =>
    createLifeAreaAllowed ? openCreateDialog() : openPremiumDialog();

  return (
    <Styled.Container>
      <SelfSortingLifeAreaDataGrid
        lifeAreas={activeLifeAreas}
        goals={goals}
        onReorder={updateOrder}
        onEditLifeArea={onEdit}
        onDeleteLifeArea={onDelete}
        onArchiveLifeArea={onArchive}
        showPremiumLabel={!createLifeAreaAllowed}
        onCreateLifeArea={!theme.isMobile ? onCreateLifeArea : undefined}
      />
      {!createLifeAreaAllowed && (
        <>
          <Styled.PremiumBannerContainer>
            <PremiumBanner
              title={t(
                createLifeAreaEnforced
                  ? 'pages.life-areas.premium-banner.title'
                  : 'pages.life-areas.premium-banner.not-enforced.title',
                { total: createLifeAreaTotal, limit: createLifeAreaLimit },
              )}
              description={t(
                createLifeAreaEnforced
                  ? userHadTrial
                    ? 'pages.life-areas.premium-banner.description'
                    : 'pages.life-areas.premium-banner.try-for-free.description'
                  : 'pages.life-areas.premium-banner.not-enforced.description',
                { limit: createLifeAreaLimit },
              )}
              onClick={openPremiumDialog}
              isTrial={!userHadTrial}
            />
          </Styled.PremiumBannerContainer>
          {!createLifeAreaAllowed && (
            <LifeAreaDataGrid
              goals={goals}
              lifeAreas={frozenLifeAreas}
              onCreateLifeArea={openPremiumDialog}
              onLifeArea={openPremiumDialog}
              isFrozen
              hideHeader
              showPremiumLabel
            />
          )}
        </>
      )}

      {!!archivedLifeAreas.length && (
        <Styled.ArchivedContainer>
          <TitledCollapse
            title={t('pages.life-areas.sections.archived.title')}
            titleCount={archivedLifeAreas.length}
          >
            <LifeAreaDataGrid
              goals={goals}
              lifeAreas={archivedLifeAreas}
              onArchiveLifeArea={onArchive}
              onEditLifeArea={onEdit}
              onDeleteLifeArea={onDelete}
              hideHeader
              isArchived
            />
          </TitledCollapse>
        </Styled.ArchivedContainer>
      )}
    </Styled.Container>
  );
};
