import { InsightsTimeframes } from 'shared/types/insights';

import { getUser, getUserRef } from './get-user';
import { updateDocWithTimestamp } from './helpers';

export const updateUserInsightsTimeframe = async (
  timeframe: InsightsTimeframes,
) => {
  const user = await getUser();

  if (!user) {
    return;
  }

  const userRef = await getUserRef();

  if (!userRef) {
    return;
  }

  updateDocWithTimestamp(userRef, {
    'settings.insights.timeframe': timeframe,
  });
};
