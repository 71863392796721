import { DndContext } from '@dnd-kit/core';
import {
  useCreateUnlimitedGoalsAllowed,
  useGoalControls,
  useGoals,
} from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import { useUserHadTrial } from 'features/user';
import React, { useMemo } from 'react';
import { GoalListCard } from 'shared/components/ui/goal-list-card';
import { GoalPremiumBanner } from 'shared/components/ui/goal-premium-banner';
import { SortableOverlay } from 'shared/components/ui/sortable-overlay';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useDroppableSectionControls } from 'shared/hooks/use-droppable-section-controls';
import { useSectionCollisionDetection } from 'shared/hooks/use-section-collision-detection';
import { useSortableSensors } from 'shared/hooks/use-sortable-sensors';

import { useGoalLifeAreaReorder } from '../../hooks/use-goal-life-area-reorder';
import { useLifeAreaActiveGoalSections } from '../../hooks/use-life-area-active-goal-sections';
import { EmptyBanner } from '../empty-banner';
import { LifeAreaSection } from '../life-area-section';
import * as Styled from './life-area-grouping-goal-list.style';

export type LifeAreaGroupingGoalListProps = {};

export const LifeAreaGroupingGoalList: React.FC<
  LifeAreaGroupingGoalListProps
> = () => {
  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();
  const userHadTrial = useUserHadTrial();
  const { onUpdateGoalProgress } = useGoalControls(goals);

  const { sections: initialSections, goals: activeGoals } =
    useLifeAreaActiveGoalSections();
  const onDragEnd = useGoalLifeAreaReorder(initialSections);

  const {
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    handleDragCancel,
    activeId,
    sections,
  } = useDroppableSectionControls({ initialSections, onDragEnd });

  const sensors = useSortableSensors();
  const collisionStrategy = useSectionCollisionDetection(activeId, sections);
  const openPremiumDialog = useOpenPremiumDialog();

  const {
    allowed: createGoalAllowed,
    enforced: createGoalEnforced,
    limit: createGoalLimit,
    total: createGoalTotal,
  } = useCreateUnlimitedGoalsAllowed();

  const showPremiumBanner =
    !createGoalAllowed ||
    (!createGoalEnforced && createGoalLimit < createGoalTotal);

  const activeItem = useMemo(
    () => (activeId ? goals.find((goal) => goal.id === activeId) : undefined),
    [activeId, goals],
  );

  return (
    <>
      <DndContext
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
        sensors={sensors}
        collisionDetection={collisionStrategy}
      >
        {!activeGoals.length ? (
          <EmptyBanner />
        ) : (
          <Styled.Sections>
            {sections.map(({ id, items }) => (
              <Styled.Section key={id}>
                <LifeAreaSection
                  droppableId={id}
                  goals={items}
                  allGoals={goals}
                  lifeAreas={lifeAreas}
                  onPremium={openPremiumDialog}
                  onUpdateProgress={onUpdateGoalProgress}
                  hideLifeArea
                  canReorder
                />
              </Styled.Section>
            ))}
          </Styled.Sections>
        )}

        <SortableOverlay>
          {!!activeItem && (
            <GoalListCard
              goal={activeItem}
              lifeAreas={lifeAreas}
              goals={goals}
              hideLifeArea
            />
          )}
        </SortableOverlay>
      </DndContext>

      {showPremiumBanner && (
        <GoalPremiumBanner
          onClick={openPremiumDialog}
          createGoalEnforced={createGoalEnforced}
          createGoalTotal={createGoalTotal}
          createGoalLimit={createGoalLimit}
          hadTrial={userHadTrial}
        />
      )}
    </>
  );
};
