import { DocumentReference, Firestore, writeBatch } from 'firebase/firestore';

enum DocumentType {
  Add = 'add',
  Update = 'update',
  Delete = 'delete',
}

type Document = {
  data: any;
  ref: DocumentReference;
  type: DocumentType;
};

export class BatchDocuments {
  database: Firestore;
  documents: Document[] = [];

  constructor(database: Firestore) {
    this.database = database;
  }

  addDocument = (document: { data: any; ref: DocumentReference }) => {
    this.documents.push({ ...document, type: DocumentType.Add });
  };

  updateDocument = (document: { data: any; ref: DocumentReference }) => {
    this.documents.push({ ...document, type: DocumentType.Update });
  };

  deleteDocument = (ref: DocumentReference) => {
    this.documents.push({ ref, data: undefined, type: DocumentType.Update });
  };

  commit = () => {
    const amountOfBatches = Math.ceil(this.documents.length / 500);
    const batches = Array.from({ length: amountOfBatches }, () =>
      writeBatch(this.database),
    );

    // map over this.documents and add it to the correct batch
    this.documents.forEach((doc, index) => {
      const batchIndex = Math.floor(index / 500);
      if (doc.type === DocumentType.Update) {
        batches[batchIndex].update(doc.ref, doc.data);
        return;
      }

      if (doc.type === DocumentType.Delete) {
        batches[batchIndex].delete(doc.ref);
        return;
      }

      batches[batchIndex].set(doc.ref, doc.data);
    });

    // commit the batches
    batches.forEach((batch) => batch.commit());
  };
}
