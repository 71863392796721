import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { X } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { useForwardedRef } from 'shared/hooks/use-forwarded-ref';

import * as Styled from './resizable-input.style';

export type ResizableInputProps = React.ComponentProps<'input'> & {
  maxWidth?: string;
  minWidth?: string;
  icon?: React.FC;
  canClearEntry?: boolean;
  onClear?: () => void;
  showAsFormInput?: boolean;
  inheritFont?: boolean;
};

export const ResizableInput = forwardRef<HTMLInputElement, ResizableInputProps>(
  (
    {
      value,
      placeholder,
      minWidth = '6.2rem',
      maxWidth = '100%',
      icon,
      canClearEntry,
      onClear,
      showAsFormInput,
      inheritFont,
      ...rest
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const inputRef = useForwardedRef(ref);
    const showClearButton = canClearEntry && !!value?.toString().length;

    const clearInput = () => {
      const valueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value',
      )!.set;
      if (!valueSetter || !inputRef.current) {
        return;
      }

      valueSetter.call(inputRef.current, '');
      inputRef.current.dispatchEvent(new Event('input', { bubbles: true }));
      onClear?.();
    };

    return (
      <Styled.Container
        $minWidth={minWidth}
        $maxWidth={maxWidth}
        $fullWidth={!!showAsFormInput}
      >
        {!!icon && (
          <Styled.IconContainer $hasValue={!!value?.toString().length}>
            <Icon icon={icon} />
          </Styled.IconContainer>
        )}
        <Styled.SizingSpan
          $hasIconLeft={!!icon}
          $hasIconRight={!!showClearButton}
          $showAsFormInput={!!showAsFormInput}
          $inheritFont={!!inheritFont}
        >
          {value || placeholder}
        </Styled.SizingSpan>
        <Styled.Input
          ref={inputRef}
          value={value}
          placeholder={placeholder}
          $hasIconLeft={!!icon}
          $hasIconRight={!!showClearButton}
          $showAsFormInput={!!showAsFormInput}
          $inheritFont={!!inheritFont}
          {...rest}
        />
        {showClearButton && (
          <Styled.ClearButton
            onClick={clearInput}
            aria-label={t('general.labels.clear-field')}
            type="button"
          >
            <Icon icon={X} />
          </Styled.ClearButton>
        )}
      </Styled.Container>
    );
  },
);
