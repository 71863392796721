import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const CategoryList = styled.ul`
  list-style: none;

  margin: 0;
  padding: 0;
`;

export const CategoryListItem = styled.li``;

export const CategoryTitle = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.subtitle2)};
  display: block;
  padding: ${({ theme }) =>
    `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(1)}`};
`;

export const IconList = styled.ul`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: ${({ theme }) => theme.spacing(2)};
  list-style: none;

  margin: 0;
  padding: ${({ theme }) => theme.spacing(2)};
`;

export const IconListItem = styled.li``;

export const Button = styled.button<{ $selected: boolean }>`
  display: block;

  margin: 0;
  padding: ${({ theme }) => theme.spacing(1.5)};

  cursor: pointer;
  border: none;
  border-radius: ${({ theme }) => theme.spacing(2)};
  background: transparent;

  color: inherit;
  font-size: 2rem;
  line-height: 62.5%;

  ${({ $selected, theme }) =>
    $selected &&
    css`
      background: ${theme.palette.primary.light};
      color: ${theme.palette.primary.main};
    `}
`;
