import { dragging, hovered } from 'shared/assets/styles';
import styled, { css } from 'styled-components';

export const Container = styled.div<{
  $hasBigBorderRadius: boolean;
  $isDragging: boolean;
  $isHovered: boolean;
}>`
  position: relative;

  ${({ theme, $isDragging, $hasBigBorderRadius }) =>
    $isDragging &&
    css`
      ${dragging};

      ${$hasBigBorderRadius &&
      css`
        &:after {
          border-radius: ${theme.spacing(3)};
        }
      `}
    `};

  ${({ $isHovered, $isDragging }) => $isHovered && !$isDragging && hovered};
`;
