import { FirestoreDataConverter } from 'firebase/firestore';
import { NewLifeArea } from 'shared/types/life-area';
import { Lifescan } from 'shared/types/lifescan';

import { firebaseTimestampToDate } from './firebase-timestamp-to-date';

export const lifescanConverter: FirestoreDataConverter<Lifescan> = {
  fromFirestore: (snapshot) => {
    const data = snapshot.data();
    return {
      ...data,
      createdAt: firebaseTimestampToDate(data.createdAt),
    } as Lifescan;
  },
  toFirestore: (lifeArea) => lifeArea,
};

export const newLifeAreaConverter = (lifeArea: NewLifeArea) => ({
  ...lifeArea,
  createdAt: new Date(),
});
