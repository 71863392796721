import { useCallback } from 'react';
import { Locales } from 'shared/assets/localization';
import { useCompleteSignupAndLoginMutation } from 'shared/hooks/queries/use-complete-signup-and-login-mutation';
import { useNavigateAppOpenScreen } from 'shared/hooks/use-navigate-app-open-screen';

export const useCompleteSignupAndLogin = () => {
  const navigateAppOpen = useNavigateAppOpenScreen();
  const { mutateAsync, error, reset, isPending, isSuccess } =
    useCompleteSignupAndLoginMutation();

  const completeSignupAndLogin = useCallback(
    async (
      oobCode: string,
      {
        password,
        name,
        locale,
      }: { password: string; name?: string; locale?: Locales },
    ) => {
      const user = await mutateAsync({ code: oobCode, password, name, locale });
      // specifically navigate as a revalidation of this page would mean that the user is logged out again
      await navigateAppOpen(user);
    },
    [mutateAsync, navigateAppOpen],
  );

  return {
    completeSignupAndLogin,
    error,
    isLoading: isPending,
    isSuccess,
    reset,
  };
};
