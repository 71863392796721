import { useUpdateGoalRoadmapSortMode } from 'features/user';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Check } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
  PopupMenuTitle,
} from 'shared/components/ui/popup-menu';
import { useUser } from 'shared/hooks/use-user';
import { GoalSortingMode } from 'shared/types/sorting-mode';

import { sortOptions } from './header-interaction-options';

export type RoadmapMenuProps = {
  referenceElement: React.RefObject<HTMLElement>;
  onClose: () => void;
};

export const RoadmapMenu: React.FC<RoadmapMenuProps> = ({
  referenceElement,
  onClose,
}) => {
  const { t } = useTranslation();

  const user = useUser();
  const setSortMode = useUpdateGoalRoadmapSortMode();

  const _onSort = (value: GoalSortingMode) => {
    setSortMode(value);
    onClose();
  };

  const sort = user?.settings?.goalRoadmapSortingMode;

  return (
    <PopupMenu referenceElement={referenceElement} position="bottom-end">
      <PopupMenuList>
        <PopupMenuTitle title={t('pages.goals.sort.title')} />

        {sortOptions.map(({ id, label, value }) => (
          <PopupMenuListItem key={id}>
            <PopupMenuButton
              start={sort === id ? <Icon icon={Check} /> : undefined}
              onClick={() => _onSort(value)}
              hasIndentation={sort !== id}
            >
              {t(label)}
            </PopupMenuButton>
          </PopupMenuListItem>
        ))}
      </PopupMenuList>
    </PopupMenu>
  );
};
