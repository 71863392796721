import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const SvgText = styled.text<{ $isHighlighted: boolean }>`
  ${({ theme, $isHighlighted }) =>
    typographyToCss(
      $isHighlighted ? theme.typography.caption : theme.typography.overline,
    )};

  fill: ${({ theme }) => theme.palette.text.secondary};
`;
