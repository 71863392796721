import {
  useCompleteHabitDate,
  useHabitEntries,
  useHabitProgress,
} from 'features/tasks';
import { useTimeframeTime } from 'features/user';
import React from 'react';
import { HabitInsightsCard as HabitInsightsCardComponent } from 'shared/components/connected/habit-insights-card';
import { useUser } from 'shared/hooks/use-user';
import { Habit } from 'shared/types/habit';
import { ID } from 'shared/types/id';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';

export type HabitInsightsCardProps = {
  habit: Habit;
};

export const HabitInsightsMetricCard: React.FC<HabitInsightsCardProps> = ({
  habit,
}) => {
  const user = useUser();
  const { startDate, endDate, timeframe } = useTimeframeTime({
    quarterRoundedToWeeks: true,
  });
  const onCompleteDate = useCompleteHabitDate();
  const {
    timeframeOccurrences,
    completions,
    skips,
    completionPercentage,
    entryTarget,
    target,
  } = useHabitProgress(habit, {
    timeframeType: timeframe,
    startDate,
    endDate,
    weekStartsOn: user?.settings?.startOfWeek,
  });

  const { entries } = useHabitEntries({
    timeframe,
    startDate,
    endDate,
    completions,
  });

  const _onCompleteDate = (id: ID, date: Timestamp) => {
    onCompleteDate(habit, date, true);
  };

  return (
    <HabitInsightsCardComponent
      habit={habit}
      startDate={startDate}
      endDate={endDate}
      entries={entries}
      entryTarget={entryTarget}
      calendarOccurrences={timeframeOccurrences}
      completions={completions}
      skips={skips}
      currentValue={completions.length}
      completionPercentage={completionPercentage}
      weekStartsOn={user?.settings?.startOfWeek ?? WeekDays.Monday}
      target={target}
      onDate={_onCompleteDate}
    />
  );
};
