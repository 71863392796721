import { useUpdateGoal } from 'features/goals';
import { useCallback, useMemo } from 'react';
import { useUpdateGoalGroupingLifeAreaGoalOrderMutation } from 'shared/hooks/queries/use-update-goal-life-area-goal-order-mutation';
import { Goal } from 'shared/types/goal';
import { lifeAreaOtherID, SectionBase } from 'shared/types/orderable-section';

export const useGoalLifeAreaReorder = (sections: SectionBase<Goal>[]) => {
  const { mutate: updateLifeAreaSorting } =
    useUpdateGoalGroupingLifeAreaGoalOrderMutation();
  const { submit: updateGoal } = useUpdateGoal();

  const oldSections = useMemo(() => structuredClone(sections), [sections]);

  return useCallback(
    (newSections: SectionBase<Goal>[], movedItem: Goal) => {
      const newSection = newSections.find(({ items }) =>
        items.find(({ id }) => id === movedItem.id),
      );
      const oldSection = oldSections.find(({ items }) =>
        items.find(({ id }) => id === movedItem.id),
      );

      if (!newSection || !oldSection) {
        // invalid data if we can't find the movedItem within the sections
        return;
      }

      const lifeAreasToUpdate = [
        {
          lifeAreaId: newSection.id,
          goalIds: newSection.items.map(({ id }) => id),
        },
      ];

      // if the section changed, get the new oldSection variant and add it to the lifeAreasToUpdate list
      if (newSection.id !== oldSection.id) {
        const newOldSection = newSections.find(
          ({ id }) => id === oldSection.id,
        );

        if (!newOldSection) {
          // should not be possible, just check for type-safety
          return;
        }

        lifeAreasToUpdate.push({
          lifeAreaId: newOldSection.id,
          goalIds: newOldSection.items.map(({ id }) => id),
        });

        updateGoal({
          id: movedItem.id,
          lifeAreaId: newSection.id === lifeAreaOtherID ? null : newSection.id,
        });
      }

      updateLifeAreaSorting(lifeAreasToUpdate);
    },
    [oldSections, updateGoal, updateLifeAreaSorting],
  );
};
