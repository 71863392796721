import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useActivePath } from 'shared/hooks/use-active-path';
import { useUser } from 'shared/hooks/use-user';
import {
  mainMobileNavigation,
  MainNavigationItemId,
} from 'shared/routes/navigation';

import * as Styled from './mobile-navigation.style';
import { NavigationItem } from './navigation-item';

export const MobileNavigation: React.FC = () => {
  const { t } = useTranslation();
  const activePath = useActivePath({ level: 2 });
  const user = useUser();

  const filteredMobileNavigation = useMemo(
    () =>
      mainMobileNavigation.filter(
        ({ id }) =>
          id !== MainNavigationItemId.Insights ||
          user?.email?.endsWith('@griply.app'),
      ),
    [user?.email],
  );

  return (
    <>
      <Styled.Placeholder />
      <Styled.Container>
        <Styled.List>
          {filteredMobileNavigation.map((navigation) => (
            <Styled.ListItem key={navigation.id}>
              <NavigationItem
                icon={navigation.icon}
                label={t(navigation.translationLabel)}
                to={navigation.link}
                isSelected={
                  activePath && navigation.pathsToMatch.includes(activePath)
                }
              />
            </Styled.ListItem>
          ))}
        </Styled.List>
      </Styled.Container>
    </>
  );
};
