import { index, Options } from 'shared/assets/styles';
import styled, { css } from 'styled-components';

export const minContainerWidth = 264;
export const initialContainerWidth = 440;
export const maxContainerWidth = 560;

const mobileContainer = css<{ $open: boolean; $mobileViewportHeight: number }>`
  position: fixed;
  right: 0;
  left: 0;

  ${({ $open, $mobileViewportHeight }) =>
    $open &&
    css`
      height: ${$mobileViewportHeight}px;
    `};
`;

const desktopContainer = css<{
  $open: boolean;
  $width: number;
  $isResizing: boolean;
}>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: ${index(Options.DETAIL_COLUMN)};

  background: ${({ theme }) => theme.palette.background.default};

  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.lg)} {
    position: relative;
    transition:
      right 200ms,
      width 200ms;

    top: unset;
    bottom: unset;
    right: unset;
  }

  ${({ $open, $width }) =>
    $open
      ? css`
          width: ${$width}px;
          box-shadow: ${({ theme }) => theme.shadowBox.column};

          border-left: 1px solid ${({ theme }) => theme.palette.divider};
        `
      : css`
          width: 0;
        `};
`;

export const ResizableContainer = styled.article<{
  $open: boolean;
  $width: number;
  $mobileViewportHeight: number;
  $isResizing: boolean;
}>`
  z-index: ${index(Options.DETAIL_COLUMN)};
  ${({ theme }) => (theme.isMobile ? mobileContainer : desktopContainer)};
`;

export const Content = styled.div`
  position: sticky;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${index(Options.DETAIL_COLUMN)};

  display: flex;
  flex-direction: column;
  max-height: 100dvh;

  background: ${({ theme }) => theme.palette.background.default};

  overflow: hidden;

  ${({ theme }) =>
    theme.isMobile &&
    css`
      position: absolute;
      height: calc(100% - ${theme.spacing(8)});
      top: unset;

      border-radius: ${`${theme.spacing(4)} ${theme.spacing(4)} 0 0`};
    `};
`;

export const MobileBackdrop = styled.div<{ $open: boolean }>`
  position: fixed;
  top: ${({ $open }) => ($open ? 0 : '100%')};
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${index(Options.DETAIL_COLUMN)};
  transition: background-color 200ms;

  background: ${({ theme, $open }) =>
    $open ? theme.palette.backdrop.default : 'transparent'};

  ${({ theme }) =>
    !theme.isMobile &&
    css`
      display: none;
    `};
`;

export const ResizeHandle = styled.span<{
  $isResizing: boolean;
  $open: boolean;
}>`
  display: ${({ $open }) => ($open ? 'block' : 'none')};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;

  cursor: col-resize;
  background: transparent;
  z-index: ${index(Options.DETAIL_COLUMN)};
  transition: background 200ms;

  &:hover {
    background: ${({ theme }) => theme.palette.divider};
  }

  ${({ theme, $isResizing }) =>
    $isResizing &&
    css`
      background: ${theme.palette.divider};
    `};
`;
