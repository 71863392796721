import styled, { css } from 'styled-components';

export const GroupingContainer = styled.div`
  padding: ${({ theme }) => `0 0 0 ${theme.spacing(3)}`};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: 0;
    `};
`;
