import { differenceInWeeks } from 'date-fns';
import { useMemo } from 'react';

import { TickRange } from './types';

export const useTickRange = (domain: [number, number]) => {
  return useMemo(() => {
    const difInWeeks = differenceInWeeks(domain[1], domain[0]);

    return difInWeeks <= 1
      ? TickRange.Day
      : difInWeeks < 11
        ? TickRange.Week
        : TickRange.Month;
  }, [domain]);
};
