import { useLifescans } from 'features/life-areas';
import { useMemo } from 'react';
import { MetricEntry } from 'shared/types/goal-metric';
import { LifeArea } from 'shared/types/life-area';
import { Timestamp } from 'shared/types/timestamp';

import { useTimeframeDifference } from '../hooks/use-timeframe-difference';

export type Options = {
  startDate: Timestamp;
  endDate: Timestamp;
};

export const useLifeAreaProgress = (
  lifeArea: LifeArea,
  { startDate, endDate }: Options,
) => {
  const lifescans = useLifescans();
  const startValue = 0;
  const target = 10;

  const entries = useMemo(
    () =>
      lifescans.map<MetricEntry>(({ createdAt, lifeAreas, id }) => ({
        id,
        value: lifeAreas[lifeArea.id]?.value ?? 0,
        date: createdAt,
      })),
    [lifeArea.id, lifescans],
  );

  const average = useMemo(
    () =>
      entries.reduce<number>((acc, { value }) => acc + value, 0) /
      entries.length,
    [entries],
  );

  const current = entries.length ? entries[0].value : 0;

  const timeframeDifference = useTimeframeDifference({
    entries,
    startDate,
    endDate,
    target,
  });

  return {
    startValue,
    entries,
    target,
    average: average || 0,
    timeframeDifference,
    current,
  };
};
