import { useTransformSectionToGoalMutation } from 'shared/hooks/queries/use-transform-section-to-goal-mutation';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';

export type Options = {
  goal: Goal;
  isSubGoal?: boolean;
};

export const useTransformSectionToGoal = () => {
  const { mutateAsync } = useTransformSectionToGoalMutation();
  return (sectionId: ID, { goal, isSubGoal }: Options) =>
    mutateAsync({ goal, sectionId, isSubGoal });
};
