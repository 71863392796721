import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const ProgressGradient = styled.linearGradient`
  stop:first-child {
    stop-color: ${({ theme }) => theme.palette.primary.main};
    stop-opacity: 0.4;
  }

  stop:last-child {
    stop-color: ${({ theme }) => theme.palette.primary.main};
    stop-opacity: 0;
  }
`;
export const EstimateGradient = styled.linearGradient`
  stop:first-child {
    stop-color: ${({ theme }) => theme.palette.divider};
    stop-opacity: 0.4;
  }

  stop:last-child {
    stop-color: ${({ theme }) => theme.palette.divider};
    stop-opacity: 0;
  }
`;

export const SvgCircle = styled.circle<{ $content?: string }>`
  stroke: ${({ theme }) => theme.palette.primary.main};
  stroke-width: ${({ theme }) => theme.spacing(0.5)};
  fill: ${({ theme }) => theme.palette.background.default};
  r: ${({ theme }) => theme.spacing(1.5)};
`;

export const SvgDotText = styled.text`
  ${({ theme }) => typographyToCss(theme.typography.h6)};

  fill: ${({ theme }) => theme.palette.text.primary};
`;
