import React from 'react';
import { usePremiumFeatureAllowed } from 'shared/hooks/use-premium-feature-allowed';
import { LifeArea } from 'shared/types/life-area';
import { LimitKeys } from 'shared/types/limit-keys';

import { LifescanInsightsPreviewCard } from './lifescan-insights-preview-card';
import { LifescanMetricInsightsCard } from './lifescan-metric-insights-card';

export type LifescanInsightsCardProps = {
  lifeArea: LifeArea;
};

export const LifescanInsightsCard: React.FC<LifescanInsightsCardProps> = ({
  lifeArea,
}) => {
  const { allowed } = usePremiumFeatureAllowed(LimitKeys.Insights, {});

  if (!allowed) {
    return <LifescanInsightsPreviewCard lifeArea={lifeArea} />;
  }

  return <LifescanMetricInsightsCard lifeArea={lifeArea} />;
};
