import React from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { useToday } from 'shared/contexts/today';
import { Timestamp } from 'shared/types/timestamp';
import { getStartTillEndLabel } from 'shared/utils/get-start-till-end-label';

import * as Styled from './timeframe.style';

export type TimeframeProps = {
  startDate?: Timestamp | null;
  endDate?: Timestamp | null;
  hasLargeIcon?: boolean;
  isShort?: boolean;
};

export const Timeframe: React.FC<TimeframeProps> = ({
  startDate,
  endDate,
  isShort,
  hasLargeIcon,
}) => {
  const { t } = useTranslation();
  const today = useToday();

  const label = getStartTillEndLabel({
    startDate: startDate ?? undefined,
    endDate: endDate ?? undefined,
    isShort,
    today,
    t,
  });

  return (
    <Styled.Container>
      <Styled.IconContainer $hasLargeIcon={!!hasLargeIcon}>
        <Icon icon={Calendar} />
      </Styled.IconContainer>

      <Styled.Label>{label}</Styled.Label>
    </Styled.Container>
  );
};
