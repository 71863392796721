import { PaletteOptions } from '@mui/material';

export const palette: PaletteOptions = {
  text: {
    primary: '#FFFFFF',
    secondary: '#8F8F8F',
  },
  primary: {
    main: '#8C6AEA',
    light: '#8C6AEA1A',
    contrastText: '#FFFFFF',
  },
  primaryExtended: {
    opacityBackgroundText: '#B094FF',
    opacityBackgroundBackground: '#B094FF24',
  },
  secondary: {
    main: '#FFFFFF',
    contrastText: '#FFFFFF',
  },
  background: {
    default: '#161617',
    paper: '#242425',
  },
  backgroundExtended: {
    card: '#242425',
  },
  backdrop: {
    default: '#00000080',
  },
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  error: {
    main: '#FF7B6B',
    dark: '#FF5B47',
    light: '#FFFFFF',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#FFAA27',
    dark: '#D86700',
    light: '#FFAA271F',
  },
  info: {
    main: '#A8A5FF',
    dark: '#514CD6',
    light: '#A8A5FF33',
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#2EA9A1',
    dark: '#4DA29D',
    light: '#2EA9A124',
    contrastText: '#FFFFFF',
  },
  divider: '#4F4F4F',
  grey: {
    900: '#FCF6FA',
    800: '#F4EEF2',
    700: '#E9E4E7',
    600: '#D8D3D6',
    500: '#AAA4A7',
    400: '#898487',
    300: '#6B6669',
    200: '#585356',
    100: '#393538',
    50: '#272727',
  },
  brown: {
    900: '#FFFFFF',
    800: '#FFFFFF',
    700: '#FFFFFF',
    600: '#FFFFFF',
    500: '#FFFFFF',
    400: '#FFFFFF',
    300: '#747474',
    200: '#6F6F6F',
    100: '#383838',
    50: '#272727',
  },
};
