import { CSS, Transform } from '@dnd-kit/utilities';
import { dragging } from 'shared/assets/styles';
import styled from 'styled-components';

export const List = styled.ul`
  list-style: none;

  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li``;

export const DraggableContainer = styled.div<{
  $isDragging?: boolean;
  $transform: Transform | null;
  $transition?: string;
}>`
  position: relative;
  transform: ${({ $transform }) => CSS.Translate.toString($transform)};
  transition: ${({ $transition }) => $transition};

  ${({ $isDragging }) => $isDragging && dragging};
`;
