import { endOfWeek, format, isSameWeek, startOfMonth } from 'date-fns';
import { weekdaysNumberMap } from 'shared/constants';
import { DateFormatterOptions } from 'shared/lib/recharts';

export const dateTickFormatterWeekDay = ({ date }: DateFormatterOptions) => {
  return format(date, 'iiiii');
};

export const dateTickFormatterDay = ({ date }: DateFormatterOptions) => {
  return format(date, 'd');
};

export const dateTickFormatterMonth = ({
  date,
  weekStartsOn,
}: DateFormatterOptions) => {
  const endOfWeekDate = endOfWeek(date, {
    weekStartsOn: weekdaysNumberMap[weekStartsOn],
  });
  if (
    isSameWeek(date, startOfMonth(endOfWeekDate), {
      weekStartsOn: weekdaysNumberMap[weekStartsOn],
    })
  ) {
    return format(endOfWeekDate, 'LLLLL');
  }

  return '';
};
