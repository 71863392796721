import { useMemo } from 'react';
import { useTheme } from 'styled-components';

export const useBarchartStyling = () => {
  const theme = useTheme();

  const xAxisTickLabelProps = useMemo(
    () => ({
      fill: theme.palette.text.secondary,
      fontSize: theme.typography.overline.fontSize,
      lineHeight: theme.typography.overline.lineHeight,
      fontFamily: theme.typography.overline.fontFamily,
      fontWeight: theme.typography.overline.fontWeight,
      textAnchor: 'middle' as 'middle',
    }),
    [theme.palette.text, theme.typography.overline],
  );

  const yAxisTickLabelProps = useMemo(
    () => ({
      fill: theme.palette.text.secondary,
      fontSize: theme.typography.overline.fontSize,
      lineHeight: theme.typography.overline.lineHeight,
      fontFamily: theme.typography.overline.fontFamily,
      fontWeight: theme.typography.overline.fontWeight,
      textAnchor: 'end' as 'end',
      dy: '0.4rem',
    }),
    [theme.palette.text, theme.typography.overline],
  );

  return {
    yAxisTickLabelProps,
    yAxisStrokeColor: theme.palette.divider,
    xAxisTickLabelProps,
    xAxisStrokeColor: theme.palette.divider,
    cartesianColor: theme.palette.divider,
  };
};
