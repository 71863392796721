import { useTimeframeTime } from 'features/user';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GoalInsightsCard } from 'shared/components/connected/goal-insights-card';
import { Goal } from 'shared/types/goal';
import { metricUnitFormatBase } from 'shared/types/metric-template';

import { useGoalTaskProgress } from '../../hooks/use-goal-task-progress';

export type GoalInsightsTaskCardProps = {
  goal: Goal;
};

export const GoalInsightsTaskCard: React.FC<GoalInsightsTaskCardProps> = ({
  goal,
}) => {
  const { t } = useTranslation();
  const { startDate, endDate } = useTimeframeTime({
    weekEndsWithStartOfDay: true,
  });
  const {
    entries: goalProgress,
    target,
    completionPercentage,
    timeframeDifference,
  } = useGoalTaskProgress(goal, { startDate, endDate });

  const unitFormat = `${metricUnitFormatBase} ${t('general.labels.goal-progress.unit-format.tasks.label')}`;

  return (
    <GoalInsightsCard
      goal={goal}
      entries={goalProgress}
      completionPercentage={completionPercentage}
      unitFormat={unitFormat}
      startValue={0}
      target={target}
      timeframeDifference={timeframeDifference}
      startDate={startDate}
      endDate={endDate}
      isLink
    />
  );
};
