import { useDuplicateGoal, useGoals, useUpdateGoal } from 'features/goals';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ArrowDownRight,
  ArrowUpRight,
  CopyDuplicateObject,
} from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { Paths } from 'shared/routes';
import { Goal } from 'shared/types/goal';
import { replaceUrlParams } from 'shared/utils/replace-url-params';

export type MoveGoalListProps = {
  goal: Goal;
  onClose: () => void;
  openGoalSelect: () => void;
};

export const MoveGoalList: React.FC<MoveGoalListProps> = ({
  goal,
  onClose,
  openGoalSelect,
}) => {
  const { t } = useTranslation();
  const { submit: updateGoal } = useUpdateGoal();
  const navigate = useNavigate();
  const duplicateGoal = useDuplicateGoal();
  const goals = useGoals();
  const filteredGoals = useMemo(
    () => goals.filter(({ parentIds }) => !parentIds),
    [goals],
  );

  const childGoals = useMemo(
    () => goals.filter(({ parentIds }) => parentIds?.includes(goal.id)),
    [goal.id, goals],
  );
  const onGoalDuplicate = async (e: React.MouseEvent) => {
    e.preventDefault();
    const newGoalId = await duplicateGoal(goal);

    if (newGoalId) {
      navigate(replaceUrlParams(Paths.GoalDetail, { id: newGoalId }));
    }

    onClose();
  };

  const onGoalSelect = (e: React.MouseEvent) => {
    e.preventDefault();
    openGoalSelect();
  };

  const onGoalToMain = (e: React.MouseEvent) => {
    e.preventDefault();
    updateGoal({ id: goal.id, parentIds: null });
    onClose();
  };

  return (
    <PopupMenuList hasBottomBorder>
      <PopupMenuListItem>
        <PopupMenuButton
          onClick={onGoalDuplicate}
          start={<Icon icon={CopyDuplicateObject} />}
        >
          {t('context-menus.goal.labels.duplicate-goal')}
        </PopupMenuButton>
      </PopupMenuListItem>

      {(!!filteredGoals.length || !!goal.parentIds?.length) &&
      !childGoals.length ? (
        <>
          {!!filteredGoals.length && (
            <PopupMenuListItem>
              <PopupMenuButton
                onClick={onGoalSelect}
                start={<Icon icon={ArrowDownRight} />}
              >
                {t('context-menus.goal.labels.move-goal')}
              </PopupMenuButton>
            </PopupMenuListItem>
          )}
          {!!goal.parentIds?.length && (
            <PopupMenuListItem>
              <PopupMenuButton
                onClick={onGoalToMain}
                start={<Icon icon={ArrowUpRight} />}
              >
                {t('context-menus.goal.labels.make-main')}
              </PopupMenuButton>
            </PopupMenuListItem>
          )}
        </>
      ) : null}
    </PopupMenuList>
  );
};
