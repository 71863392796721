import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React from 'react';
import { DndGoalListCard } from 'shared/components/ui/goal-list-card';
import { Goal as GoalType } from 'shared/types/goal';

import { Goal } from './goal';
import * as Styled from './task-goal-section-list.style';

export type GoalsListProps = Omit<
  React.ComponentProps<typeof Goal>,
  // todo: figure out isDragging and isHovered
  'goal' | 'isDragging' | 'isMoving' | 'isHovered'
> & {
  goals: GoalType[];
  canCreateTask?: boolean;
  noRightSpacing?: boolean;
};

export const GoalsList: React.FC<GoalsListProps> = ({
  goals,
  canCreateTask,
  noRightSpacing,

  allGoals,
  lifeAreas,
  imagePlaceholderIcon,
  onUpdateGoalProgress,
  onPremium,

  items,
  onTask,
  onUnfocus,
  hideGoal,
  showRepeatingAsTodo,
  selectedItem,
  overwriteCollapse,
  onUpdateSection,
  canCollapse,
  initialOpen,
  hasDnd,
}) => {
  const SortableContainer = hasDnd ? SortableContext : React.Fragment;
  const sortableProps = hasDnd
    ? {
        items: goals.map(({ id }) => id),
        strategy: verticalListSortingStrategy,
      }
    : {};

  return (
    // @ts-ignore
    <SortableContainer {...sortableProps}>
      <Styled.List $noRightSpacing={!!noRightSpacing}>
        {goals.map((goal) => (
          <Styled.ListItem key={goal.id}>
            <Styled.GoalContainer>
              <DndGoalListCard
                goal={goal}
                lifeAreas={lifeAreas}
                goals={allGoals}
                onUpdateProgress={onUpdateGoalProgress}
                hideLifeArea
              />
            </Styled.GoalContainer>
            {/*<Goal*/}
            {/*  goal={goal}*/}
            {/*  allGoals={allGoals}*/}
            {/*  lifeAreas={lifeAreas}*/}
            {/*  imagePlaceholderIcon={imagePlaceholderIcon}*/}
            {/*  onUpdateGoalProgress={onUpdateGoalProgress}*/}
            {/*  onPremium={onPremium}*/}
            {/*  items={items}*/}
            {/*  onTask={onTask}*/}
            {/*  onUnfocus={onUnfocus}*/}
            {/*  hideGoal={hideGoal}*/}
            {/*  showRepeatingAsTodo={showRepeatingAsTodo}*/}
            {/*  selectedItem={selectedItem}*/}
            {/*  overwriteCollapse={overwriteCollapse}*/}
            {/*  onUpdateSection={onUpdateSection}*/}
            {/*  canCreateTask={canCreateTask}*/}
            {/*  initialOpen={initialOpen}*/}
            {/*  canCollapse={canCollapse}*/}
            {/*  hasDnd={hasDnd}*/}
            {/*/>*/}
          </Styled.ListItem>
        ))}
      </Styled.List>
    </SortableContainer>
  );
};
