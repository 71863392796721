import { useUpdateInsightsTimeframe, useUserInsights } from 'features/user';
import React from 'react';
import { InsightsTimeframeSelection } from 'shared/components/ui/insights-timeframe-selection';
import { InsightsTimeframes } from 'shared/types/insights';

export type PeriodSelectionProps = {
  isLight?: boolean;
  isFullWidth?: boolean;
};

export const PeriodSelection: React.FC<PeriodSelectionProps> = ({
  isLight,
  isFullWidth,
}) => {
  const userInsights = useUserInsights();
  const setInsightsTimeframe = useUpdateInsightsTimeframe();

  return (
    <InsightsTimeframeSelection
      value={userInsights?.timeframe ?? InsightsTimeframes.Year}
      onChange={setInsightsTimeframe}
      isLight={isLight}
      isFullWidth={isFullWidth}
    />
  );
};
