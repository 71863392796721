import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { TaskCardTask } from 'shared/types/task-card-task';
import { Timestamp } from 'shared/types/timestamp';

export type SectionItemBase = {
  id: ID;
};

export type SectionBase<Item extends SectionItemBase> = {
  id: ID;
  items: Item[];
};

export type GoalSection = SectionBase<TaskCardTask> & {
  frozen?: boolean;
};

export type DateSection<Item extends SectionItemBase> = SectionBase<Item> & {
  startDate: Timestamp;
  endDate: Timestamp;
};

export type TaskCardDateSection = DateSection<TaskCardTask> & {
  deadline: Timestamp;
  type: DateSectionOptions;
  isDroppableOnly?: boolean;
};
export type GoalDateSection = DateSection<Goal>;

export enum DateSectionOptions {
  Day = 'day',
  RestOfMonth = 'rest-of-month',
  Month = 'month',
  Year = 'year',
}

export const overdueSectionID = 'overdue';

export type OverdueSectionID = typeof overdueSectionID;

export const otherSectionID = 'other';

export type OtherSectionID = typeof otherSectionID;

export const goalInboxID = 'inbox';

export type GoalInboxID = typeof goalInboxID;

export const lifeAreaOtherID = 'other';

export type LifeAreaOtherID = typeof lifeAreaOtherID;
