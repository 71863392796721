import {
  addMilliseconds,
  addMonths,
  addWeeks,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { useMemo } from 'react';
import { weekdaysNumberMap } from 'shared/constants';
import { useToday } from 'shared/contexts/today';
import { InsightsTimeframes } from 'shared/types/insights';
import { WeekDays } from 'shared/types/week-days';

export type Options = {
  weekStartsOn: WeekDays;
  quarterRoundedToWeeks?: boolean;
  weekEndsWithStartOfDay?: boolean;
};

export const useTimeByInsightsTimeframe = (
  timeframe: InsightsTimeframes,
  { weekStartsOn, quarterRoundedToWeeks, weekEndsWithStartOfDay }: Options,
) => {
  const today = useToday();

  return useMemo(() => {
    const _weekStartsOn = weekdaysNumberMap[weekStartsOn];

    switch (timeframe) {
      case InsightsTimeframes.Week:
        return {
          startDate: startOfWeek(today, { weekStartsOn: _weekStartsOn }),
          endDate: weekEndsWithStartOfDay
            ? // add a millisecond so the date is taken into account when generating the ticks
              addMilliseconds(
                startOfDay(endOfWeek(today, { weekStartsOn: _weekStartsOn })),
                1,
              )
            : endOfWeek(today, { weekStartsOn: _weekStartsOn }),
        };
      case InsightsTimeframes.Month:
        return { startDate: startOfMonth(today), endDate: endOfMonth(today) };
      case InsightsTimeframes.Quarter:
        return {
          startDate: quarterRoundedToWeeks
            ? startOfWeek(startOfQuarter(today), {
                weekStartsOn: _weekStartsOn,
              })
            : startOfQuarter(today),
          endDate: quarterRoundedToWeeks
            ? endOfWeek(endOfQuarter(today), { weekStartsOn: _weekStartsOn })
            : endOfQuarter(today),
        };
      case InsightsTimeframes.Year:
        return { startDate: startOfYear(today), endDate: endOfYear(today) };
      case InsightsTimeframes.FourWeeks:
        return {
          startDate: addWeeks(
            startOfWeek(today, { weekStartsOn: _weekStartsOn }),
            -3,
          ),
          endDate: endOfWeek(today, { weekStartsOn: _weekStartsOn }),
        };
      case InsightsTimeframes.TwelveWeeks:
        return {
          startDate: addWeeks(
            startOfWeek(today, { weekStartsOn: _weekStartsOn }),
            -11,
          ),
          endDate: endOfWeek(today, { weekStartsOn: _weekStartsOn }),
        };
      case InsightsTimeframes.TwelveMonths:
        return {
          startDate: addMonths(startOfMonth(today), -11),
          endDate: endOfMonth(today),
        };
      default: {
        return { startDate: today, endDate: today };
      }
    }
  }, [
    quarterRoundedToWeeks,
    timeframe,
    today,
    weekEndsWithStartOfDay,
    weekStartsOn,
  ]);
};
