import { startOfQuarter } from 'date-fns';
import { getSortedGoals, useGoals } from 'features/goals';
import { useMemo } from 'react';
import { useUser } from 'shared/hooks/use-user';
import { isBetween } from 'shared/lib/date-fns';
import { ID } from 'shared/types/id';
import { GoalDateSection } from 'shared/types/orderable-section';
import { GoalSortingMode, OrderDirection } from 'shared/types/sorting-mode';

import { createRoadmapSection } from '../utils/create-roadmap-section';
import { useQuarterlySections } from './use-quarterly-sections';
import { useRoadmapGoals } from './use-roadmap-goals';

export const useRoadmapSections = () => {
  const user = useUser();
  const allGoals = useGoals();
  const goals = useRoadmapGoals();
  const sections = useQuarterlySections();

  const filledSections = useMemo(() => {
    const result = [];
    const unplannedGoals = goals.filter(({ startDate }) => !startDate);
    const plannedGoals = goals.filter(({ startDate }) => !!startDate);

    // check if there are any unplanned goals
    if (unplannedGoals.length) {
      const unplannedSection = createRoadmapSection(new Date(0));
      unplannedSection.items = unplannedGoals;
      result.push(unplannedSection);
    }

    // create a base of sections that at least have to be present
    const sectionsClone = structuredClone(sections);
    const sectionsMapBase = sectionsClone.reduce<Record<ID, GoalDateSection>>(
      (acc, section) => {
        acc[section.id] = section;
        return acc;
      },
      {},
    );

    // fill the sections with the planned goals
    const filledSectionsMap = plannedGoals.reduce<Record<ID, GoalDateSection>>(
      (acc, goal) => {
        const section = sections.find(({ startDate, endDate }) =>
          isBetween(goal.startDate!, startDate, endDate),
        );

        if (section) {
          acc[section.id] = acc[section.id] ?? section;
          acc[section.id].items.push(goal);
          return acc;
        }

        const quarterStartDate = startOfQuarter(goal.startDate!);
        const newSection = createRoadmapSection(quarterStartDate);
        acc[newSection.id] = newSection;
        acc[newSection.id].items.push(goal);

        return acc;
      },
      sectionsMapBase,
    );

    // add the sections to the list of sections
    result.push(...Object.values(filledSectionsMap));

    // adjust sorting to the selected sorting of the user
    result.forEach((section) => {
      section.items = getSortedGoals(section.items, {
        mode: [user?.settings?.goalRoadmapSortingMode].filter(
          Boolean,
        ) as GoalSortingMode[],
        order: user?.settings?.goalRoadmapSorting?.[section.id],
        direction: OrderDirection.Desc,
        allGoals,
      });
    });

    return result;
  }, [
    allGoals,
    goals,
    sections,
    user?.settings?.goalRoadmapSorting,
    user?.settings?.goalRoadmapSortingMode,
  ]);

  return {
    goals,
    sections: filledSections,
  };
};
