import { addDays, endOfDay } from 'date-fns';
import React, { useMemo } from 'react';
import { LifeAreaInsightsCard } from 'shared/components/connected/life-area-insights-card';
import { useToday } from 'shared/contexts/today';
import { MetricEntry } from 'shared/types/goal-metric';
import { LifeArea } from 'shared/types/life-area';

const target = 10;
const average = 2;
const currentValue = 7;

export type LifescanInsightsPreviewCardProps = {
  lifeArea: LifeArea;
};

export const LifescanInsightsPreviewCard: React.FC<
  LifescanInsightsPreviewCardProps
> = ({ lifeArea }) => {
  const today = useToday();

  const entries = useMemo<MetricEntry[]>(
    () => [
      {
        id: window.crypto.randomUUID(),
        date: addDays(today, -7),
        value: 5,
      },
      {
        id: window.crypto.randomUUID(),
        date: addDays(today, -5),
        value: 3,
      },
      {
        id: window.crypto.randomUUID(),
        date: addDays(today, -2),
        value: 10,
      },
      {
        id: window.crypto.randomUUID(),
        date: today,
        value: currentValue,
      },
    ],
    [today],
  );

  const startDate = addDays(today, -7);
  const endDate = endOfDay(today);

  return (
    <LifeAreaInsightsCard
      lifeArea={lifeArea}
      entries={entries}
      startDate={startDate}
      endDate={endDate}
      currentValue={currentValue}
      target={target}
      average={average}
      isPreview
    />
  );
};
