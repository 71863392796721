import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Locales } from 'shared/assets/localization';
import { completeSignupAndLogin } from 'shared/services/backend-api';
import { ResetPasswordErrorTypes } from 'shared/types/reset-password-form';
import { User } from 'shared/types/user';

import { queryKey as useUserKey } from './use-user-query';

export const useCompleteSignupAndLoginMutation = () => {
  const client = useQueryClient();

  return useMutation<
    User | null,
    ResetPasswordErrorTypes,
    {
      password: string;
      code: string;
      name?: string;
      locale?: Locales;
    }
  >({
    mutationFn: async ({ password, code, name, locale }) => {
      const user = await completeSignupAndLogin(code, password, {
        name,
        locale,
      });

      await client.invalidateQueries({ queryKey: [useUserKey] });

      return user;
    },
  });
};
