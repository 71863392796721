import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Check,
  DotsMenu,
  IconFormIcons,
  SlimArrowUp,
  Target,
} from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { IconButton } from 'shared/components/ui/icon-button';
import { TimeLeft } from 'shared/components/ui/time-left';
import { TimeProgressChart } from 'shared/components/ui/time-progress-chart';
import { Entry } from 'shared/lib/recharts';
import { Paths } from 'shared/routes';
import { Goal } from 'shared/types/goal';
import { LifeArea } from 'shared/types/life-area';
import { metricUnitFormatBase } from 'shared/types/metric-template';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { formatNumberShort } from 'shared/utils/format-number-short';
import { getGoalColor } from 'shared/utils/get-goal-color';
import { isNumber } from 'shared/utils/is-number';
import { replaceUrlParams } from 'shared/utils/replace-url-params';

import * as Styled from './goal-insights-card.style';

export type GoalInsightsCardProps = {
  goal: Goal;
  entries: Entry[];
  completionPercentage?: number;
  unitFormat?: string;
  startValue?: number;
  currentValue?: number;
  target?: number;
  timeframeDifference?: number;
  goals: Goal[];
  lifeAreas: LifeArea[];
  startDate: Timestamp;
  endDate: Timestamp;
  menuButtonRef?: React.RefObject<HTMLButtonElement>;
  onMenuButton?: (e: React.MouseEvent) => void;
  weekStartsOn: WeekDays;
  isLink?: boolean;
};

export const GoalInsightsCard: React.FC<GoalInsightsCardProps> = ({
  goal,
  entries,
  completionPercentage,
  unitFormat,
  startValue,
  currentValue,
  target,
  timeframeDifference,
  goals,
  lifeAreas,
  startDate,
  endDate,
  menuButtonRef,
  onMenuButton,
  weekStartsOn,
  isLink,
}) => {
  const { t } = useTranslation();
  const color = useMemo(
    () => getGoalColor(goal.id, { goals, lifeAreas }),
    [goal.id, goals, lifeAreas],
  );

  const lastEntryValue =
    currentValue ?? entries?.[entries.length - 1]?.value ?? startValue;
  const formattedTarget = isNumber(target)
    ? formatNumberShort(target!)
    : undefined;
  const formattedLastEntryValue = formatNumberShort(lastEntryValue ?? 0);

  const _onMenuButton = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onMenuButton?.(e);
  };

  return (
    <Styled.Container
      as={!isLink ? 'span' : undefined}
      to={replaceUrlParams(Paths.GoalDetail, { id: goal.id })}
    >
      <Styled.Header>
        <Styled.GoalInformationContainer>
          <Styled.IllustrationContainer>
            <Styled.ImageContainer>
              {goal.image ? (
                <Styled.Image
                  src={goal.image.url}
                  alt={goal.name}
                  hash={goal.image.blurHash}
                />
              ) : (
                <Icon icon={IconFormIcons[goal.iconName!] ?? Target} />
              )}
            </Styled.ImageContainer>

            {!!goal.completedAt && (
              <Styled.CompletedContainer>
                <Icon icon={Check} />
              </Styled.CompletedContainer>
            )}

            {!!color && <Styled.ColorDot $color={color} />}
          </Styled.IllustrationContainer>

          <Styled.Title $isCompleted={!!goal.completedAt}>
            {goal.name}
          </Styled.Title>
        </Styled.GoalInformationContainer>

        <IconButton
          icon={DotsMenu}
          onClick={_onMenuButton}
          ref={menuButtonRef}
        />
      </Styled.Header>

      <Styled.Body>
        <Styled.CurrentProgress>
          {!!unitFormat ? (
            <>
              <Styled.CurrentProgressValue>
                {formattedTarget
                  ? formattedLastEntryValue
                  : unitFormat?.replace(
                      metricUnitFormatBase,
                      formattedLastEntryValue,
                    )}
              </Styled.CurrentProgressValue>
              {formattedTarget
                ? ` / ${unitFormat?.replace(metricUnitFormatBase, formattedTarget)}`
                : null}
            </>
          ) : (
            t('general.labels.insights-card.no-tasks.label')
          )}
        </Styled.CurrentProgress>

        <Styled.ProgressContainer>
          <Styled.Progress>
            {!goal.completedAt && !!goal.deadline && (
              <>
                <TimeLeft date={goal.deadline} isShort />
                <Styled.Dot />
              </>
            )}
            <Typography variant="overline">{completionPercentage}%</Typography>
          </Styled.Progress>

          {!!timeframeDifference && (
            <Styled.Difference $difference={timeframeDifference}>
              <Styled.DifferenceIconContainer
                $positive={timeframeDifference > 0}
              >
                <Icon icon={SlimArrowUp} />
              </Styled.DifferenceIconContainer>
              {timeframeDifference.toFixed(1)}%
            </Styled.Difference>
          )}
        </Styled.ProgressContainer>
      </Styled.Body>

      <Styled.Footer>
        <TimeProgressChart
          data={entries}
          startDate={goal.startDate}
          endDate={goal.deadline}
          chartStartDate={startDate}
          chartEndDate={endDate}
          endValue={target}
          weekStartsOn={weekStartsOn}
          hideYAxis
        />
      </Styled.Footer>
    </Styled.Container>
  );
};
