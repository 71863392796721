import { addDays, addQuarters, startOfQuarter } from 'date-fns';
import { useMemo } from 'react';
import { useToday } from 'shared/contexts/today';

import { createRoadmapSection } from '../utils/create-roadmap-section';

const amountOfQuarters = 20;

export const useQuarterlySections = () => {
  const today = useToday();

  return useMemo(() => {
    const tomorrow = addDays(today, 1);
    const quarterOfTomorrow = startOfQuarter(tomorrow);
    return Array.from({ length: amountOfQuarters }, (_, index) => {
      const quarterStartDate =
        index === 0 ? tomorrow : addQuarters(quarterOfTomorrow, index);

      return createRoadmapSection(quarterStartDate);
    });
  }, [today]);
};
