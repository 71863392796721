import React, { Suspense } from 'react';
import { ProgressChartContainer } from 'shared/components/ui/progress-chart-container';

const TimeProgressChart = React.lazy(async () => ({
  default: (await import('shared/components/ui/time-progress-chart'))
    .TimeProgressChart,
}));

export type ProgressChartSectionProps = Omit<
  React.ComponentProps<typeof ProgressChartContainer>,
  'children'
> &
  Omit<React.ComponentProps<typeof TimeProgressChart>, 'endValue'>;

export const ProgressChartSection: React.FC<ProgressChartSectionProps> = ({
  target,
  lastEntryValue,
  targetFormat,
  onUpdateProgress,
  onShowHistory,
  onEditTarget,
  onEditStartDate,
  onEditDeadline,
  onComplete,
  isCompleted,
  data,
  startDate,
  endDate,
  startValue,
  completed,
  weekStartsOn,
}) => (
  <ProgressChartContainer
    target={target}
    lastEntryValue={lastEntryValue}
    targetFormat={targetFormat}
    onUpdateProgress={onUpdateProgress}
    onShowHistory={onShowHistory}
    onEditTarget={onEditTarget}
    onEditStartDate={onEditStartDate}
    onEditDeadline={onEditDeadline}
    onComplete={onComplete}
    isCompleted={isCompleted}
  >
    <Suspense>
      <TimeProgressChart
        data={data}
        startDate={startDate}
        endDate={endDate}
        startValue={startValue}
        endValue={target}
        completed={completed}
        weekStartsOn={weekStartsOn}
        showTodayCount
        showTodayLine
      />
    </Suspense>
  </ProgressChartContainer>
);
