import {
  useCompleteHabitDate,
  useHabitEntries,
  useHabitProgress,
} from 'features/tasks';
import React, { useState } from 'react';
import { InsightsTimeframeSelection } from 'shared/components/ui/insights-timeframe-selection';
import { useTimeByInsightsTimeframe } from 'shared/hooks/use-time-by-insights-timeframe';
import { useUser } from 'shared/hooks/use-user';
import { Habit } from 'shared/types/habit';
import { InsightsTimeframes } from 'shared/types/insights';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';

import { Statistics } from './statistics';
import * as Styled from './task-statistics.style';
import { TimeframeSpecifics } from './timeframe-specifics';

export type TaskStatisticsProps = {
  task: Habit;
};

export const TaskStatistics: React.FC<TaskStatisticsProps> = ({ task }) => {
  const user = useUser();

  const [timeframe, setTimeframe] = useState(
    user?.settings?.insights?.timeframe ?? InsightsTimeframes.Year,
  );

  const weekStartsOn = user?.settings?.startOfWeek ?? WeekDays.Monday;
  const { startDate, endDate } = useTimeByInsightsTimeframe(timeframe, {
    weekStartsOn,
    quarterRoundedToWeeks: true,
  });

  const {
    timeframeOccurrences,
    completions,
    skips,
    completionPercentage,
    entryTarget,
    target,
  } = useHabitProgress(task, {
    timeframeType: timeframe,
    startDate,
    endDate,
    weekStartsOn: user?.settings?.startOfWeek,
  });

  const { entries } = useHabitEntries({
    timeframe,
    startDate,
    endDate,
    completions,
  });

  const completeHabitDate = useCompleteHabitDate();

  const onDate = (date: Timestamp) => {
    completeHabitDate(task, date, true);
  };

  return (
    <>
      <Styled.Header>
        <Styled.Title>{task.name}</Styled.Title>
        <InsightsTimeframeSelection value={timeframe} onChange={setTimeframe} />
      </Styled.Header>

      <Styled.Body>
        <Statistics
          target={target}
          completionPercentage={completionPercentage}
          completions={completions}
          skips={skips}
        />

        <TimeframeSpecifics
          timeframe={timeframe}
          startDate={startDate}
          endDate={endDate}
          weekStartsOn={weekStartsOn}
          entries={entries}
          entryTarget={entryTarget}
          occurrences={timeframeOccurrences}
          completions={completions}
          skips={skips}
          target={target}
          onDate={onDate}
        />
      </Styled.Body>
    </>
  );
};
