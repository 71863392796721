import { DndContext } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React from 'react';
import { GoalListCard } from 'shared/components/ui/goal-list-card';
import { SortableOverlay } from 'shared/components/ui/sortable-overlay';
import { useSortableItemsControls } from 'shared/hooks/use-sortable-items-controls';
import { useSortableSensors } from 'shared/hooks/use-sortable-sensors';
import { ID } from 'shared/types/id';

import { GoalListSection } from './goal-list-section';

export type SelfSortingGoalListSectionProps = Omit<
  React.ComponentProps<typeof GoalListSection>,
  'canReorder'
> & {
  onReorder: (ids: ID[]) => void;
};

export const SelfSortingGoalListSection: React.FC<
  SelfSortingGoalListSectionProps
> = ({ allGoals, goals, onReorder, ...rest }) => {
  const {
    items,
    activeItem,
    handleDragStart,
    handleDragCancel,
    handleDragEnd,
  } = useSortableItemsControls({ items: goals, onDragEnd: onReorder });

  const sensors = useSortableSensors();

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      sensors={sensors}
    >
      <SortableContext
        items={items.map(({ id }) => id)}
        strategy={verticalListSortingStrategy}
        disabled={!onReorder}
      >
        <GoalListSection
          goals={items}
          allGoals={allGoals}
          canReorder
          {...rest}
        />

        <SortableOverlay>
          {!!activeItem && (
            <GoalListCard goal={activeItem} goals={allGoals} {...rest} />
          )}
        </SortableOverlay>
      </SortableContext>
    </DndContext>
  );
};
