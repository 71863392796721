import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Target } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';

import * as Styled from './create-goal-card.style';

export type CreateGoalCardProps = {
  onCreateGoal: () => void;
  isSubGoal?: boolean;
};

export const CreateGoalCard: React.FC<CreateGoalCardProps> = ({
  onCreateGoal,
  isSubGoal,
}) => {
  const { t } = useTranslation();

  return (
    <Styled.Container onClick={onCreateGoal}>
      <Styled.IconContainer>
        <Icon icon={Target} />
      </Styled.IconContainer>

      <Typography variant="subtitle2" component="span">
        {t(
          isSubGoal
            ? 'general.labels.create-sub-goal.label'
            : 'general.labels.create-goal.label',
        )}
      </Typography>
    </Styled.Container>
  );
};
