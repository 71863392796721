import { addYears, getYear } from 'date-fns';
import { FirestoreDataConverter } from 'firebase/firestore';
import { Entitlements } from 'shared/types/entitlements';
import { InsightsTimeframes, insightsTimeframes } from 'shared/types/insights';
import { LimitKeys } from 'shared/types/limit-keys';
import { SortingMode, TodayGroupBy } from 'shared/types/sorting-mode';
import { User } from 'shared/types/user';
import { FirestoreUserLimits, UserLimits } from 'shared/types/user-limits';
import { initialWeekStartsOn } from 'shared/types/week-days';
import { getLimits, getPremiumLimits } from 'shared/utils/get-fallback-limits';

import { firebaseTimestampToDate } from './firebase-timestamp-to-date';

const todayGroupByOptions = Object.values(TodayGroupBy);

const processLimits = (
  limits: FirestoreUserLimits | null | undefined,
  isPremium: boolean,
) => {
  const limitBase = isPremium ? getPremiumLimits() : getLimits();
  return Object.keys(limitBase).reduce<UserLimits>((acc, limitKey) => {
    const userLimit = limits?.[limitKey as LimitKeys];
    const limitBackup = limitBase[limitKey as LimitKeys];

    acc[limitKey as LimitKeys] = {
      ...(userLimit ?? limitBackup),
      enforcedAt: userLimit?.enforcedAt
        ? firebaseTimestampToDate(userLimit.enforcedAt)!
        : new Date(),
    };

    return acc;
  }, {} as UserLimits);
};

export const userConverter: FirestoreDataConverter<User> = {
  fromFirestore: (snapshot) => {
    const data = snapshot.data();

    return {
      ...data,
      name: data.name ?? '',
      settings: {
        ...(data.settings ?? {}),
        startOfWeek: data.settings?.startOfWeek ?? initialWeekStartsOn,
        goalTabSorting: {
          ...(data.settings?.goalTabSorting ?? {}),
          all: {
            sorting: data.settings?.goalSorting,
            ...(data.settings?.goalTabSorting?.all ?? {}),
          },
        },
        goalRoadmapSorting: data.settings?.goalRoadmapSorting ?? {
          // add deprecated sorting values if new sorting isn't used already
          inProgress: data.settings?.goalTabSorting?.active?.sorting ?? [],
          [getYear(new Date())]:
            data.settings?.goalTabSorting?.[getYear(new Date())]?.sorting ?? [],
          [getYear(addYears(new Date(), 1))]:
            data.settings?.goalTabSorting?.[getYear(new Date())]?.sorting ?? [],
        },
        todayGroupBy: todayGroupByOptions.includes(data.settings?.todayGroupBy)
          ? data.settings?.todayGroupBy
          : TodayGroupBy.None,
        todoSortingMode: data.settings?.todoSortingMode ?? SortingMode.DueDate,
        insights: {
          ...(data.settings?.insights ?? {}),
          timeframe: data.settings?.insights?.timeframe
            ? insightsTimeframes.includes(data.settings?.insights?.timeframe)
              ? data.settings?.insights?.timeframe
              : InsightsTimeframes.Year
            : null,
        },
      },
      limits: processLimits(
        data.limits,
        !!data.entitlements?.includes(Entitlements.Premium),
      ),
    } as User;
  },
  toFirestore: (user) => user,
};
