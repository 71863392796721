import styled from 'styled-components';

export const WidthRef = styled.div`
  width: 100%;
`;

export const List = styled.ul<{ $columns: number }>`
  position: relative;
  display: grid;
  grid-template-columns: ${({ $columns }) =>
    `repeat(${$columns}, minmax(0, 1fr))`};
  gap: ${({ theme }) => theme.spacing(3)};

  list-style: none;
  min-height: 23.2rem;
  width: 100%;
  margin: 0;
  padding: ${({ theme }) =>
    theme.isMobile
      ? `${theme.spacing(1.5)} ${theme.spacing(0.5)}`
      : `${theme.spacing(1.5)} ${theme.spacing(0.5)} ${theme.spacing(0.5)} ${theme.spacing(6)}`};
`;

export const ListItem = styled.li``;

export const PremiumBannerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
`;
