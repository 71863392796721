import React, { useEffect } from 'react';

export const useListColumns = (
  ref: React.RefObject<HTMLElement>,
  initialValue = 2,
) => {
  const [listColumns, setListColumns] = React.useState(initialValue);

  useEffect(() => {
    const container = ref.current;
    if (!container) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) =>
      entries.forEach((entry) =>
        setListColumns(
          entry.contentRect.width > 800
            ? 4
            : entry.contentRect.width > 600
              ? 3
              : 2,
        ),
      ),
    );
    resizeObserver.observe(container);

    setListColumns(
      container.clientWidth > 800 ? 4 : container.clientWidth > 600 ? 3 : 2,
    );

    return () => resizeObserver.unobserve(container);
  }, [ref]);

  return listColumns;
};
