import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import React, { useMemo } from 'react';
import { GoalListSection } from 'shared/components/ui/goal-list-section';
import { GridContainer } from 'shared/components/ui/grid-container';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useToday } from 'shared/contexts/today';

import * as Styled from './archived.style';
import { TabPanel } from './components/tab-panel';
import { GoalsFilterOptions, goalsFilters } from './utils/goals-filter';

export const Archived: React.FC = () => {
  const today = useToday();
  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();

  const openPremiumDialog = useOpenPremiumDialog();

  const filteredGoals = useMemo(
    () =>
      goalsFilters[GoalsFilterOptions.Archived](goals, today)
        .filter(({ parentIds }) => !parentIds?.length)
        .sort(
          (goalA, goalB) =>
            goalB.archivedAt!.getTime() - goalA.archivedAt!.getTime(),
        ),
    [goals, today],
  );

  return (
    <GridContainer>
      <TabPanel role="tab-panel">
        <Styled.Container>
          <GoalListSection
            goals={filteredGoals}
            allGoals={goals}
            lifeAreas={lifeAreas}
            onPremium={openPremiumDialog}
          />
        </Styled.Container>
      </TabPanel>
    </GridContainer>
  );
};
