import { useGoals } from 'features/goals';
import { useGoalSorting } from 'features/user';
import { useMemo } from 'react';
import { useToday } from 'shared/contexts/today';
import { GoalRoadmapSortingKeys } from 'shared/types/user-settings';
import { sortItems } from 'shared/utils/sort-items';

import { GoalsFilterOptions, goalsFilters } from '../utils/goals-filter';

export const useActiveGoals = () => {
  const today = useToday();
  const goals = useGoals();
  const goalSorting = useGoalSorting(GoalRoadmapSortingKeys.InProgress);

  const filteredGoals = useMemo(
    () =>
      sortItems(
        goalsFilters[GoalsFilterOptions.Active](goals, today).filter(
          ({ parentIds }) => !parentIds?.length,
        ),
        goalSorting,
        'id',
      ),
    [goalSorting, goals, today],
  );

  const activeGoals = useMemo(
    () => filteredGoals.filter(({ frozenAt }) => !frozenAt),
    [filteredGoals],
  );

  const frozenGoals = useMemo(
    () => filteredGoals.filter(({ frozenAt }) => !!frozenAt),
    [filteredGoals],
  );

  return { goals: filteredGoals, activeGoals, frozenGoals };
};
