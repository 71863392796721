import Typography from '@mui/material/Typography';
import {
  addMonths,
  eachMonthOfInterval,
  endOfMonth,
  startOfMonth,
} from 'date-fns';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HabitProgressCalendar } from 'shared/components/ui/habit-progress-calendar';
import { Section } from 'shared/components/ui/section';
import { TimeBarChart } from 'shared/components/ui/time-bar-chart';
import { isBetween } from 'shared/lib/date-fns';
import { Entry } from 'shared/lib/recharts';
import { InsightsTimeframes } from 'shared/types/insights';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';

import * as Styled from './timeframe-specifics.style';

const timeframeBarChartSectionTitleMap = {
  [InsightsTimeframes.Week]:
    'general.labels.task-statistics.sections.this-week.label',
  [InsightsTimeframes.Quarter]:
    'general.labels.task-statistics.sections.this-quarter.label',
  [InsightsTimeframes.Year]:
    'general.labels.task-statistics.sections.this-year.label',
  [InsightsTimeframes.FourWeeks]:
    'general.labels.task-statistics.sections.last-four-weeks.label',
  [InsightsTimeframes.TwelveWeeks]:
    'general.labels.task-statistics.sections.last-twelve-weeks.label',
  [InsightsTimeframes.TwelveMonths]:
    'general.labels.task-statistics.sections.last-twelve-months.label',
};

export type TimeframeSpecificsProps = {
  timeframe: InsightsTimeframes;
  startDate: Timestamp;
  endDate: Timestamp;
  weekStartsOn: WeekDays;
  entries?: Entry[];
  entryTarget?: number;
  target?: number;
  occurrences: Timestamp[];
  completions: Timestamp[];
  skips: Timestamp[];
  onDate?: (date: Timestamp) => void;
};

export const TimeframeSpecifics: React.FC<TimeframeSpecificsProps> = ({
  timeframe,
  startDate,
  endDate,
  weekStartsOn,
  entries,
  entryTarget,
  target,
  occurrences,
  completions,
  skips,
  onDate,
}) => {
  const { t } = useTranslation();

  const months = useMemo(
    () =>
      eachMonthOfInterval({ start: startDate, end: endDate }).map(
        (_, index) => {
          const monthStartDate = startOfMonth(addMonths(startDate, index));
          const monthEndDate = endOfMonth(addMonths(startDate, index));
          const monthOccurrences = occurrences.filter((occurrence) =>
            isBetween(occurrence, monthStartDate, monthEndDate),
          );
          const monthCompletions = completions.filter((completion) =>
            isBetween(completion, monthStartDate, monthEndDate),
          );

          return {
            date: monthStartDate,
            occurrences: monthOccurrences,
            completions: monthCompletions,
            completionPercentage: monthOccurrences.length
              ? Math.floor(
                  (monthCompletions.length / monthOccurrences.length) * 100,
                )
              : 0,
            skips: skips.filter((skip) =>
              isBetween(skip, monthStartDate, monthEndDate),
            ),
          };
        },
      ),
    [completions, endDate, occurrences, skips, startDate],
  );

  return (
    <>
      {timeframe !== InsightsTimeframes.Month && entries && (
        <Section title={t(timeframeBarChartSectionTitleMap[timeframe])}>
          <Styled.ChartContainer>
            <Styled.BarChartContainer>
              <Styled.BarChartHeader>
                <Typography variant="h3" component="span">
                  {completions.length}
                </Typography>
                {!!target && ` / ${target}`}
              </Styled.BarChartHeader>

              <TimeBarChart
                data={entries}
                weekStartsOn={weekStartsOn}
                target={entryTarget}
                startDate={startDate}
                endDate={endDate}
                showYAxis
              />
            </Styled.BarChartContainer>
          </Styled.ChartContainer>
        </Section>
      )}

      <Section
        title={t('general.labels.task-statistics.sections.calendar.label')}
      >
        {months.map(
          ({ date, completions, completionPercentage, occurrences, skips }) => (
            <Styled.CalendarContainer key={date.getTime()}>
              <HabitProgressCalendar
                openToDate={date}
                occurrences={occurrences}
                completions={completions}
                completionPercentage={completionPercentage}
                skips={skips}
                weekStartsOn={weekStartsOn}
                onChange={onDate}
                showDates
                showHeader
                isCard
              />
            </Styled.CalendarContainer>
          ),
        )}
      </Section>
    </>
  );
};
