export enum CollectionOptions {
  Users = 'users',
  Goals = 'goals',
  Tasks = 'tasks',
  LifeAreas = 'life-areas',
  Lifescans = 'lifescans',
  Journal = 'journal',
  Visions = 'visions',
  GoalTemplates = 'goal-templates',
  FcmTokens = 'fcm-tokens',
}
