import React from 'react';
import { ChevronLeft } from 'shared/assets/icons';
import { Collapse } from 'shared/components/ui/collapse';
import {
  IconButton,
  Sizes as IconButtonSizes,
} from 'shared/components/ui/icon-button';
import { useToggle } from 'shared/hooks/use-toggle';
import { ID } from 'shared/types/id';
import { useTheme } from 'styled-components';

import { headerMap } from './header-map';
import * as Styled from './list-section-header.style';
import { HeaderTypeProps, HeaderTypes } from './types';

export type ListSectionLayoutProps<HeaderType extends HeaderTypes> = {
  id?: ID;
  onHeader?: (id?: ID) => void;
  headerType?: HeaderType;
  headerProps?: HeaderTypeProps[HeaderType];
  customHeader?: React.ReactNode;
  canCollapse?: boolean;
  initialOpen?: boolean;
  smallTopSpacing?: boolean;
  children: React.ReactNode;
};

export const ListSectionLayout = <HeaderType extends HeaderTypes>({
  id,
  onHeader,
  headerType,
  headerProps,
  customHeader,
  canCollapse,
  initialOpen = true,
  smallTopSpacing,
  children,
}: ListSectionLayoutProps<HeaderType>): ReturnType<React.FC> => {
  const theme = useTheme();
  const [collapseOpen, toggleCollapse] = useToggle(initialOpen);

  const _onHeader = () =>
    onHeader ? onHeader(id) : canCollapse && toggleCollapse();

  const CollapseContainer = canCollapse ? Collapse : React.Fragment;
  const collapseProps = canCollapse ? { isOpen: collapseOpen } : {};

  const HeaderToDisplay = headerType
    ? (headerMap[headerType] as React.FC<HeaderTypeProps[HeaderType]>)
    : undefined;

  return (
    <>
      <Styled.HeaderContainer
        onClick={_onHeader}
        $smallTopSpacing={!!smallTopSpacing}
      >
        {!!canCollapse && (
          <Styled.IconButtonContainer>
            <IconButton
              icon={ChevronLeft}
              rotation={collapseOpen ? 270 : theme.isMobile ? 90 : 180}
              size={
                theme.isMobile
                  ? IconButtonSizes.Large
                  : IconButtonSizes.ExtraSmall
              }
              as="span"
            />
          </Styled.IconButtonContainer>
        )}

        {customHeader}

        {!customHeader && !!headerProps && !!HeaderToDisplay && (
          <HeaderToDisplay {...(headerProps as HeaderTypeProps[HeaderType])} />
        )}
      </Styled.HeaderContainer>

      <CollapseContainer {...collapseProps}>{children}</CollapseContainer>
    </>
  );
};
