import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import React from 'react';
import { ArrowRight, BarChart2, CheckCircle } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { Timestamp } from 'shared/types/timestamp';

import * as Styled from './header.style';
import { Colors } from './types';

type StatusProps = {
  value: number;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  color: Colors;
  isPercentage?: boolean;
};

const StatusItem: React.FC<StatusProps> = ({
  value,
  icon,
  color,
  isPercentage,
}) => (
  <Styled.StatusContainer>
    <Styled.StatusIcon $color={color}>
      <Icon icon={icon} />
    </Styled.StatusIcon>

    <Typography variant="overline">
      {isPercentage ? (value ? `${value}%` : '-') : value || 0}
    </Typography>
  </Styled.StatusContainer>
);

export type HeaderProps = {
  date: Timestamp;
  completions: Timestamp[];
  completionsPercentage?: number;
  skips: Timestamp[];
  failures: Timestamp[];
  hideStatus?: boolean;
};

export const Header: React.FC<HeaderProps> = ({
  date,
  completions,
  completionsPercentage,
  skips,
  failures,
  hideStatus,
}) => (
  <Styled.Container>
    <Typography variant="h5">{format(date, "LLL ''yy")}</Typography>

    {!hideStatus && (
      <Styled.StatusList>
        <Styled.StatusItem>
          <StatusItem
            value={completions.length}
            icon={CheckCircle}
            color={Colors.Primary}
          />
        </Styled.StatusItem>
        <Styled.StatusItem>
          <StatusItem
            value={completionsPercentage ?? 0}
            icon={BarChart2}
            color={Colors.Info}
            isPercentage
          />
        </Styled.StatusItem>
        <Styled.StatusItem>
          <StatusItem
            value={skips.length}
            icon={ArrowRight}
            color={Colors.Warning}
          />
        </Styled.StatusItem>
        {/*<Styled.StatusItem>*/}
        {/*  <StatusItem value={failures.length} icon={X} color={Colors.Error} />*/}
        {/*</Styled.StatusItem>*/}
      </Styled.StatusList>
    )}
  </Styled.Container>
);
