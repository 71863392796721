import { GridContainer } from 'shared/components/ui/grid-container';

import { GoalsInsights } from './components/goals-insights';
import { HabitsInsights } from './components/habits-insights';
import { Header } from './components/header';
import { LifescanInsights } from './components/lifescan-insights';
import * as Styled from './insights.style';

export const Insights = () => (
  <GridContainer>
    <Header />

    <Styled.Content>
      <GoalsInsights />
      <HabitsInsights />
      <LifescanInsights />
    </Styled.Content>
  </GridContainer>
);
