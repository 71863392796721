import { useDroppable } from '@dnd-kit/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconFormIcons, Target } from 'shared/assets/icons';
import { GoalListSection } from 'shared/components/ui/goal-list-section';
import { ListSectionLayout } from 'shared/components/ui/list-section-header';
import { HeaderTypes } from 'shared/components/ui/list-section-header/types';
import { ID } from 'shared/types/id';

import * as Styled from './life-area-section.style';

export type LifeAreaSectionProps = React.ComponentProps<
  typeof GoalListSection
> & {
  droppableId: ID;
};

export const LifeAreaSection: React.FC<LifeAreaSectionProps> = ({
  droppableId,

  lifeAreas,
  ...rest
}) => {
  const { t } = useTranslation();

  const { setNodeRef } = useDroppable({
    id: droppableId,
    disabled: !droppableId,
  });

  const lifeArea = useMemo(
    () => lifeAreas.find(({ id }) => droppableId === id),
    [droppableId, lifeAreas],
  );

  const containerProps = droppableId ? { ref: setNodeRef } : {};

  const headerProps = {
    id: droppableId,
    name: lifeArea?.name ?? t('general.sections.life-areas.other.title'),
    image: lifeArea?.visionPreview?.image,
    icon: lifeArea ? IconFormIcons[lifeArea.iconName] : Target,
    color: lifeArea?.color ?? undefined,
    isFrozen: !!lifeArea?.frozenAt,
  };

  return (
    <Styled.Container {...containerProps}>
      <ListSectionLayout
        headerType={HeaderTypes.LifeArea}
        headerProps={headerProps}
        canCollapse
      >
        <Styled.ListContainer>
          <GoalListSection lifeAreas={lifeAreas} {...rest} />
        </Styled.ListContainer>
      </ListSectionLayout>
    </Styled.Container>
  );
};
