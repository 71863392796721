import { doc, serverTimestamp } from 'firebase/firestore';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { chunkArray } from 'shared/utils/chunk-array';

import { getTaskDocs } from './get-tasks';
import {
  BatchDocuments,
  CollectionOptions,
  getCollection,
  getDatabase,
} from './helpers';

export type Options = {
  sectionId: ID;
  from: Goal;
  to: Goal;
};

export const transferSectionToGoal = async ({
  sectionId,
  from,
  to,
}: Options) => {
  const batchDocuments = new BatchDocuments(getDatabase());
  const goalCollection = getCollection(CollectionOptions.Goals);
  const taskCollection = getCollection(CollectionOptions.Tasks);

  // get the current section from the "from" goal
  const section = from.sections?.find(({ id }) => id === sectionId);

  if (!section) {
    return;
  }

  // get all section tasks (chunked as there could be more than 30 which is the limit of firestore
  const tasks = (
    await Promise.all(
      chunkArray(section.tasks, 30).map(
        async (taskIds) => await getTaskDocs({ ids: taskIds }),
      ),
    )
  ).flat(1);
  const completedTasks = tasks.filter(({ completedAt }) => !!completedAt);

  // update "to" goal to have the section
  batchDocuments.updateDocument({
    data: {
      sections: [...(to.sections ?? []), section],
      taskCount: Math.max((to.taskCount ?? 0) + tasks.length, 0),
      completedTaskCount: Math.max(
        (to.completedTaskCount ?? 0) + completedTasks.length,
        0,
      ),
      updatedAt: serverTimestamp(),
    },
    ref: doc(goalCollection, to.id),
  });

  // update "from" goal to not have the section anymore
  batchDocuments.updateDocument({
    data: {
      sections: from.sections?.filter(({ id }) => id !== sectionId) ?? null,
      taskCount: Math.max((from.taskCount ?? 0) - tasks.length, 0),
      completedTaskCount: Math.max(
        (from.completedTaskCount ?? 0) - completedTasks.length,
        0,
      ),
      updatedAt: serverTimestamp(),
    },
    ref: doc(goalCollection, from.id),
  });

  // update tasks to have the "to" goal id
  tasks.forEach((task) => {
    batchDocuments.updateDocument({
      data: { goalId: to.id, updatedAt: serverTimestamp() },
      ref: doc(taskCollection, task.id),
    });
  });

  batchDocuments.commit();
};
