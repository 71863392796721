import {
  differenceInWeeks,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isSameMonth,
} from 'date-fns';
import { useMemo } from 'react';

import { TickRange } from './types';

export const useTickRange = (domain: [number, number]) => {
  return useMemo(() => {
    if (
      isSameMonth(domain[0], domain[1]) &&
      isFirstDayOfMonth(domain[0]) &&
      isLastDayOfMonth(domain[1])
    ) {
      return TickRange.Month;
    }

    const difInWeeks = differenceInWeeks(domain[1], domain[0]);

    return difInWeeks <= 1
      ? TickRange.Day
      : difInWeeks < 6
        ? TickRange.Week
        : TickRange.Months;
  }, [domain]);
};
