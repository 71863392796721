import styled, { css } from 'styled-components';

export const List = styled.ul<{ $noRightSpacing: boolean }>`
  list-style: none;

  margin: 0;
  padding: ${({ theme, $noRightSpacing }) =>
    `0 ${$noRightSpacing ? 0 : theme.spacing(1)} 0 ${theme.spacing(1)}`};
`;

export const ListItem = styled.li<{ $hasIndentation?: boolean }>`
  ${({ $hasIndentation, theme }) =>
    $hasIndentation &&
    !theme.isMobile &&
    css`
      padding-left: ${theme.spacing(4)};
    `};
`;

export const GoalContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacing(0.75)} ${theme.spacing(5)}`};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: ${({ theme }) => `${theme.spacing(0.75)} ${theme.spacing(2)}`};
    `}
`;

export const DroppableArea = styled.div``;

export const NewTaskContainer = styled.div<{ $hasIndentation: boolean }>`
  padding: ${({ theme, $hasIndentation }) =>
    `0 ${theme.spacing($hasIndentation ? 1 : 5)}`};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: 0;
    `};
`;
