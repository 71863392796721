import {
  useCreateSubGoalAllowed,
  useTransformSectionToGoal,
} from 'features/goals';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ArrowDownRight, ArrowUpRight } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { Paths } from 'shared/routes';
import { Goal } from 'shared/types/goal';
import { replaceUrlParams } from 'shared/utils/replace-url-params';

export type MoveListProps = {
  goal: Goal;
  sectionId: string;
  onClose: () => void;
};

export const MoveList: React.FC<MoveListProps> = ({
  goal,
  sectionId,
  onClose,
}) => {
  const { t } = useTranslation();
  const transformToGoal = useTransformSectionToGoal();
  const navigate = useNavigate();
  const { allowed: createSubGoalAllowed } = useCreateSubGoalAllowed();
  const openPremiumDialog = useOpenPremiumDialog();

  const onTransformToMainGoal = async (e: React.MouseEvent) => {
    e.preventDefault();
    const newGoalId = await transformToGoal(sectionId, { goal });
    if (newGoalId) {
      navigate(replaceUrlParams(Paths.GoalDetail, { id: newGoalId }));
    }
    onClose();
  };

  const onTransformToSubGoal = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (!createSubGoalAllowed) {
      openPremiumDialog();
      onClose();
      return;
    }
    const newGoalId = await transformToGoal(sectionId, {
      goal,
      isSubGoal: true,
    });
    if (newGoalId) {
      navigate(replaceUrlParams(Paths.GoalDetail, { id: newGoalId }));
    }
    onClose();
  };

  return (
    <PopupMenuList hasBottomBorder>
      <PopupMenuListItem>
        <PopupMenuButton
          onClick={onTransformToMainGoal}
          start={<Icon icon={ArrowUpRight} />}
        >
          {t('context-menus.goal.section.labels.turn-into-goal')}
        </PopupMenuButton>
      </PopupMenuListItem>
      {!goal.parentIds?.length && (
        <PopupMenuListItem>
          <PopupMenuButton
            onClick={onTransformToSubGoal}
            start={<Icon icon={ArrowDownRight} />}
            isPremium={!createSubGoalAllowed}
          >
            {t('context-menus.goal.section.labels.turn-into-sub-goal')}
          </PopupMenuButton>
        </PopupMenuListItem>
      )}
    </PopupMenuList>
  );
};
