import { startOfDay } from 'date-fns';
import { useSubGoals } from 'features/goals';
import { useTasks } from 'features/tasks';
import { useMemo } from 'react';
import { Goal } from 'shared/types/goal';
import { MetricEntry } from 'shared/types/goal-metric';
import { ID } from 'shared/types/id';
import { DefaultMetricOptions } from 'shared/types/metric-template';
import { Timestamp } from 'shared/types/timestamp';
import { formatDateKey } from 'shared/utils/format-date-key';
import { getCompletionPercentage } from 'shared/utils/get-completion-percentage';

import { useTimeframeDifference } from './use-timeframe-difference';

export type Options = {
  startDate: Timestamp;
  endDate: Timestamp;
};

enum EventTypes {
  Task = 'task',
  Goal = 'goal',
  MetricEntry = 'metricEntry',
}

type Event = {
  goalId: ID;
  date: Timestamp;
  value: number;
  type: EventTypes;
};

export const useGoalSubgoalProgress = (
  goal: Goal,
  { startDate, endDate }: Options,
) => {
  const subGoals = useSubGoals(goal.id);
  const allTasks = useTasks(
    { goals: [goal.id, ...subGoals.map(({ id }) => id)] },
    !!goal.id,
  );
  const target = subGoals.length;

  const allGoals = useMemo(() => [goal, ...subGoals], [goal, subGoals]);

  const goalIdGoalMap = useMemo(
    () =>
      allGoals.reduce<Record<ID, Goal>>((acc, subGoal) => {
        acc[subGoal.id] = subGoal;
        return acc;
      }, {}),
    [allGoals],
  );

  const entries = useMemo<MetricEntry[]>(() => {
    // prep some data for later calculations
    const subGoalProgressMap = subGoals.reduce<Record<ID, number>>(
      (acc, goal) => {
        acc[goal.id] = 0;
        return acc;
      },
      {},
    );
    const goalIdTaskCountMap = allGoals.reduce<Record<ID, number>>(
      (acc, { id }) => {
        acc[id] = allTasks.filter(({ goalId }) => goalId === id).length;
        return acc;
      },
      {},
    );

    // gather all events
    const taskCompletedEvents = allTasks
      .filter(({ completedAt, goalId }) => !!completedAt && goalId)
      .map<Event>(({ goalId, completedAt }) => ({
        goalId: goalId!,
        date: completedAt!,
        value: 1,
        type: EventTypes.Task,
      }));
    const subgoalCompletedEvents = subGoals
      .filter(({ completedAt }) => !!completedAt)
      .map<Event>(({ id, completedAt }) => ({
        goalId: id,
        date: completedAt!,
        value: 1,
        type: EventTypes.Goal,
      }));
    const metricEntryEvents = allGoals
      .filter(({ metric }) => !!metric?.entries)
      .map(({ id, metric }) =>
        metric?.entries?.map(({ value, date }) => ({
          goalId: id,
          value,
          date,
          type: EventTypes.MetricEntry,
        })),
      )
      .flat(1)
      .filter(Boolean) as unknown as Event[];

    const combinedEvents = [
      ...taskCompletedEvents,
      ...subgoalCompletedEvents,
      ...metricEntryEvents,
    ].sort((eventA, eventB) => eventA.date.getTime() - eventB.date.getTime());

    const dateEntryMap = combinedEvents.reduce<Record<string, MetricEntry>>(
      (acc, event) => {
        // get the eventDate entry from the accumulator if it already exists
        const dateKey = formatDateKey(event.date);
        const entry = (acc[dateKey] = acc[dateKey] ?? {
          id: dateKey,
          date: startOfDay(event.date),
          value: 0,
        });

        // calculate progress changes
        // if a subgoal is completed, the progress is 100
        if (event.type === EventTypes.Goal) {
          // set the goalProgress of the goal event to 100%
          subGoalProgressMap[event.goalId] = 100;

          // count all percentages of the subgoals together for the total progress of the main goal.
          const totalProgress = Object.values(subGoalProgressMap).reduce(
            (acc, subgoalProgress) => acc + subgoalProgress,
            0,
          );

          // adjust the total progress to represent the correct amount compared to the target
          entry.value =
            (subGoals.length / 100) * (totalProgress / subGoals.length) || 0;

          return acc;
        }

        const goal = goalIdGoalMap[event.goalId];
        const metricType =
          goal.metric?.id ?? DefaultMetricOptions.CompletedTasks;
        // if a subgoal has completedTasks as metric and we have a task event, we need to calculate the completed tasks percentage
        if (
          event.type === EventTypes.Task &&
          metricType === DefaultMetricOptions.CompletedTasks
        ) {
          subGoalProgressMap[event.goalId] +=
            (event.value / goalIdTaskCountMap[event.goalId]) * 100;
        }

        // if a subgoal has metric entries, we need the percentage of that
        if (event.type === EventTypes.MetricEntry) {
          subGoalProgressMap[event.goalId] = getCompletionPercentage({
            start: goal.metric?.startValue ?? 0,
            current: event.value,
            end: goal.metric?.targetValue ?? Infinity,
          });
        }

        // make sure we are not above 100%
        subGoalProgressMap[event.goalId] = Math.min(
          subGoalProgressMap[event.goalId] ?? 0,
          100,
        );

        // count all percentages of the subgoals together for the total progress of the main goal.
        const totalProgress = Object.values(subGoalProgressMap).reduce(
          (acc, subgoalProgress) => acc + subgoalProgress,
          0,
        );
        // adjust the total progress to represent the correct amount compared to the target
        entry.value =
          (subGoals.length / 100) * (totalProgress / subGoals.length) || 0;

        return acc;
      },
      {},
    );

    // get a startDate from the goal
    const goalStartDate = startOfDay(goal.startDate ?? goal.createdAt);

    return [
      { id: formatDateKey(goalStartDate), date: goalStartDate, value: 0 },
      ...Object.values(dateEntryMap),
    ];
  }, [
    allGoals,
    allTasks,
    goal.createdAt,
    goal.startDate,
    goalIdGoalMap,
    subGoals,
  ]);

  const timeframeDifference = useTimeframeDifference({
    entries,
    startDate,
    endDate,
    target,
  });

  const lastEntryValue = entries[entries.length - 1]?.value;
  const completionPercentage = lastEntryValue
    ? Math.floor((lastEntryValue / subGoals.length) * 100)
    : 0;

  return {
    entries,
    completionPercentage,
    completed: subGoals.filter(({ completedAt }) => !!completedAt).length,
    target,
    timeframeDifference,
  };
};
