import { PaletteOptions } from '@mui/material';

export const palette: PaletteOptions = {
  text: {
    primary: '#24041D',
    secondary: '#91818E',
  },
  primary: {
    main: '#2802B1',
    light: '#2802B11A',
    contrastText: '#FFFFFF',
  },
  primaryExtended: {
    opacityBackgroundText: '#2802B1',
    opacityBackgroundBackground: '#2802B11A',
  },
  secondary: {
    main: '#634E4E',
    contrastText: '#FFFFFF',
  },
  background: {
    default: '#FFFFFF',
    paper: '#FAF5F4',
  },
  backgroundExtended: {
    card: '#FFFFFF',
  },
  backdrop: {
    default: '#0000004D',
  },
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  error: {
    main: '#FF7B6B',
    dark: '#FF5B47',
    light: '#FF7B6B33',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#FFAA27',
    dark: '#D86700',
    light: '#FFAA272E',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#8C88EF',
    dark: '#514CD6',
    light: '#8C88EF33',
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#72C1BC',
    dark: '#4DA29D',
    light: '#72C1BC24',
    contrastText: '#FFFFFF',
  },
  divider: '#E7DBD6',
  grey: {
    900: '#191518',
    800: '#393538',
    700: '#585356',
    600: '#6B6669',
    500: '#938E91',
    400: '#B4AEB1',
    300: '#D8D3D6',
    200: '#E9E4E7',
    100: '#F4EEF2',
    50: '#FCF6FA',
  },
  brown: {
    900: '#432926',
    800: '#543632',
    700: '#63423B',
    600: '#734F45',
    500: '#80584C',
    400: '#937067',
    300: '#A78A82',
    200: '#C1ACA6',
    100: '#F2EBE8',
    50: '#F5EFED',
  },
};
