import React from 'react';
import { Timestamp } from 'shared/types/timestamp';

import * as Styled from './bar-chart.style';

export type BarProps = {
  x: number;
  y?: number;
  maxHeight: number;
  date: Timestamp;
  onHover: (date: Timestamp, options?: { left: number; top: number }) => void;
};

export const Bar: React.FC<BarProps> = ({
  x,
  y = 0,
  maxHeight,
  date,
  onHover,
}) => {
  const _onHover = (e: React.MouseEvent) => {
    const rect = e.currentTarget.getBoundingClientRect();
    onHover(date, { left: rect.left + rect.width / 2, top: rect.top });
  };

  const _onUnHover = () => {
    onHover(date);
  };

  return (
    <Styled.Bar
      key={date.getTime()}
      width="0.8rem"
      // make the bar centered on the line by subtracting half the width
      x={x - 4}
      // add rounded borders
      rx="0.4rem"
      ry="0.4rem"
      // add a bit of length to prevent the bottom rounded borders to be shown
      height={Math.max(0, maxHeight - y + 4)}
      // translate the bar a bit down to move the bottom out of view (the same amount as the height is increased
      transform="translate(0, 4)"
      // compensate the transform by removing the same portion from the top
      y={y - 4}
      onMouseEnter={_onHover}
      onMouseLeave={_onUnHover}
    />
  );
};
