import { Placement } from '@floating-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconFormIcons, IconFormNames } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { PopupMenu } from 'shared/components/ui/popup-menu';

import { iconCategoryList } from './icon-category-list';
import * as Styled from './icon-menu.style';

export type IconMenuProps = {
  referenceElement: React.RefObject<HTMLElement>;
  position: Placement;
  onChange: (icon: IconFormNames) => void;
  selectedIcon?: IconFormNames;
};

export const IconMenu: React.FC<IconMenuProps> = ({
  referenceElement,
  position,
  onChange,
  selectedIcon,
}) => {
  const { t } = useTranslation();

  return (
    <PopupMenu referenceElement={referenceElement} position={position}>
      <Styled.CategoryList>
        {iconCategoryList.map(({ category, icons }) => (
          <Styled.CategoryListItem key={category}>
            <Styled.CategoryTitle>
              {t(`general.labels.icons.categories.${category}`)}
            </Styled.CategoryTitle>

            <Styled.IconList>
              {icons.map((iconName) => {
                const onClick = () => onChange(iconName as IconFormNames);

                return (
                  <Styled.IconListItem key={iconName}>
                    <Styled.Button
                      onClick={onClick}
                      $selected={iconName === selectedIcon}
                    >
                      <Icon icon={IconFormIcons[iconName]} />
                    </Styled.Button>
                  </Styled.IconListItem>
                );
              })}
            </Styled.IconList>
          </Styled.CategoryListItem>
        ))}
      </Styled.CategoryList>
    </PopupMenu>
  );
};
