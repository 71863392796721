import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';
import { DraggableOptions } from 'shared/types/draggable-types';
import { ID } from 'shared/types/id';

import * as Styled from './sortable-container.style';

export type SortableContainerProps = {
  id: ID;
  type: DraggableOptions;
  children: React.ReactNode;
  hasBigBorderRadius?: boolean;
  isDisabled?: boolean;
  cantDrag?: boolean;
  cantInteractWithType?: string;
  hasHoverStyle?: boolean;
  isEditing?: boolean;
};

export const SortableContainer: React.FC<SortableContainerProps> = ({
  id,
  type,
  children,
  hasBigBorderRadius,
  isDisabled,
  cantDrag,
  cantInteractWithType,
  hasHoverStyle = true,
  isEditing,
}) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    isDragging,
    transform,
    transition,
    isOver,
    active,
  } = useSortable({
    id: id,
    disabled: {
      droppable: isDisabled || isEditing,
      draggable: isDisabled || cantDrag || isEditing,
    },
    data: { type, isDisabled },
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition: transition,
  };

  return (
    <Styled.Container
      ref={
        cantInteractWithType &&
        active?.data?.current?.type === cantInteractWithType
          ? undefined
          : setNodeRef
      }
      {...listeners}
      {...attributes}
      role="presentation"
      style={style}
      $isDragging={isDragging}
      $hasBigBorderRadius={!!hasBigBorderRadius}
      $isHovered={
        hasHoverStyle && active?.data.current?.type !== type && isOver
      }
    >
      {children}
    </Styled.Container>
  );
};
