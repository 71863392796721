import { Bar as VisxBar } from '@visx/shape';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const SVG = styled.svg``;

export const Bar = styled(VisxBar)`
  fill: ${({ theme }) => theme.palette.primary.main};
`;
