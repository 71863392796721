import { doc, serverTimestamp } from 'firebase/firestore';
import { IconFormNames } from 'shared/assets/icons';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { InheritColors } from 'shared/types/inherit-colors';
import { SortingMode } from 'shared/types/sorting-mode';
import { Timestamp } from 'shared/types/timestamp';
import { chunkArray } from 'shared/utils/chunk-array';

import { getTaskDocs } from './get-tasks';
import {
  BatchDocuments,
  CollectionOptions,
  getCollection,
  getDatabase,
} from './helpers';

export type Options = {
  goal: Goal;
  isSubGoal?: boolean;
};

export const transformGoalSectionToGoal = async (
  sectionId: ID,
  { goal, isSubGoal }: Options,
) => {
  const batchDocuments = new BatchDocuments(getDatabase());
  const goalCollection = getCollection(CollectionOptions.Goals);
  const taskCollection = getCollection(CollectionOptions.Tasks);

  const section = goal.sections?.find((section) => section.id === sectionId);
  if (!section) {
    return;
  }

  // get all section tasks (chunked as there could be more than 30 which is the limit of firestore
  const tasks = (
    await Promise.all(
      chunkArray(section.tasks, 30).map(
        async (taskIds) => await getTaskDocs({ ids: taskIds }),
      ),
    )
  ).flat(1);
  const completedTasks = tasks.filter(({ completedAt }) => !!completedAt);

  // create a new goal document
  const newGoalRef = doc(goalCollection);
  const newGoal: Goal = {
    id: newGoalRef.id,
    name: section.name,
    taskCount: tasks.length,
    completedTaskCount: completedTasks.length,
    color: goal.color ? InheritColors.Goal : null,
    lifeAreaId: goal.lifeAreaId ?? null,
    startDate: goal.startDate ?? null,
    deadline: goal.deadline ?? null,
    iconName: IconFormNames.Target,
    image: null,
    sections: null,
    goalSorting: null,
    metric: null,
    taskSorting: section.tasks,
    roles: goal.roles,
    parentIds: isSubGoal ? [...(goal.parentIds ?? []), goal.id] : null,
    sortingMode: SortingMode.Custom,
    taskSortingMode: SortingMode.Custom,
    metadata: null,
    excludedFromLimits: null,
    frozenAt: null,
    completedAt: null,
    archivedAt: null,
    createdAt: serverTimestamp() as unknown as Timestamp,
    updatedAt: serverTimestamp() as unknown as Timestamp,
  };
  batchDocuments.addDocument({ data: newGoal, ref: newGoalRef });

  // update all tasks with the new goalId
  tasks.forEach((task) => {
    batchDocuments.updateDocument({
      data: { goalId: newGoal.id, updatedAt: serverTimestamp() },
      ref: doc(taskCollection, task.id),
    });
  });

  // update current goal to remove the given section (as it is transformed to a goal now) and update the task-count
  batchDocuments.updateDocument({
    data: {
      taskCount: Math.max((goal.taskCount ?? 0) - tasks.length, 0),
      completedTaskCount: Math.max(
        (goal.completedTaskCount ?? 0) - completedTasks.length,
        0,
      ),
      sections:
        goal.sections?.filter((section) => section.id !== sectionId) ?? null,
      updatedAt: serverTimestamp(),
    },
    ref: doc(goalCollection, goal.id),
  });

  batchDocuments.commit();

  return newGoalRef.id;
};
