import { useTaskById, useTaskControls } from 'features/tasks';
import React, { useEffect, useRef } from 'react';
import { useBlockMobileScrollOutsideElement } from 'shared/hooks/use-block-mobile-scroll-outside-element';
import { ID } from 'shared/types/id';
import { TaskType } from 'shared/types/task-base';

import * as Styled from './task-detail-column.style';
import { TaskDetails } from './task-details';
import { TaskStatistics } from './task-statistics';

export type TaskDetailColumnProps = {
  taskId: ID;
  showStatistics: boolean;
  onClose: () => void;
};

export const TaskDetailColumn: React.FC<TaskDetailColumnProps> = ({
  taskId,
  showStatistics,
  onClose,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { onComplete, onUpdate } = useTaskControls();
  const task = useTaskById(taskId);

  const onChangeComplete = () => !!task && !task.frozenAt && onComplete(task);

  const onChangeTitle = (name: string) =>
    !!task && !task.frozenAt && onUpdate({ ...task, name });

  const onChangeNotes = (description: string | null) =>
    !!task && !task.frozenAt && onUpdate({ ...task, description });

  useEffect(() => {
    if (!task) {
      onClose();
    }
  }, [onClose, task]);
  useBlockMobileScrollOutsideElement(containerRef);

  return !!task ? (
    <Styled.Container ref={containerRef}>
      {showStatistics && task.type === TaskType.Habit ? (
        <TaskStatistics task={task} />
      ) : (
        <TaskDetails
          task={task}
          onComplete={onChangeComplete}
          onChangeTitle={onChangeTitle}
          onChangeNotes={onChangeNotes}
        />
      )}
    </Styled.Container>
  ) : null;
};
