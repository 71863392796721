import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React, { useMemo } from 'react';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';

import { GoalDataGridView } from './goal-data-grid-view';
import { useGoalOrdering } from './use-goal-ordering';

export type GoalDataGridProps = Omit<
  React.ComponentProps<typeof GoalDataGridView>,
  'rows' | 'onSortChange' | 'sortMode' | 'columnToSort'
> & {
  goals: Goal[];
  allGoals: Goal[];
  lifeAreas: LifeArea[];
  favorites: ID[];
  canReorder?: boolean;
};

export const GoalDataGrid: React.FC<GoalDataGridProps> = ({
  goals,
  allGoals,
  lifeAreas,
  columnTypes,
  favorites,
  canReorder,
  ...rest
}) => {
  const { rows, sortMode, onSortChange, sortColumn } = useGoalOrdering({
    goals,
    allGoals,
    lifeAreas,
    columnTypes,
    favorites,
  });

  const Wrapper = canReorder ? SortableContext : React.Fragment;
  const wrapperProps = useMemo(
    () =>
      canReorder
        ? {
            items: goals.map(({ id }) => id),
            strategy: verticalListSortingStrategy,
          }
        : ({} as { items: string[] }),
    [canReorder, goals],
  );

  return (
    <Wrapper {...wrapperProps}>
      <GoalDataGridView
        rows={rows}
        columnTypes={columnTypes}
        sortMode={sortMode}
        onSortChange={onSortChange}
        columnToSort={sortColumn}
        {...rest}
      />
    </Wrapper>
  );
};
