import React from 'react';
import {
  DndGoalListCard,
  GoalListCard,
} from 'shared/components/ui/goal-list-card';
import { Goal } from 'shared/types/goal';

import * as Styled from './goal-list.style';

type GoalListItemProps = React.ComponentProps<typeof GoalListCard> & {
  onPremium: () => void;
  hasDnd?: boolean;
};

const GoalListItem: React.FC<GoalListItemProps> = ({
  goal,
  onPremium,
  onClick,
  hasDnd,
  ...rest
}) => {
  const _onClick = goal.frozenAt ? onPremium : onClick;

  return hasDnd ? (
    <DndGoalListCard goal={goal} onClick={_onClick} {...rest} />
  ) : (
    <GoalListCard goal={goal} onClick={_onClick} {...rest} />
  );
};

export type GoalListViewProps = Omit<
  React.ComponentProps<typeof GoalListItem>,
  'goals' | 'goal'
> & {
  goals: Goal[];
  allGoals: Goal[];
};

export const GoalListView: React.FC<GoalListViewProps> = ({
  goals,
  allGoals,
  ...rest
}) => (
  <Styled.List>
    {goals.map((goal) => (
      <Styled.ListItem key={goal.id}>
        <GoalListItem goal={goal} goals={allGoals} {...rest} />
      </Styled.ListItem>
    ))}
  </Styled.List>
);
