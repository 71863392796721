import {
  addMonths,
  addWeeks,
  differenceInWeeks,
  endOfMonth,
  endOfWeek,
  isBefore,
  isSameDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { useMemo } from 'react';
import { RRule } from 'rrule';
import { weekdaysNumberMap } from 'shared/constants';
import { useToday } from 'shared/contexts/today';
import {
  differenceInMonths,
  isBetween,
  RoundingMethod,
} from 'shared/lib/date-fns';
import { Habit } from 'shared/types/habit';
import { FrequencyUnit } from 'shared/types/habit-schedule';
import { InsightsTimeframes } from 'shared/types/insights';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';

export type Options = {
  timeframeType: InsightsTimeframes;
  startDate: Timestamp;
  endDate: Timestamp;
  weekStartsOn?: WeekDays;
};

export const useHabitProgress = (
  habit: Habit,
  {
    timeframeType,
    startDate,
    endDate,
    weekStartsOn = WeekDays.Monday,
  }: Options,
) => {
  const today = useToday();
  const activeSchedule = useMemo(
    () => habit.schedules.find(({ endDate }) => !endDate),
    [habit.schedules],
  );

  const timeframeSchedules = useMemo(
    () =>
      habit.schedules.filter(
        (schedule) =>
          !schedule.endDate ||
          isBetween(schedule.startDate, startDate, endDate) ||
          (!!schedule.endDate &&
            isBetween(schedule.endDate, startDate, endDate)),
      ),
    [endDate, habit.schedules, startDate],
  );

  const completions = useMemo(
    () =>
      timeframeSchedules.reduce<Timestamp[]>((acc, schedule) => {
        schedule.completions.forEach((completion) => {
          if (isBetween(completion, startDate, endDate)) {
            acc.push(completion);
          }
        });

        return acc;
      }, []),
    [endDate, startDate, timeframeSchedules],
  );

  const skips = useMemo(
    () =>
      timeframeSchedules.reduce<Timestamp[]>((acc, schedule) => {
        schedule.skips?.forEach((skip) => {
          if (isBetween(skip, startDate, endDate)) {
            acc.push(skip);
          }
        });

        return acc;
      }, []),
    [endDate, startDate, timeframeSchedules],
  );

  const timeframeOccurrences = useMemo(
    () =>
      timeframeSchedules.reduce<Timestamp[]>((acc, schedule) => {
        if (schedule.frequency?.unit) {
          return acc;
        }

        const rrule = RRule.fromString(schedule.rrule.format);
        rrule.options.dtstart = startDate;
        rrule.options.until =
          schedule.endDate && isBefore(schedule.endDate, endDate)
            ? schedule.endDate
            : endDate;

        acc.push(...rrule.all());

        return acc;
      }, []),
    [endDate, startDate, timeframeSchedules],
  );

  const occurrencesTillToday = useMemo(
    () =>
      timeframeOccurrences.filter(
        (occurrence) =>
          isBefore(occurrence, today) || isSameDay(occurrence, today),
      ),
    [timeframeOccurrences, today],
  );

  const target = useMemo(() => {
    // check if timeframe starts at the start of the week when the schedule is week-based
    if (
      activeSchedule?.frequency?.unit === FrequencyUnit.Week &&
      isSameDay(
        startOfWeek(startDate, {
          weekStartsOn: weekdaysNumberMap[weekStartsOn],
        }),
        startDate,
      ) &&
      isSameDay(
        endOfWeek(endDate, {
          weekStartsOn: weekdaysNumberMap[weekStartsOn],
        }),
        endDate,
      )
    ) {
      return timeframeSchedules.reduce((acc, schedule) => {
        if (!schedule.frequency?.count) {
          return acc;
        }

        const dateToCountFrom = isBefore(schedule.startDate, startDate)
          ? startDate
          : schedule.startDate;
        const amountOfWeeksTillEndDate = differenceInWeeks(
          schedule.endDate ?? endDate,
          dateToCountFrom,
          { roundingMethod: 'ceil' },
        );

        return acc + schedule.frequency.count * amountOfWeeksTillEndDate;
      }, 0);
    }

    // check if timeframe starts at the start of the month when the schedule is month-based
    if (
      activeSchedule?.frequency?.unit === FrequencyUnit.Month &&
      isSameDay(startOfMonth(startDate), startDate) &&
      isSameDay(endOfMonth(endDate), endDate)
    ) {
      return timeframeSchedules.reduce((acc, schedule) => {
        if (!schedule.frequency?.count) {
          return acc;
        }

        const dateToCountFrom = isBefore(schedule.startDate, startDate)
          ? startDate
          : schedule.startDate;
        const amountOfMonthsTillEndDate = differenceInMonths(
          schedule.endDate ?? endDate,
          dateToCountFrom,
          { roundingMethod: RoundingMethod.Ceil },
        );

        return acc + schedule.frequency.count * amountOfMonthsTillEndDate;
      }, 0);
    }

    // other units means we cannot calculate (yet)
    if (activeSchedule?.frequency?.unit) {
      return undefined;
    }

    return timeframeOccurrences.length;
  }, [
    activeSchedule?.frequency?.unit,
    endDate,
    startDate,
    timeframeOccurrences.length,
    timeframeSchedules,
    weekStartsOn,
  ]);

  const entryTarget = useMemo(() => {
    if (timeframeType === InsightsTimeframes.Week) {
      return Math.max(1, activeSchedule?.targetFrequency?.count ?? 0);
    }

    if (timeframeType === InsightsTimeframes.Month) {
      return undefined;
    }

    if (activeSchedule?.frequency) {
      const timeframeTypeAmount = {
        [InsightsTimeframes.Quarter]:
          activeSchedule.frequency.unit === FrequencyUnit.Month
            ? activeSchedule.frequency.count / 4
            : activeSchedule.frequency.count,
        [InsightsTimeframes.FourWeeks]:
          activeSchedule.frequency.unit === FrequencyUnit.Month
            ? activeSchedule.frequency.count / 4
            : activeSchedule.frequency.count,
        [InsightsTimeframes.TwelveWeeks]:
          activeSchedule.frequency.unit === FrequencyUnit.Month
            ? activeSchedule.frequency.count / 4
            : activeSchedule.frequency.count,
        [InsightsTimeframes.Year]:
          activeSchedule.frequency.unit === FrequencyUnit.Month
            ? activeSchedule.frequency.count
            : activeSchedule.frequency.count * 4,
        [InsightsTimeframes.TwelveMonths]:
          activeSchedule.frequency.unit === FrequencyUnit.Month
            ? activeSchedule.frequency.count
            : activeSchedule.frequency.count * 4,
      };

      return Math.max(1, timeframeTypeAmount[timeframeType] ?? 0);
    }

    const timeframeTypeStartDate = {
      [InsightsTimeframes.Quarter]: addWeeks(endDate, -1),
      [InsightsTimeframes.FourWeeks]: addWeeks(endDate, -1),
      [InsightsTimeframes.TwelveWeeks]: addWeeks(endDate, -1),
      [InsightsTimeframes.Year]: addMonths(endDate, -1),
      [InsightsTimeframes.TwelveMonths]: addMonths(endDate, -1),
    };
    const timeframeTypeOccurrences = timeframeOccurrences.filter((date) =>
      isBetween(date, timeframeTypeStartDate[timeframeType], endDate),
    );

    return Math.max(1, timeframeTypeOccurrences.length);
  }, [
    activeSchedule?.frequency,
    activeSchedule?.targetFrequency?.count,
    endDate,
    timeframeOccurrences,
    timeframeType,
  ]);

  const completionPercentage = useMemo(
    () =>
      target ? Math.floor((completions.length / target) * 100) : undefined,
    [completions.length, target],
  );

  return {
    completions,
    skips,
    timeframeOccurrences,
    occurrencesTillToday,
    completionPercentage,
    entryTarget,
    target,
  };
};
