import React from 'react';
import { ChevronLeft } from 'shared/assets/icons';
import { NewHabitRow } from 'shared/components/connected/new-habit-row';
import { NewTaskRow } from 'shared/components/connected/new-task-row';
import { Collapse } from 'shared/components/ui/collapse';
import {
  IconButton,
  Sizes as IconButtonSizes,
} from 'shared/components/ui/icon-button';
import { ListSectionDateHeader } from 'shared/components/ui/list-section-date-header';
import { ListSectionMonthHeader } from 'shared/components/ui/list-section-month-header';
import { ListSectionYearHeader } from 'shared/components/ui/list-section-year-header';
import { useToggle } from 'shared/hooks/use-toggle';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import { TaskPriorityOptions } from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';
import { useTheme } from 'styled-components';

import { GoalHeader } from './goal-header';
import * as Styled from './task-section-layout.style';

export enum Headers {
  Date = 'date',
  Month = 'month',
  Year = 'year',
  Goal = 'goal',
}

export type TaskSectionLayoutProps = {
  customHeader?: React.ReactNode;
  date?: Timestamp;
  goalId?: ID;
  lifeAreaId?: ID;
  parentIds?: ID[] | null;
  parentChildIds?: ID[] | null;
  priority?: TaskPriorityOptions;
  headerToDisplay?: Headers;
  headerIsLink?: boolean;
  onHeader?: () => void;
  goals: Goal[];
  lifeAreas: LifeArea[];
  today: Timestamp;
  headerHasImage?: boolean;
  canCreateTask?: boolean;
  canCreateHabit?: boolean;
  canCollapse?: boolean;
  isFrozen?: boolean;
  hideMeta?: boolean;
  newTaskInputRef?: React.RefObject<HTMLTextAreaElement>;
  children: React.ReactNode;
};

export const TaskSectionLayout: React.FC<TaskSectionLayoutProps> = ({
  date,
  goalId,
  lifeAreaId,
  parentIds,
  parentChildIds,
  priority,
  customHeader,
  headerToDisplay,
  headerIsLink,
  onHeader,
  goals,
  lifeAreas,
  today,
  headerHasImage = headerToDisplay === Headers.Goal,
  canCreateTask,
  canCreateHabit,
  canCollapse,
  isFrozen,
  hideMeta,
  newTaskInputRef,
  children,
}) => {
  const theme = useTheme();
  const [collapseOpen, toggleCollapse] = useToggle(true);

  const CollapseContainer = canCollapse ? Collapse : React.Fragment;
  const collapseProps = canCollapse ? { isOpen: collapseOpen } : {};

  return (
    <>
      <Styled.HeaderContainer
        onClick={onHeader ?? (canCollapse ? toggleCollapse : undefined)}
        $canCollapse={!!canCollapse}
      >
        {!!canCollapse && (
          <Styled.IconButtonContainer>
            <IconButton
              icon={ChevronLeft}
              rotation={collapseOpen ? 270 : theme.isMobile ? 90 : 180}
              size={
                theme.isMobile
                  ? IconButtonSizes.Large
                  : IconButtonSizes.ExtraSmall
              }
              as="span"
            />
          </Styled.IconButtonContainer>
        )}
        {customHeader}
        {!customHeader && !!date && (
          <>
            {headerToDisplay === Headers.Date && (
              <ListSectionDateHeader date={date} today={today} />
            )}
            {headerToDisplay === Headers.Month && (
              <ListSectionMonthHeader date={date} />
            )}
            {headerToDisplay === Headers.Year && (
              <ListSectionYearHeader date={date} />
            )}
          </>
        )}
        {!customHeader && headerToDisplay === Headers.Goal && (
          <GoalHeader
            goalId={goalId}
            goals={goals}
            lifeAreas={lifeAreas}
            isLink={headerIsLink}
            isFrozen={isFrozen}
          />
        )}
      </Styled.HeaderContainer>
      <CollapseContainer {...collapseProps}>
        <Styled.ChildrenContainer $hasExtraSpacing={headerHasImage}>
          {children}
        </Styled.ChildrenContainer>
        {!!canCreateTask && (
          <Styled.NewTaskContainer $hasExtraSpacing={headerHasImage}>
            <NewTaskRow
              date={date}
              goal={goalId}
              lifeArea={lifeAreaId}
              parentIds={parentIds}
              parentChildIds={parentChildIds}
              priority={priority}
              newTaskInputRef={newTaskInputRef}
              hideMeta={hideMeta}
            />
          </Styled.NewTaskContainer>
        )}
        {!!canCreateHabit && (
          <Styled.NewTaskContainer $hasExtraSpacing={headerHasImage}>
            <NewHabitRow
              goal={goalId}
              lifeArea={lifeAreaId}
              priority={priority}
            />
          </Styled.NewTaskContainer>
        )}
      </CollapseContainer>
    </>
  );
};
