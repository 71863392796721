import { DndContext } from '@dnd-kit/core';
import React, { useMemo } from 'react';
import { DndGoalListCard } from 'shared/components/ui/goal-list-card';
import { OverlayComponentWrapper } from 'shared/components/ui/overlay-component-wrapper';
import { SortableOverlay } from 'shared/components/ui/sortable-overlay';
import { useSortableNestedSectionsControls } from 'shared/hooks/use-sortable-nested-sections-controls';
import { useSortableSensors } from 'shared/hooks/use-sortable-sensors';
import { Goal as GoalType } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import { TaskCardTask } from 'shared/types/task-card-task';

import { GoalSection } from './goal-section';
import { GoalSectionTaskList } from './goal-section-task-list';
import { Task } from './task';
import { useCustomCollisionDetection } from './use-custom-collision-detection';

export type SelfSortingGoalSectionTaskListProps = Omit<
  React.ComponentProps<typeof GoalSectionTaskList>,
  | 'hasDnd'
  | 'allGoals'
  | 'lifeAreas'
  | 'onPremium'
  | 'onUpdateSection'
  | 'hideGoal'
  | 'showRepeatingAsTodo'
> & {
  onReorder?: (changes: (GoalType | TaskCardTask)[]) => void;
  allGoals: GoalType[];
  lifeAreas: LifeArea[];
  onPremium: () => void;
  onUpdateSection: (sectionId: ID, sectionName: string) => void;
  hideGoal?: boolean;
  showRepeatingAsTodo?: boolean;
};

export const SelfSortingGoalSectionTaskList: React.FC<
  SelfSortingGoalSectionTaskListProps
> = ({
  selectedItem,
  items,
  onReorder,
  allGoals,
  lifeAreas,
  onPremium,
  onUpdateSection,
  hideGoal,
  showRepeatingAsTodo,
  ...rest
}) => {
  const sensors = useSortableSensors();
  const {
    items: changedItems,
    activeItem,
    activeId,
    handleDragStart,
    handleDragOver,
    handleDragCancel,
    handleDragEnd,
  } = useSortableNestedSectionsControls({
    items,
    onDragEnd: onReorder,
  });
  const customCollisionDetection = useCustomCollisionDetection(
    activeId,
    changedItems,
  );

  const activeGoal = useMemo(
    () =>
      activeItem && 'tasks' in activeItem
        ? changedItems.find(
            (item) =>
              !('fields' in item) &&
              item.sections?.findIndex(({ id }) => id === activeItem.id) !== -1,
          )
        : undefined,
    [activeItem, changedItems],
  );

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      sensors={sensors}
      collisionDetection={customCollisionDetection}
    >
      <GoalSectionTaskList
        {...rest}
        selectedItem={activeItem?.id ?? selectedItem}
        items={changedItems}
        allGoals={allGoals}
        lifeAreas={lifeAreas}
        onPremium={onPremium}
        onUpdateSection={onUpdateSection}
        hideGoal={hideGoal}
        showRepeatingAsTodo={showRepeatingAsTodo}
        hasDnd={!!onReorder}
      />

      <SortableOverlay>
        {!!activeItem && (
          <OverlayComponentWrapper>
            {'fields' in activeItem && (
              <Task
                task={activeItem}
                hideGoal={hideGoal}
                showRepeatingAsTodo={showRepeatingAsTodo}
                selectedItem={activeItem.id}
                isMoving
              />
            )}
            {activeGoal && 'tasks' in activeItem && (
              <GoalSection
                goalId={activeGoal.id}
                section={activeItem}
                items={changedItems}
                allGoals={allGoals}
                lifeAreas={lifeAreas}
                onPremium={onPremium}
                onUpdateSection={onUpdateSection}
                isMoving
              />
            )}
            {!('fields' in activeItem || 'tasks' in activeItem) && (
              <DndGoalListCard
                goal={activeItem}
                lifeAreas={lifeAreas}
                goals={allGoals}
              />
              // <Goal
              //   goal={activeItem}
              //   items={changedItems}
              //   allGoals={allGoals}
              //   lifeAreas={lifeAreas}
              //   onPremium={onPremium}
              //   onUpdateSection={onUpdateSection}
              //   isMoving
              // />
            )}
          </OverlayComponentWrapper>
        )}
      </SortableOverlay>
    </DndContext>
  );
};
