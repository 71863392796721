import { getDocs } from 'firebase/firestore';

import { getGoalsQuery, Options } from './get-goals-query';

export const getGoals = async (options?: Options) => {
  const query = await getGoalsQuery(options);
  const goals = await getDocs(query);

  return goals.docs.map((doc) => doc.data());
};

export const getGoalDocs = async (options?: Options) => {
  const query = await getGoalsQuery(options);
  const goals = await getDocs(query);
  return goals.docs.map((doc) => doc.data());
};
