import { GoalGroupBy, GoalSortingMode } from 'shared/types/sorting-mode';

import { GroupByMenuOptions, SortMenuOptions } from './menu-options';

export const groupByOptions: GroupByMenuOptions[] = [
  {
    id: GoalGroupBy.None,
    value: GoalGroupBy.None,
    label: `pages.goals.group-by.label.${GoalGroupBy.None}`,
  },
  {
    id: GoalGroupBy.LifeArea,
    value: GoalGroupBy.LifeArea,
    label: `pages.goals.group-by.label.${GoalGroupBy.LifeArea}`,
  },
];

export const sortOptions: SortMenuOptions[] = [
  {
    id: GoalSortingMode.Custom,
    value: GoalSortingMode.Custom,
    label: `pages.goals.sort.label.${GoalSortingMode.Custom}`,
  },
  {
    id: GoalSortingMode.StartDate,
    value: GoalSortingMode.StartDate,
    label: `pages.goals.sort.label.${GoalSortingMode.StartDate}`,
  },
  {
    id: GoalSortingMode.Deadline,
    value: GoalSortingMode.Deadline,
    label: `pages.goals.sort.label.${GoalSortingMode.Deadline}`,
  },
  {
    id: GoalSortingMode.Progress,
    value: GoalSortingMode.Progress,
    label: `pages.goals.sort.label.${GoalSortingMode.Progress}`,
  },
];
