import { formatISO } from 'date-fns';
import { httpsCallable } from 'firebase/functions';
import { Locales } from 'shared/assets/localization';
import { QueryParamOptions } from 'shared/lib/react-router-dom';
import { getLoggableError, getRollbar } from 'shared/services/error-tracking';
import { UtmParameters } from 'shared/types/utm-parameters';

import { getFunctions } from './helpers';

export type Options = {
  uid?: string | null;
  name?: string;
  locale?: Locales;
  hasNotifications?: boolean;
  queryParameters?: Partial<Record<UtmParameters | QueryParamOptions, string>>;
};

export const finishUserSignup = async ({
  uid,
  name,
  locale,
  hasNotifications,
  queryParameters,
}: Options) => {
  const functions = getFunctions();
  const finishSignup = httpsCallable<{
    name?: string;
    date: string;
    hasNotifications?: boolean;
    queryParameters: Partial<Record<UtmParameters | QueryParamOptions, string>>;
    locale?: Locales;
    source: string;
  }>(functions, 'users-finishSignup');

  try {
    return finishSignup({
      name,
      locale,
      hasNotifications,
      date: formatISO(new Date()),
      queryParameters: queryParameters ?? {},
      source: window.electron?.isMac
        ? 'mac_app'
        : window.electron?.isWindows
          ? 'win_app'
          : 'web_app',
    });
  } catch (error) {
    getRollbar().error('error trying to finish user signup', {
      uid,
      ...getLoggableError(error),
    });
  }
};
