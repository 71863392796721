import { useMutation } from '@tanstack/react-query';
import { transformGoalSectionToGoal } from 'shared/services/backend-api';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';

export type Options = {
  goal: Goal;
  sectionId: ID;
  isSubGoal?: boolean;
};

export const useTransformSectionToGoalMutation = () =>
  useMutation({
    mutationFn: ({ goal, sectionId, isSubGoal }: Options) =>
      transformGoalSectionToGoal(sectionId, { goal, isSubGoal }),
  });
