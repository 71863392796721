import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  PushNotificationMessageRequestState,
  setPushNotificationMessage,
} from 'shared/services/local-storage';
import { ID } from 'shared/types/id';

import { queryKey as pushNotificationMessageQueryKey } from './use-push-notification-message-query';

export type Options = { uid: ID; state: PushNotificationMessageRequestState };

export const useSetPushNotificationMessageMutation = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async ({ uid, state }: Options) => {
      setPushNotificationMessage(uid, state);
      await client.invalidateQueries({
        queryKey: [pushNotificationMessageQueryKey],
      });
    },
  });
};
