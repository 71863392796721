import { addDays, endOfDay } from 'date-fns';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Archive,
  Calendar,
  CalendarDeleteDate,
  CalendarTomorrow,
  CheckCircle,
  Edit3,
  Trash2,
} from 'shared/assets/icons';
import { HideOnTodoToggle } from 'shared/components/ui/hide-on-todo-toggle';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { TaskTypeToggle } from 'shared/components/ui/task-type-toggle';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { ID } from 'shared/types/id';
import { TaskType } from 'shared/types/task-base';
import { TaskCardTaskChanges } from 'shared/types/task-card-task';

export type ContextMenuProps = {
  id: ID;
  type?: TaskType;
  hideOnTodo?: boolean;
  onClose: () => void;
  position?: { top: number; left: number };
  onEditTask?: (id: ID) => void;
  onChangeHabitType?: (
    id: ID,
    type: TaskType.Habit | TaskType.Repeating,
  ) => void;
  onChangeHideOnTodo?: (id: ID) => void;
  isCompleted?: boolean;
  onCompleteTask?: (id: ID) => void;
  isArchived?: boolean;
  onArchiveTask?: (id: ID) => void;
  deleteTask?: (id: ID) => void;
  onUpdateDeadlineTask?: (partialTask: TaskCardTaskChanges) => void;
  completionCount?: number;
};

export const ContextMenu: React.FC<ContextMenuProps> = ({
  id,
  type,
  hideOnTodo,
  onClose,
  position,
  onEditTask,
  onChangeHabitType,
  onChangeHideOnTodo,
  isCompleted,
  onCompleteTask,
  isArchived,
  onArchiveTask,
  deleteTask,
  onUpdateDeadlineTask,
  completionCount,
}) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const onEdit = (e: React.MouseEvent) => {
    e.preventDefault();
    onEditTask?.(id);
    onClose();
  };
  const onRemoveDeadline = () => {
    onUpdateDeadlineTask?.({
      id,
      endStrategy: { deadline: undefined, completionCount },
    });
    onClose();
  };
  const onDeadlineToday = () => {
    onUpdateDeadlineTask?.({
      id,
      endStrategy: { deadline: endOfDay(new Date()), completionCount },
    });
    onClose();
  };
  const onDeadlineTomorrow = () => {
    onUpdateDeadlineTask?.({
      id,
      endStrategy: {
        deadline: endOfDay(addDays(new Date(), 1)),
        completionCount,
      },
    });
    onClose();
  };

  const onHabitType = (type: TaskType.Habit | TaskType.Repeating) => {
    onChangeHabitType?.(id, type);
    onClose();
  };

  const onChangeHide = () => {
    onChangeHideOnTodo?.(id);
    onClose();
  };

  const onComplete = () => onCompleteTask?.(id);

  const onDelete = () => deleteTask?.(id);

  const onArchive = () => onArchiveTask?.(id);

  const canHabitTypeChange =
    !!onChangeHabitType && [TaskType.Habit, TaskType.Repeating].includes(type!);

  useClickOutside(menuRef, onClose);

  return !!position ? (
    <PopupMenu ref={menuRef} location={position} position="bottom-start">
      {!!onEditTask && (
        <PopupMenuList
          hasBottomBorder={
            !!onUpdateDeadlineTask ||
            !!onCompleteTask ||
            !!onArchiveTask ||
            !!deleteTask
          }
        >
          <PopupMenuListItem>
            <PopupMenuButton onClick={onEdit} start={<Icon icon={Edit3} />}>
              {t('context-menus.task.labels.edit')}
            </PopupMenuButton>
          </PopupMenuListItem>
        </PopupMenuList>
      )}
      {!!onUpdateDeadlineTask && (
        <PopupMenuList
          hasBottomBorder={!!onCompleteTask || !!onArchiveTask || !!deleteTask}
        >
          <PopupMenuListItem>
            <PopupMenuButton
              onClick={onDeadlineToday}
              start={<Icon icon={Calendar} />}
            >
              {t('context-menus.task.labels.deadline-today')}
            </PopupMenuButton>
          </PopupMenuListItem>
          <PopupMenuListItem>
            <PopupMenuButton
              onClick={onDeadlineTomorrow}
              start={<Icon icon={CalendarTomorrow} />}
            >
              {t('context-menus.task.labels.deadline-tomorrow')}
            </PopupMenuButton>
          </PopupMenuListItem>
          <PopupMenuListItem>
            <PopupMenuButton
              onClick={onRemoveDeadline}
              start={<Icon icon={CalendarDeleteDate} />}
            >
              {t('context-menus.task.labels.remove-deadline')}
            </PopupMenuButton>
          </PopupMenuListItem>
        </PopupMenuList>
      )}
      {!!onCompleteTask && (
        <PopupMenuList hasBottomBorder={!!onArchiveTask || !!deleteTask}>
          <PopupMenuListItem>
            <PopupMenuButton
              onClick={onComplete}
              start={<Icon icon={CheckCircle} />}
            >
              {t(
                type === TaskType.Habit
                  ? isCompleted
                    ? 'context-menus.task.habit.labels.un-complete'
                    : 'context-menus.task.habit.labels.complete'
                  : isCompleted
                    ? 'context-menus.task.labels.un-complete'
                    : 'context-menus.task.labels.complete',
              )}
            </PopupMenuButton>
          </PopupMenuListItem>
        </PopupMenuList>
      )}
      {(!!onArchiveTask || !!deleteTask) && (
        <PopupMenuList hasBottomBorder={canHabitTypeChange}>
          {!!onArchiveTask && (
            <PopupMenuListItem>
              <PopupMenuButton
                onClick={onArchive}
                start={<Icon icon={Archive} />}
              >
                {t(
                  isArchived
                    ? 'context-menus.task.labels.de-archive'
                    : 'context-menus.task.labels.archive',
                )}
              </PopupMenuButton>
            </PopupMenuListItem>
          )}
          {!!deleteTask && (
            <PopupMenuListItem>
              <PopupMenuButton
                onClick={onDelete}
                start={<Icon icon={Trash2} />}
              >
                {t('context-menus.task.labels.delete')}
              </PopupMenuButton>
            </PopupMenuListItem>
          )}
        </PopupMenuList>
      )}
      {canHabitTypeChange && (
        <PopupMenuList>
          <PopupMenuListItem>
            <PopupMenuButton as="span">
              <TaskTypeToggle
                type={type as TaskType.Habit | TaskType.Repeating}
                onChange={onHabitType}
                fullWidth
              />
            </PopupMenuButton>
          </PopupMenuListItem>
          <PopupMenuListItem>
            <PopupMenuButton as="span">
              <HideOnTodoToggle
                value={!!hideOnTodo}
                onChange={onChangeHide}
                fullWidth
              />
            </PopupMenuButton>
          </PopupMenuListItem>
        </PopupMenuList>
      )}
    </PopupMenu>
  ) : null;
};
