import {
  AuthError,
  AuthErrorCodes,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { Locales } from 'shared/assets/localization';
import { getGlobalAllowedSearchParameters } from 'shared/lib/react-router-dom';
import { SignupErrorTypes } from 'shared/types/signup-form';

import { createPushToken } from './create-push-token';
import { finishUserSignup } from './finish-user-signup';
import { getPushToken } from './get-push-token';
import { getAuthentication } from './helpers';

export const signupUser = async (
  email: string,
  password: string,
  name?: string,
  locale?: Locales,
) => {
  const auth = await getAuthentication();

  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
    );
    const notificationsEnabled = Notification?.permission === 'granted';

    await finishUserSignup({
      uid: userCredential.user.uid,
      name,
      locale,
      hasNotifications: notificationsEnabled,
      queryParameters: Object.fromEntries(getGlobalAllowedSearchParameters()),
    });

    if (notificationsEnabled) {
      const token = await getPushToken(userCredential.user.uid);
      token && (await createPushToken(userCredential.user.uid, token, locale));
    }

    return userCredential.user.uid;
  } catch (e) {
    if ((e as AuthError).message.includes(AuthErrorCodes.EMAIL_EXISTS)) {
      throw SignupErrorTypes.EmailTaken;
    }

    switch ((e as AuthError).code) {
      case AuthErrorCodes.EMAIL_EXISTS:
        throw SignupErrorTypes.EmailTaken;
      default:
        throw SignupErrorTypes.Unknown;
    }
  }
};
