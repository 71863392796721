import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React, { useMemo } from 'react';

import { GoalListView } from './goal-list-view';

export type GoalListProps = Omit<
  React.ComponentProps<typeof GoalListView>,
  'hasDnd'
> & {
  canReorder?: boolean;
};

export const GoalList: React.FC<GoalListProps> = ({
  goals,
  canReorder,
  ...rest
}) => {
  const Wrapper = canReorder ? SortableContext : React.Fragment;
  const wrapperProps = useMemo(
    () =>
      canReorder
        ? {
            items: goals.map(({ id }) => id),
            strategy: verticalListSortingStrategy,
          }
        : ({} as { items: string[] }),
    [canReorder, goals],
  );

  return (
    <Wrapper {...wrapperProps}>
      <GoalListView goals={goals} hasDnd={canReorder} {...rest} />
    </Wrapper>
  );
};
