import React, { Suspense } from 'react';
import { Goal } from 'shared/types/goal';
import { DefaultMetricOptions } from 'shared/types/metric-template';

import { GoalInsightsMetricEntriesCard } from './goal-insights-metric-entries-card';
import { GoalInsightsSubgoalCard } from './goal-insights-subgoal-card';
import { GoalInsightsTaskCard } from './goal-insights-task-card';
import { LoadingInsightsCard } from './loading-insights-card';

export type GoalInsightsCardProps = {
  goal: Goal;
};

export const GoalInsightsCard: React.FC<GoalInsightsCardProps> = ({ goal }) => {
  if (!goal.metric || goal.metric.id === DefaultMetricOptions.CompletedTasks) {
    return (
      <Suspense fallback={<LoadingInsightsCard />}>
        <GoalInsightsTaskCard goal={goal} />
      </Suspense>
    );
  }

  if (goal.metric.id === DefaultMetricOptions.SubGoalProgress) {
    return (
      <Suspense fallback={<LoadingInsightsCard />}>
        <GoalInsightsSubgoalCard goal={goal} />
      </Suspense>
    );
  }

  return <GoalInsightsMetricEntriesCard goal={goal} />;
};
