import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Sliders } from 'shared/assets/icons';
import { IconButton } from 'shared/components/ui/icon-button';
import {
  PopupMenu,
  PopupMenuList,
  PopupMenuListItem,
  PopupMenuToggle,
} from 'shared/components/ui/popup-menu';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { useUser } from 'shared/hooks/use-user';

import { useToggleHiddenInsights } from '../../hooks/use-toggle-hidden-insights';
import { useToggleSubGoals } from '../../hooks/use-toggle-sub-goals';
import * as Styled from './settings-menu.style';

export const SettingsMenu: React.FC = () => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const user = useUser();
  const toggleSubGoals = useToggleSubGoals();
  const toggleHidden = useToggleHiddenInsights();
  const { menuOpen, openMenu, closeMenu } = useOpenMenu();

  useClickOutside(containerRef, closeMenu);

  return (
    <Styled.Container ref={containerRef}>
      <IconButton icon={Sliders} onClick={openMenu} />

      {menuOpen && (
        <PopupMenu referenceElement={containerRef} position="bottom-end">
          <PopupMenuList>
            <PopupMenuListItem>
              <PopupMenuToggle
                label={t(
                  'pages.insights.header-interactions.toggles.show-sub-goals.label',
                )}
                checked={!!user?.settings?.insights?.showActiveSubGoals}
                onChange={toggleSubGoals}
              />
            </PopupMenuListItem>
            <PopupMenuListItem>
              <PopupMenuToggle
                label={t(
                  'pages.insights.header-interactions.toggles.show-hidden.label',
                )}
                checked={!!user?.settings?.insights?.showHiddenItems}
                onChange={toggleHidden}
              />
            </PopupMenuListItem>
          </PopupMenuList>
        </PopupMenu>
      )}
    </Styled.Container>
  );
};
