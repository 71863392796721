import { index, Options } from 'shared/assets/styles';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: sticky;
  top: ${({ theme }) => theme.spacing(10)};
  z-index: ${index(Options.TOPBAR)};

  padding: ${({ theme }) =>
    `${theme.spacing(6)} ${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(6)}`};

  background: ${({ theme }) => theme.palette.background.default};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      top: 0;
      padding: ${({ theme }) =>
        `${theme.spacing(6)} ${theme.spacing(3)} ${theme.spacing(2)}`};

      background: ${({ theme }) => theme.palette.background.paper};
    `}
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;

  padding-top: ${({ theme }) => theme.spacing(2)};
`;

export const Side = styled.div<{ $right?: boolean }>`
  flex-grow: 1;
  flex-basis: 0;

  text-align: ${({ $right }) => ($right ? 'right' : 'left')};
`;

export const CenterDesktop = styled.div`
  ${({ theme }) =>
    theme.isMobile &&
    css`
      display: none;
    `};
`;

export const CenterMobile = styled.div`
  width: 100%;

  ${({ theme }) =>
    !theme.isMobile &&
    css`
      display: none;
    `}
`;
