import { useArchiveTask, useDeleteTask, useTaskById } from 'features/tasks';
import React, { forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Archive, Edit3, Trash2 } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { useOpenDeleteHabitDialog } from 'shared/contexts/delete-habit';
import { useOpenEditHabitDialog } from 'shared/contexts/habit-form';
import { useOpenTaskDetail } from 'shared/contexts/task-detail';
import { Habit } from 'shared/types/habit';
import { ID } from 'shared/types/id';
import { TaskType } from 'shared/types/task-base';

export type ContextMenuProps = {
  taskId?: ID;
  open?: boolean;
  onClose: () => void;
  referenceElement: React.RefObject<HTMLElement>;
  showEditButton?: boolean;
};

export const ContextMenu = forwardRef<HTMLDivElement, ContextMenuProps>(
  ({ taskId, open, onClose, referenceElement, showEditButton }, ref) => {
    const task = useTaskById(taskId);
    const { t } = useTranslation();
    const archiveTask = useArchiveTask();
    const openHabitDialog = useOpenEditHabitDialog();
    const openTaskDetail = useOpenTaskDetail();

    const parentTaskId = useMemo(
      () => task?.parentIds?.[task.parentIds.length - 1],
      [task],
    );

    const { submit: deleteTask } = useDeleteTask(parentTaskId);
    const deleteHabit = useOpenDeleteHabitDialog();

    const isHabit = [TaskType.Habit, TaskType.Repeating].includes(
      task?.type as TaskType,
    );

    const onEdit = () => {
      if (!task) {
        onClose();
        return;
      }

      if ([TaskType.Repeating, TaskType.Habit].includes(task.type)) {
        openHabitDialog(task as Habit, task.id);
        onClose();
        return;
      }

      openTaskDetail(task.id);

      onClose();
    };

    const onArchive = () => {
      task && archiveTask(task);
      onClose();
    };

    const onDelete = () => {
      if (isHabit) {
        deleteHabit(task as Habit);
        return;
      }

      task && deleteTask(task);
      onClose();
    };

    return task && open ? (
      <PopupMenu
        ref={ref}
        referenceElement={referenceElement}
        position="bottom-start"
      >
        {showEditButton && (
          <PopupMenuList hasBottomBorder>
            <PopupMenuListItem>
              <PopupMenuButton onClick={onEdit} start={<Icon icon={Edit3} />}>
                {t('context-menus.task.labels.edit')}
              </PopupMenuButton>
            </PopupMenuListItem>
          </PopupMenuList>
        )}
        <PopupMenuList>
          {isHabit && (
            <PopupMenuListItem>
              <PopupMenuButton
                onClick={onArchive}
                start={<Icon icon={Archive} />}
              >
                {t(
                  task.archivedAt
                    ? 'context-menus.task.labels.de-archive'
                    : 'context-menus.task.labels.archive',
                )}
              </PopupMenuButton>
            </PopupMenuListItem>
          )}
          <PopupMenuListItem>
            <PopupMenuButton onClick={onDelete} start={<Icon icon={Trash2} />}>
              {t('context-menus.task.labels.delete')}
            </PopupMenuButton>
          </PopupMenuListItem>
        </PopupMenuList>
      </PopupMenu>
    ) : null;
  },
);
