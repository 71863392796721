import styled from 'styled-components';

export const Container = styled.main`
  flex: 1;
  display: flex;

  max-width: 100%;
  min-width: 0;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: ${({ theme }) => theme.spacing(12)};
`;

export const Content = styled.div`
  flex: 1;
`;
