import { ListSectionDateHeader } from 'shared/components/ui/list-section-date-header';
import { ListSectionGoalHeader } from 'shared/components/ui/list-section-goal-header';
import { ListSectionMonthHeader } from 'shared/components/ui/list-section-month-header';
import { ListSectionQuarterlyHeader } from 'shared/components/ui/list-section-quarterly-header';
import { ListSectionYearHeader } from 'shared/components/ui/list-section-year-header';
import { ListSectionLifeAreaHeader } from 'shared/components/ui/section-header-life-area';

import { HeaderTypes } from './types';

export const headerMap = {
  [HeaderTypes.Date]: ListSectionDateHeader,
  [HeaderTypes.Month]: ListSectionMonthHeader,
  [HeaderTypes.Quarterly]: ListSectionQuarterlyHeader,
  [HeaderTypes.Year]: ListSectionYearHeader,
  [HeaderTypes.Goal]: ListSectionGoalHeader,
  [HeaderTypes.LifeArea]: ListSectionLifeAreaHeader,
};
