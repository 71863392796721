import styled, { css } from 'styled-components';

export const Content = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(3)} 0 0`};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: ${({ theme }) => `0 ${theme.spacing(3)}`};
    `}
`;
