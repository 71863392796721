import { deleteField, FieldValue } from 'firebase/firestore';
import { ID } from 'shared/types/id';
import { GoalSortingMode } from 'shared/types/sorting-mode';
import { Timestamp } from 'shared/types/timestamp';
import { formatQuarterlyDateKey } from 'shared/utils/format-date-key';

import { getUser, getUserRef } from './get-user';
import { updateDocWithTimestamp } from './helpers';

export const updateGoalRoadmapOrder = async (
  datesOrder: { quarterlyStartDate: Timestamp; ids: ID[] }[],
) => {
  const user = await getUser();
  // without a user we cannot update anything.
  if (!user) {
    return;
  }

  const userRef = await getUserRef();

  if (!userRef) {
    return;
  }

  const roadmapSorting = datesOrder.reduce<Record<string, ID[] | FieldValue>>(
    (acc, { quarterlyStartDate, ids }) => {
      // filter out the new ids of other entries, since tasks cannot be present in multiple days
      Object.keys(acc).forEach((key) => {
        acc[key] = Array.isArray(acc[key])
          ? (acc[key] as string[]).filter((oldIds) => !ids.includes(oldIds))
          : acc[key];

        // if there is nothing left in the array, set the key to be deleted
        if (Array.isArray(acc[key]) && !(acc[key] as string[]).length) {
          acc[key] = deleteField();
        }
      });

      // add the ids under the date that is given as parameter
      const dayKey = formatQuarterlyDateKey(quarterlyStartDate);

      if (!ids.length) {
        acc[dayKey] = deleteField();
        return acc;
      }

      acc[dayKey] = Array.from(new Set(ids));
      return acc;
    },
    structuredClone(user.settings?.goalRoadmapSorting ?? {}),
  );

  Object.keys(roadmapSorting).forEach((key) => {
    const prefixedKey = `settings.goalRoadmapSorting.${key}`;
    roadmapSorting[prefixedKey] = roadmapSorting[key];
    delete roadmapSorting[key];
  });

  updateDocWithTimestamp(userRef, {
    ...roadmapSorting,
    'settings.goalRoadmapSortingMode': GoalSortingMode.Custom,
  });
};
