import { QueryNavLink } from 'shared/lib/react-router-dom';
import styled, { css } from 'styled-components';

export const ClickContainer = styled.div`
  ${({ onClick }) =>
    !!onClick &&
    css`
      cursor: pointer;
    `};
`;

export const LinkContainer = styled(QueryNavLink)`
  text-decoration: none;
  color: inherit;
`;
