import { DndContext } from '@dnd-kit/core';
import {
  useCreateUnlimitedGoalsAllowed,
  useGoalControls,
  useGoals,
} from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import { useUserHadTrial } from 'features/user';
import React, { useMemo } from 'react';
import { GoalListCard } from 'shared/components/ui/goal-list-card';
import { GoalPremiumBanner } from 'shared/components/ui/goal-premium-banner';
import { GridContainer } from 'shared/components/ui/grid-container';
import { OverlayComponentWrapper } from 'shared/components/ui/overlay-component-wrapper';
import { SortableOverlay } from 'shared/components/ui/sortable-overlay';
import { useOpenCreateGoalDialog } from 'shared/contexts/goal-form';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useDroppableSectionControls } from 'shared/hooks/use-droppable-section-controls';
import { useSectionCollisionDetection } from 'shared/hooks/use-section-collision-detection';
import { useSortableSensors } from 'shared/hooks/use-sortable-sensors';
import { Goal } from 'shared/types/goal';

import { RoadmapSection } from './components/roadmap-section';
import { TabPanel } from './components/tab-panel';
import { useHandleGoalRoadmapReorder } from './hooks/use-goal-roadmap-reorder';
import { useRoadmapSections } from './hooks/use-roadmap-sections';
import * as Styled from './roadmap.style';

export const Roadmap: React.FC = () => {
  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();
  const userHadTrial = useUserHadTrial();
  const { onUpdateGoalProgress } = useGoalControls(goals);

  const { sections: initialSections, goals: sectionGoals } =
    useRoadmapSections();
  const onDragEnd = useHandleGoalRoadmapReorder(initialSections);

  const {
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    handleDragCancel,
    activeId,
    sections,
  } = useDroppableSectionControls({ initialSections, onDragEnd });

  const sensors = useSortableSensors();
  const collisionStrategy = useSectionCollisionDetection(activeId, sections);
  const openPremiumDialog = useOpenPremiumDialog();
  const openCreateGoalDialog = useOpenCreateGoalDialog();

  const {
    allowed: createGoalAllowed,
    enforced: createGoalEnforced,
    limit: createGoalLimit,
    total: createGoalTotal,
  } = useCreateUnlimitedGoalsAllowed();

  const showPremiumBanner =
    !createGoalAllowed ||
    (!createGoalEnforced && createGoalLimit < createGoalTotal);

  const onCreateGoal = (initialValues: Partial<Goal>) =>
    showPremiumBanner
      ? openPremiumDialog()
      : openCreateGoalDialog(initialValues);

  const activeItem = useMemo(
    () =>
      activeId ? sectionGoals.find((goal) => goal.id === activeId) : undefined,
    [activeId, sectionGoals],
  );

  return (
    <GridContainer>
      <TabPanel>
        <Styled.Container>
          <DndContext
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
            sensors={sensors}
            collisionDetection={collisionStrategy}
          >
            {sections.map(({ id, startDate, items }) => (
              <Styled.Section key={id}>
                <RoadmapSection
                  droppableId={id}
                  startDate={startDate}
                  amount={items.length}
                  goals={items}
                  allGoals={goals}
                  lifeAreas={lifeAreas}
                  onPremium={openPremiumDialog}
                  onCreateGoal={onCreateGoal}
                  onUpdateProgress={onUpdateGoalProgress}
                  canReorder
                />
              </Styled.Section>
            ))}

            <SortableOverlay>
              {!!activeItem && (
                <OverlayComponentWrapper>
                  <GoalListCard
                    goal={activeItem}
                    lifeAreas={lifeAreas}
                    goals={goals}
                  />
                </OverlayComponentWrapper>
              )}
            </SortableOverlay>
          </DndContext>

          {showPremiumBanner && (
            <GoalPremiumBanner
              onClick={openPremiumDialog}
              createGoalEnforced={createGoalEnforced}
              createGoalTotal={createGoalTotal}
              createGoalLimit={createGoalLimit}
              hadTrial={userHadTrial}
            />
          )}
        </Styled.Container>
      </TabPanel>
    </GridContainer>
  );
};
