import React from 'react';
import { GridContainer } from 'shared/components/ui/grid-container';
import { useUser } from 'shared/hooks/use-user';
import { GoalGroupBy } from 'shared/types/sorting-mode';

import { LifeAreaGroupingGoalList } from '../life-area-grouping-goal-list';
import { NoGroupingGoalList } from '../no-grouping-goal-list';
import { TabPanel } from '../tab-panel';
import * as Styled from './group-by-list.style';

const groupByLists: Record<GoalGroupBy, React.FC> = {
  [GoalGroupBy.None]: NoGroupingGoalList,
  [GoalGroupBy.LifeArea]: LifeAreaGroupingGoalList,
};

export const GroupByList: React.FC = () => {
  const user = useUser();
  const GoalListComponent =
    groupByLists[user?.settings?.goalGroupBy ?? GoalGroupBy.None] ??
    groupByLists[GoalGroupBy.None];

  return (
    <GridContainer>
      <TabPanel role="tab-panel">
        <Styled.GroupingContainer>
          <GoalListComponent />
        </Styled.GroupingContainer>
      </TabPanel>
    </GridContainer>
  );
};
