import { ListSectionDateHeaderProps } from 'shared/components/ui/list-section-date-header';
import { ListSectionGoalHeaderProps } from 'shared/components/ui/list-section-goal-header';
import { ListSectionMonthHeaderProps } from 'shared/components/ui/list-section-month-header';
import { ListSectionQuarterlyHeaderProps } from 'shared/components/ui/list-section-quarterly-header';
import { ListSectionYearHeaderProps } from 'shared/components/ui/list-section-year-header';
import { ListSectionLifeAreaHeaderProps } from 'shared/components/ui/section-header-life-area';

export enum HeaderTypes {
  Date = 'date',
  Month = 'month',
  Quarterly = 'quarterly',
  Year = 'year',
  Goal = 'goal',
  LifeArea = 'life-area',
}

export type HeaderTypeProps = {
  [HeaderTypes.Date]: ListSectionDateHeaderProps;
  [HeaderTypes.Month]: ListSectionMonthHeaderProps;
  [HeaderTypes.Quarterly]: ListSectionQuarterlyHeaderProps;
  [HeaderTypes.Year]: ListSectionYearHeaderProps;
  [HeaderTypes.Goal]: ListSectionGoalHeaderProps;
  [HeaderTypes.LifeArea]: ListSectionLifeAreaHeaderProps;
};
