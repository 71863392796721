import { isSameDay } from 'date-fns';
import React, { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SubTasksList } from 'shared/components/connected/sub-tasks-list';
import { TaskMetaInteractions } from 'shared/components/connected/task-meta-interactions';
import { Checkbox, Colors } from 'shared/components/ui/checkbox';
import { TaskDetailColumnDescription } from 'shared/components/ui/task-detail-column-description';
import { TaskDetailColumnResizableTitle } from 'shared/components/ui/task-detail-column-resizable-title';
import { subTaskLevel } from 'shared/constants';
import { useToday } from 'shared/contexts/today';
import { Task } from 'shared/types/task';
import { TaskPriorityOptions } from 'shared/types/task-base';

import * as Styled from './task-details.style';

const priorityColorMap: Record<TaskPriorityOptions, Colors> = {
  [TaskPriorityOptions.Low]: Colors.Info,
  [TaskPriorityOptions.Medium]: Colors.Warning,
  [TaskPriorityOptions.High]: Colors.Error,
  [TaskPriorityOptions.None]: Colors.Default,
};

export type TaskDetailsProps = {
  task: Task;
  onComplete: () => void;
  onChangeTitle: (value: string) => void;
  onChangeNotes: (value: string | null) => void;
};

export const TaskDetails: React.FC<TaskDetailsProps> = ({
  task,
  onComplete,
  onChangeTitle,
  onChangeNotes,
}) => {
  const { t } = useTranslation();
  const today = useToday();

  const completed = useMemo(() => {
    const habitCompletions = task?.schedules?.find(
      ({ endDate }) => !endDate,
    )?.completions;

    const completedToday = habitCompletions?.find((completion) =>
      isSameDay(completion, today),
    );

    return !!completedToday || !!task?.completedAt;
  }, [task?.completedAt, task?.schedules, today]);

  const preventPropagation = (e: React.MouseEvent) => e.stopPropagation();

  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.TitleBar>
          <Styled.CheckboxContainer
            onClick={preventPropagation}
            $isFrozen={!!task.frozenAt}
          >
            <Checkbox
              color={
                task.priority ? priorityColorMap[task.priority] : undefined
              }
              checked={completed}
              onChange={onComplete}
            />
          </Styled.CheckboxContainer>

          <Styled.TitleContainer>
            <TaskDetailColumnResizableTitle
              onChange={onChangeTitle}
              value={task.name}
              completed={completed}
              isFrozen={!!task.frozenAt}
            />
          </Styled.TitleContainer>
        </Styled.TitleBar>

        {!task.parentIds?.length && (
          <Styled.MetaContainer>
            <TaskMetaInteractions
              task={task}
              isFaded={!!task.frozenAt}
              highlight
              hasTooltips
              isMobileOutlined
            />
          </Styled.MetaContainer>
        )}
      </Styled.Header>

      <Styled.Body>
        <Styled.DescriptionContainer>
          <TaskDetailColumnDescription
            value={task.description ?? null}
            onChange={onChangeNotes}
            isFrozen={!!task.frozenAt}
            placeholder={t('general.labels.task-detail.notes.placeholder')}
          />
        </Styled.DescriptionContainer>

        {(task.parentIds?.length ?? 0) <= subTaskLevel && (
          <Styled.SubTasks>
            <Suspense>
              <SubTasksList task={task} />
            </Suspense>
          </Styled.SubTasks>
        )}
      </Styled.Body>
    </Styled.Container>
  );
};
