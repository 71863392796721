import { Axis, Orientation } from '@visx/axis';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import {
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import React, { useMemo, useState } from 'react';
import { Tooltip } from 'shared/components/ui/tooltip';
import { weekdaysNumberMap } from 'shared/constants';
import { Entry } from 'shared/lib/recharts';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';

import { Bar } from './bar';
import * as Styled from './bar-chart.style';
import { BarRepresentOptions } from './types';
import { useBarchartStyling } from './use-barchart-styling';
import { useXScale } from './use-x-scale';
import { useYAxisWidth } from './use-y-axis-width';
import { useYScale } from './use-y-scale';

const xAxisHeight = 28;
const chartTopPadding = 12;

type ChartProps = {
  width: number;
  height: number;

  data: Entry[];
  target?: number;
  yTicks: number[];
  yTickFormatter?: (tick: number) => string;
  xTicks: number[];
  xTickFormatter: (tick: number) => string;
  barRepresents?: BarRepresentOptions;
  weekStartsOn: WeekDays;
};

const Chart: React.FC<ChartProps> = ({
  width,
  height,
  data,
  target,
  yTicks,
  yTickFormatter,
  xTicks,
  xTickFormatter,
  barRepresents,
  weekStartsOn,
}) => {
  const chartHeight = height - xAxisHeight - chartTopPadding;

  const yAxisWidth = useYAxisWidth({
    ticks: yTicks,
    formatter: yTickFormatter,
    target,
  });

  const xScale = useXScale({ data, width, yAxisOffset: yAxisWidth });
  const yScale = useYScale({ data, height: chartHeight, target });

  const {
    yAxisTickLabelProps,
    xAxisTickLabelProps,
    xAxisStrokeColor,
    cartesianColor,
  } = useBarchartStyling();

  const [tooltipDate, setTooltipDate] = useState<Timestamp>();
  const [tooltipLocation, setTooltipLocation] = useState<{
    left: number;
    top: number;
  }>();

  const showTooltip = (
    date: Timestamp,
    options?: { left: number; top: number },
  ) => {
    setTooltipDate(options ? date : undefined);
    setTooltipLocation(options);
  };

  const hoveredBar = useMemo(
    () =>
      tooltipDate
        ? data.find(({ date }) => date.getTime() === tooltipDate.getTime())
        : undefined,
    [data, tooltipDate],
  );

  const hoveredBarDateRange = useMemo(() => {
    if (!hoveredBar) {
      return;
    }

    switch (barRepresents) {
      case BarRepresentOptions.Day:
        return format(hoveredBar.date, 'MMM dd');
      case BarRepresentOptions.Week:
        // eslint-disable-next-line max-len -- date formatting takes space
        return `${format(startOfWeek(hoveredBar.date, { weekStartsOn: weekdaysNumberMap[weekStartsOn] }), 'MMM dd')} - ${format(endOfWeek(hoveredBar.date, { weekStartsOn: weekdaysNumberMap[weekStartsOn] }), 'MMM dd')}`;
      case BarRepresentOptions.Month:
        return `${format(startOfMonth(hoveredBar.date), 'MMM dd')} - ${format(endOfMonth(hoveredBar.date), 'MMM dd')}`;
    }
  }, [barRepresents, hoveredBar, weekStartsOn]);

  return (
    <Styled.Container>
      {hoveredBar && (
        <Tooltip
          label={`${hoveredBar.value} (${hoveredBarDateRange})`}
          location={tooltipLocation}
        />
      )}
      <Styled.SVG width="100%" height="100%">
        <defs>
          <clipPath id="bar-clip">
            <rect
              x="0"
              y="0"
              width={width * 1000}
              height={Math.max(0, chartHeight)}
            />
          </clipPath>
        </defs>

        {/* cartesian grid */}
        <Group top={chartTopPadding}>
          <line
            x1={yAxisWidth}
            y1={0}
            x2={width}
            y2={0}
            strokeWidth={1}
            strokeDasharray="2 2"
            stroke={cartesianColor}
          />
          <line
            x1={yAxisWidth}
            y1={chartHeight / 2}
            x2={width}
            y2={chartHeight / 2}
            strokeWidth={1}
            strokeDasharray="2 2"
            stroke={cartesianColor}
          />
        </Group>

        <Group
          top={chartTopPadding}
          left={yAxisWidth}
          clipPath="url(#bar-clip)"
        >
          {data.map(({ date, value }) => (
            <Bar
              key={date.getTime()}
              x={xScale(date.getTime())}
              maxHeight={chartHeight}
              date={date}
              onHover={showTooltip}
              y={yScale(value)}
            />
          ))}
        </Group>

        {!!yTickFormatter && (
          <>
            {/* y-axis */}
            <Axis
              scale={yScale}
              orientation={Orientation.left}
              top={chartTopPadding}
              left={yAxisWidth} // Position the axis at the calculated width
              tickLabelProps={yAxisTickLabelProps}
              tickLength={4}
              tickValues={yTicks}
              tickFormat={(tick) => yTickFormatter(tick.valueOf())}
              tickStroke="none"
              stroke="none"
            />
          </>
        )}

        {/* x-axis line */}
        <line
          x1={yAxisWidth}
          y1={chartHeight + chartTopPadding}
          x2={width}
          y2={chartHeight + chartTopPadding}
          stroke={xAxisStrokeColor}
          strokeWidth={1}
        />

        <Axis
          scale={xScale}
          left={yAxisWidth}
          tickLabelProps={xAxisTickLabelProps}
          orientation={Orientation.bottom}
          top={chartHeight + chartTopPadding}
          tickValues={xTicks}
          tickFormat={(tick) => xTickFormatter(tick.valueOf())}
          stroke="none"
          tickStroke="none"
        />
      </Styled.SVG>
    </Styled.Container>
  );
};

export type BarChartProps = Omit<ChartProps, 'width' | 'height'> & {};

export const BarChart: React.FC<BarChartProps> = (chartProps) => (
  <ParentSize>
    {({ width, height }) => (
      <Chart {...chartProps} width={width} height={height} />
    )}
  </ParentSize>
);
