import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Plus } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';

import * as Styled from './data-grid-create-row.style';

export type DataGridCreateRowProps = {
  label: string;
  onClick: (e: React.MouseEvent) => void;
  showPremiumLabel?: boolean;
};

export const DataGridCreateRow: React.FC<DataGridCreateRowProps> = ({
  label,
  onClick,
  showPremiumLabel,
}) => {
  const { t } = useTranslation();

  return (
    <Styled.Container onClick={onClick} type="button">
      <Styled.IconContainer>
        <Icon icon={Plus} />
      </Styled.IconContainer>

      <Typography variant="subtitle2" component="span">
        {label}
      </Typography>

      {!!showPremiumLabel && (
        <Styled.PremiumLabel>{t('general.labels.premium')}</Styled.PremiumLabel>
      )}
    </Styled.Container>
  );
};
