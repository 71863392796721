import { Goal } from 'shared/types/goal';
import { GoalSortingMode } from 'shared/types/sorting-mode';
import { getCompletionPercentage } from 'shared/utils/get-completion-percentage';
import { getGoalCompletion } from 'shared/utils/get-goal-completion';

export type Options = {
  goals: Goal[];
};

export const columnTypeOrderAscendingMap = ({
  goals,
}: Options): Omit<
  Record<GoalSortingMode, ((goalA: Goal, goalB: Goal) => number) | undefined>,
  GoalSortingMode.Custom
> => ({
  [GoalSortingMode.StartDate]: (goalA, goalB) =>
    !goalB.startDate
      ? -1
      : !goalA.startDate
        ? 1
        : goalB.startDate.getTime() - goalA.startDate.getTime(),
  [GoalSortingMode.Deadline]: (goalA, goalB) =>
    !goalB.deadline
      ? -1
      : !goalA.deadline
        ? 1
        : goalB.deadline.getTime() - goalA.deadline.getTime(),
  [GoalSortingMode.Progress]: (goalA, goalB) => {
    const goalACompletion = getGoalCompletion(goalA, { goals });
    const goalBCompletion = getGoalCompletion(goalB, { goals });

    if (goalBCompletion.end === Infinity) {
      return -1;
    }

    if (goalACompletion.end === Infinity) {
      return 1;
    }

    const completionPercentageA = getCompletionPercentage(goalACompletion);
    const completionPercentageB = getCompletionPercentage(goalBCompletion);

    return completionPercentageB - completionPercentageA;
  },
});

export const columnTypeOrderDescendingMap = ({
  goals,
}: Options): Omit<
  Record<GoalSortingMode, ((goalA: Goal, goalB: Goal) => number) | undefined>,
  GoalSortingMode.Custom
> => ({
  [GoalSortingMode.StartDate]: (goalA, goalB) =>
    !goalB.startDate
      ? -1
      : !goalA.startDate
        ? 1
        : goalA.startDate.getTime() - goalB.startDate.getTime(),
  [GoalSortingMode.Deadline]: (goalA, goalB) =>
    !goalB.deadline
      ? -1
      : !goalA.deadline
        ? 1
        : goalA.deadline.getTime() - goalB.deadline.getTime(),
  [GoalSortingMode.Progress]: (goalA, goalB) => {
    const goalACompletion = getGoalCompletion(goalA, { goals });
    const goalBCompletion = getGoalCompletion(goalB, { goals });

    if (goalBCompletion.end === Infinity) {
      return -1;
    }

    if (goalACompletion.end === Infinity) {
      return 1;
    }

    const completionPercentageA = getCompletionPercentage(goalACompletion);
    const completionPercentageB = getCompletionPercentage(goalBCompletion);

    return completionPercentageA - completionPercentageB;
  },
});
