import { useMutation } from '@tanstack/react-query';
import { updateGoalRoadmapOrder } from 'shared/services/backend-api';
import { ID } from 'shared/types/id';
import { Timestamp } from 'shared/types/timestamp';

export type QuarterlyOrder = {
  quarterlyStartDate: Timestamp;
  ids: ID[];
};

export const useUpdateGoalRoadmapOrderMutation = () =>
  useMutation({
    mutationFn: async (datesOrder: QuarterlyOrder[]) =>
      updateGoalRoadmapOrder(datesOrder),
  });
