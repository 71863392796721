import { useQuery } from '@tanstack/react-query';
import { createSubscriptionQuery } from 'shared/lib/@tanstack-query';
import { getLifescans } from 'shared/services/backend-api';
import { ID } from 'shared/types/id';

export const queryKey = 'lifescans';

export const useLifescansQuery = (uid?: ID, enabled = true) =>
  useQuery({
    queryKey: [queryKey, uid ?? ''],
    queryFn: createSubscriptionQuery([() => getLifescans()]),
    enabled: !!uid && enabled,
  });
