import { useMutation } from '@tanstack/react-query';
import {
  transferSectionToGoal,
  TransferSectionToGoalOptions,
} from 'shared/services/backend-api';

export const useTransferSectionToGoalMutation = () =>
  useMutation({
    mutationFn: async (options: TransferSectionToGoalOptions) =>
      transferSectionToGoal(options),
  });
