import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(theme.isMobile ? 3 : 2)};

  padding: ${({ theme }) => `${theme.spacing(2.5)} 0 ${theme.spacing(2.5)}`};
  margin: 0;
  width: 100%;

  cursor: pointer;
  border: none;
  background: ${({ theme }) =>
    theme.isMobile
      ? theme.palette.background.paper
      : theme.palette.background.default};

  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const IconContainer = styled.span`
  display: block;
  padding: ${({ theme }) => theme.spacing(0.5)};

  border-radius: ${({ theme }) => theme.spacing(2)};
  border: 2px dotted ${({ theme }) => theme.palette.divider};

  font-size: 1.6rem;
  line-height: 62.5%;
  color: ${({ theme }) => theme.palette.grey['400']};
`;
