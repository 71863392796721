import { getUser, getUserRef } from './get-user';
import { updateDocWithTimestamp } from './helpers';

export const updateUserInsightsShowActiveSubGoals = async (
  showActiveSubGoals: boolean,
) => {
  const user = await getUser();

  if (!user) {
    return;
  }

  const userRef = await getUserRef();

  if (!userRef) {
    return;
  }

  updateDocWithTimestamp(userRef, {
    'settings.insights.showActiveSubGoals': showActiveSubGoals,
  });
};
