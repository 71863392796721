import { useDroppable } from '@dnd-kit/core';
import React from 'react';
import { CreateGoalCard } from 'shared/components/ui/create-goal-card';
import { GoalListSection } from 'shared/components/ui/goal-list-section';
import { ListSectionLayout } from 'shared/components/ui/list-section-header';
import { HeaderTypes } from 'shared/components/ui/list-section-header/types';
import { useToday } from 'shared/contexts/today';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { Timestamp } from 'shared/types/timestamp';
import { formatQuarterlyDateKey } from 'shared/utils/format-date-key';

import * as Styled from './roadmap-section.style';

export type RoadmapSectionProps = React.ComponentProps<
  typeof GoalListSection
> & {
  droppableId: ID;
  startDate: Timestamp;
  amount?: number;
  onCreateGoal?: (initialValues: Partial<Goal>) => void;
};

export const RoadmapSection: React.FC<RoadmapSectionProps> = ({
  droppableId,
  startDate,
  amount,
  onCreateGoal,

  lifeAreas,
  ...rest
}) => {
  const today = useToday();
  const { setNodeRef } = useDroppable({
    id: droppableId,
    disabled: !droppableId,
  });
  const _createGoal = () =>
    onCreateGoal?.({
      startDate:
        startDate.getTime() === new Date(0).getTime() ? null : startDate,
    });

  const containerProps = droppableId ? { ref: setNodeRef } : {};

  const headerProps = { date: startDate, today, amount };

  return (
    <Styled.Container {...containerProps}>
      <ListSectionLayout
        headerType={HeaderTypes.Quarterly}
        headerProps={headerProps}
        canCollapse
        initialOpen={droppableId !== formatQuarterlyDateKey(new Date(0))}
        smallTopSpacing
      >
        <Styled.ListContainer>
          <GoalListSection lifeAreas={lifeAreas} {...rest} />
        </Styled.ListContainer>
        {!!onCreateGoal && <CreateGoalCard onCreateGoal={_createGoal} />}
      </ListSectionLayout>
    </Styled.Container>
  );
};
