export enum TickRange {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export enum BarRepresentOptions {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}
