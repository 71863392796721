import { isAfter, isSameDay, isSameMonth } from 'date-fns';
import React, { useCallback, useMemo } from 'react';
import { ArrowRight, X } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { useToday } from 'shared/contexts/today';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';

import * as Styled from './habit-progress-calendar.style';
import { Header } from './header';

const weekDayFormat = (day: string) => day.slice(0, 1);

type DayProps = {
  date: Timestamp;
  completions: Timestamp[];
  occurrences: Timestamp[];
  skips: Timestamp[];
  failures: Timestamp[];
  showDate?: boolean;
  onClick?: (date: Timestamp) => void;
};

const Day: React.FC<DayProps> = ({
  date,
  occurrences,
  skips,
  failures,
  completions,
  showDate,
  onClick,
}) => {
  const today = useToday();
  const isCompleted = useMemo(
    () => completions.find((completion) => isSameDay(completion, date)),
    [completions, date],
  );

  const isSkipped = useMemo(
    () => skips.find((skip) => isSameDay(skip, date)),
    [date, skips],
  );

  const isFailure = useMemo(
    () => failures.find((failure) => isSameDay(failure, date)),
    [date, failures],
  );

  const _onClick = onClick ? () => onClick(date) : undefined;

  const isOccurrence = useMemo(
    () => occurrences.find((occurrence) => isSameDay(occurrence, date)),
    [date, occurrences],
  );

  return (
    <Styled.Day
      $isToday={isSameDay(date, today)}
      $isOccurrence={!!isOccurrence}
      $isCompleted={!!isCompleted}
      $isSkipped={!!isSkipped}
      $isFailure={!!isFailure}
      $isFuture={isAfter(date, today)}
      $showDate={!!showDate}
      onClick={_onClick}
    >
      {!isCompleted && (isSkipped || isFailure) ? (
        <Styled.DayIcon>
          <Icon icon={isSkipped ? ArrowRight : X} />
        </Styled.DayIcon>
      ) : (
        showDate && date.getDate()
      )}
    </Styled.Day>
  );
};

export type HabitProgressCalendarProps = {
  openToDate?: Timestamp;
  weekStartsOn?: WeekDays;
  completions: Timestamp[];
  completionPercentage?: number;
  skips: Timestamp[];
  failures?: Timestamp[];
  occurrences: Timestamp[];
  onChange?: (date: Timestamp) => void;
  showDates?: boolean;
  showHeader?: boolean;
  isCard?: boolean;
};

export const HabitProgressCalendar: React.FC<HabitProgressCalendarProps> = ({
  openToDate = new Date(),
  weekStartsOn,
  completions,
  completionPercentage,
  skips,
  failures = [],
  occurrences,
  onChange,
  showDates,
  showHeader,
  isCard,
}) => {
  const today = useToday();
  const _onChange = (date: Timestamp, e: React.ChangeEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  // Function to filter out dates not in the current month
  const isCurrentMonth = useCallback(
    (date: Timestamp) => isSameMonth(date, today),
    [today],
  );

  return (
    <Styled.Container
      $clickableDays={!!onChange}
      $showDates={!!showDates}
      $isCard={!!isCard}
    >
      <Styled.DatePicker
        weekStartsOn={weekStartsOn}
        onChange={_onChange}
        renderCustomHeader={({ date }) =>
          showHeader && (
            <Header
              date={date}
              completions={completions}
              completionsPercentage={completionPercentage}
              skips={skips}
              failures={failures}
            />
          )
        }
        openToDate={openToDate}
        renderDayContents={(_, date) =>
          date ? (
            <Day
              occurrences={occurrences}
              completions={completions}
              skips={skips}
              failures={failures}
              date={date}
              showDate={showDates}
              onClick={onChange}
            />
          ) : null
        }
        filterDate={isCurrentMonth}
        formatWeekDay={weekDayFormat}
        shouldCloseOnSelect={false}
        inline
      />
    </Styled.Container>
  );
};
