import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const Container = styled.h2`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;

  margin: 0;
  padding: 0;

  height: 3.2rem;
`;

export const Badge = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.body2)};

  color: ${({ theme }) => theme.palette.text.secondary};
`;
