import { chunkArray } from 'shared/utils/chunk-array';

import { getTaskDocs, Options } from './get-tasks';

export const getTasksWithSubTasks = async (options: Options) => {
  const mainTasks = await getTaskDocs(options);

  const allSubTasks = (
    await Promise.all(
      mainTasks.map(async (mainTask) =>
        mainTask.childIds?.length
          ? (
              await Promise.all(
                chunkArray(mainTask.childIds, 30).map(
                  async (chunkedSubTaskIds) =>
                    await getTaskDocs({ ids: chunkedSubTaskIds }),
                ),
              )
            ).flat(1)
          : [],
      ),
    )
  ).flat(1);

  return [...mainTasks, ...allSubTasks].sort(
    (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
  );
};
