export enum Paths {
  Home = '/',
  Inbox = '/inbox',
  Upcoming = '/upcoming',
  Goals = '/goals',
  GoalsRoadmap = '/goals/roadmap',
  GoalsCompleted = '/goals/completed',
  GoalsArchived = '/goals/archived',
  GoalsThisYear = '/goals/this-year',
  GoalsNextYear = '/goals/next-year',
  GoalsUnplanned = '/goals/unplanned',
  GoalDetail = '/goals/:id',
  GoalTemplates = '/goal-plans',
  LifeAreas = '/life-areas',
  LifeAreaDetailGoals = '/life-areas/:id/goals',
  LifeAreaDetail = '/life-areas/:id',
  LifeAreaDetailTasks = '/life-areas/:id/tasks',
  Habits = '/habits',
  HabitsCompleted = '/habits/completed',
  // Journal = '/journal',
  Login = '/login',
  Signup = '/signup',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  CompleteSignup = '/complete-signup',
  VerifyEmail = '/verify-email',
  Insights = '/insights',
}
