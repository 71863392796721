import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import {
  Check,
  CheckCircle,
  DotsMenu,
  IconFormIcons,
  IconFormNames,
  SlimArrowUp,
} from 'shared/assets/icons';
import { HabitProgressCalendar } from 'shared/components/ui/habit-progress-calendar';
import { Icon } from 'shared/components/ui/icon';
import { IconButton } from 'shared/components/ui/icon-button';
import { TimeBarChart } from 'shared/components/ui/time-bar-chart';
import { Entry } from 'shared/lib/recharts';
import { Goal } from 'shared/types/goal';
import { Habit } from 'shared/types/habit';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { getGoalColor } from 'shared/utils/get-goal-color';
import { isNumber } from 'shared/utils/is-number';

import * as Styled from './habit-insights-card.style';

export type HabitInsightsCardProps = {
  habit: Habit;
  entries?: Entry[];
  entryTarget?: number;
  calendarOccurrences: Timestamp[];
  completions: Timestamp[];
  skips: Timestamp[];
  completionPercentage?: number;
  currentValue: number;
  target?: number;
  timeframeDifference?: number;
  goals: Goal[];
  lifeAreas: LifeArea[];
  startDate: Timestamp;
  endDate: Timestamp;
  weekStartsOn: WeekDays;
  menuButtonRef?: React.RefObject<HTMLButtonElement>;
  onMenuButton?: (e: React.MouseEvent) => void;
  onClick?: (id: ID) => void;
  onDate?: (id: ID, date: Timestamp) => void;
  isPreview?: boolean;
};

export const HabitInsightsCard: React.FC<HabitInsightsCardProps> = ({
  habit,
  entries,
  entryTarget,
  calendarOccurrences,
  completions,
  skips,
  completionPercentage,
  currentValue,
  target,
  timeframeDifference,
  goals,
  lifeAreas,
  startDate,
  endDate,
  weekStartsOn,
  menuButtonRef,
  onMenuButton,
  onClick,
  onDate,
  isPreview,
}) => {
  const color = useMemo(
    () => getGoalColor(habit.id, { goals, lifeAreas }),
    [habit.id, goals, lifeAreas],
  );

  const onHabit = () => onClick?.(habit.id);

  const _onDate = (date: Timestamp) => onDate?.(habit.id, date);

  const _onMenuButton = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onMenuButton?.(e);
  };

  return (
    <Styled.Container $isPreview={!!isPreview} onClick={onHabit}>
      <Styled.Header>
        <Styled.GoalInformationContainer>
          <Styled.IllustrationContainer>
            <Styled.ImageContainer>
              <Icon
                icon={
                  IconFormIcons[habit.iconName!] ??
                  IconFormIcons[IconFormNames.Repeat]
                }
              />
            </Styled.ImageContainer>

            {!!habit.completedAt && (
              <Styled.CompletedContainer>
                <Icon icon={Check} />
              </Styled.CompletedContainer>
            )}

            {!!color && <Styled.ColorDot $color={color} />}
          </Styled.IllustrationContainer>

          <Styled.Title $isCompleted={!!habit.completedAt}>
            {habit.name}
          </Styled.Title>
        </Styled.GoalInformationContainer>

        <IconButton
          icon={DotsMenu}
          onClick={_onMenuButton}
          ref={menuButtonRef}
        />
      </Styled.Header>

      <Styled.Body>
        <Styled.CurrentProgress>
          <Typography variant="h3" component="span">
            {currentValue}
          </Typography>
          {!!target && ` / ${target}`}{' '}
        </Styled.CurrentProgress>

        <Styled.ProgressContainer>
          {isNumber(completionPercentage) && (
            <Styled.Progress $isPositive={!!completionPercentage}>
              <Icon icon={CheckCircle} />

              <Typography variant="caption">
                {isNumber(completionPercentage)
                  ? `${completionPercentage}%`
                  : '-'}
              </Typography>
            </Styled.Progress>
          )}

          {!!timeframeDifference && (
            <Styled.Difference $difference={timeframeDifference}>
              <Styled.DifferenceIconContainer
                $positive={timeframeDifference > 0}
              >
                <Icon icon={SlimArrowUp} />
              </Styled.DifferenceIconContainer>
              {timeframeDifference.toFixed(1)}%
            </Styled.Difference>
          )}
        </Styled.ProgressContainer>
      </Styled.Body>

      <Styled.Footer>
        {entries ? (
          <TimeBarChart
            data={entries}
            weekStartsOn={weekStartsOn}
            target={entryTarget}
            startDate={startDate}
            endDate={endDate}
            showYAxis
          />
        ) : (
          <HabitProgressCalendar
            completions={completions}
            skips={skips}
            occurrences={calendarOccurrences}
            onChange={_onDate}
            weekStartsOn={weekStartsOn}
          />
        )}
      </Styled.Footer>
    </Styled.Container>
  );
};
