import React, { useMemo } from 'react';
import { DotProps as RechartsDotProps } from 'recharts';
import { formatNumberShort } from 'shared/utils/format-number-short';
import { isNumber } from 'shared/utils/is-number';

import * as Styled from './time-progress-chart.style';

export type DotProps = RechartsDotProps & {
  index?: number;
  data: any[];
  payload?: {
    value: number;
  };
  yAxisWidth: number;
  showCount?: boolean;
};

export const Dot: React.FC<DotProps> = ({
  cx,
  cy,
  index,
  data,
  payload,
  width,
  yAxisWidth,
  showCount,
}) => {
  const {
    x: textX,
    y: textY,
    anchor: textAnchor,
  } = useMemo(() => {
    if (isNumber(cx) && isNumber(cy)) {
      const posX = cx as number;
      const posY = cy as number;

      if (posY < 24) {
        return {
          x: posX < 100 ? posX + 10 : posX - 10,
          y: posY + 5,
          anchor: posX < 100 ? 'start' : 'end',
        };
      }

      if (posX < yAxisWidth + 50) {
        return {
          x: posX + 10,
          y: posY + 5,
          anchor: 'start',
        };
      }

      if (width) {
        const maxWidth = parseInt(width.toString()) - 50;

        if (posX > maxWidth) {
          return {
            x: posX - 10,
            y: posY + 5,
            anchor: 'end',
          };
        }
      }
    }

    return { x: cx, y: cy ? cy - 12 : cy, anchor: 'middle' };
  }, [cx, cy, width, yAxisWidth]);

  return index === data.length - 1 || (!isNumber(cx) && !isNumber(cy)) ? (
    <g>
      <Styled.SvgCircle cx={cx} cy={cy} />
      {!!showCount && (
        <Styled.SvgDotText x={textX} y={textY} textAnchor={textAnchor}>
          {formatNumberShort(payload!.value!)}
        </Styled.SvgDotText>
      )}
    </g>
  ) : null;
};
