import { ID } from 'shared/types/id';
import { GoalSortingMode } from 'shared/types/sorting-mode';
import {
  GoalRoadmapSortingKeys,
  GoalRoadmapSortingOptions,
} from 'shared/types/user-settings';

import { getUser, getUserRef } from './get-user';
import { updateDocWithTimestamp } from './helpers';

const roadmapSettingsBase = 'settings.goalRoadmapSorting';

const goalOrderKeysSettingsMap: Record<GoalRoadmapSortingKeys, string> = {
  [GoalRoadmapSortingKeys.InProgress]: `${roadmapSettingsBase}.${GoalRoadmapSortingKeys.InProgress}`,
  [GoalRoadmapSortingKeys.Unplanned]: `${roadmapSettingsBase}.${GoalRoadmapSortingKeys.Unplanned}`,
  [GoalRoadmapSortingKeys.Completed]: `${roadmapSettingsBase}.${GoalRoadmapSortingKeys.Completed}`,
  [GoalRoadmapSortingKeys.Archived]: `${roadmapSettingsBase}.${GoalRoadmapSortingKeys.Archived}`,
};

export const updateGoalOrder = async (
  goalOrder: ID[],
  goalOrderOption: GoalRoadmapSortingOptions,
) => {
  const user = await getUser();

  // without a user we cannot update anything.
  if (!user) {
    return;
  }

  const userRef = await getUserRef();

  if (!userRef) {
    return null;
  }

  const settingsToUpdate =
    goalOrderKeysSettingsMap[goalOrderOption as GoalRoadmapSortingKeys] ??
    `${roadmapSettingsBase}.${goalOrderOption}`;

  updateDocWithTimestamp(userRef, {
    [settingsToUpdate]: Array.from(new Set(goalOrder)),
    // add goalSortingMode for iOS to make it work with custom as well
    'settings.goalSortingMode': GoalSortingMode.Custom,
  });
};
