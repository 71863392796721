import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import React from 'react';
import { GoalContextMenu } from 'shared/components/connected/goal-context-menu';
import { GoalInsightsCard as GoalInsightsCardUi } from 'shared/components/ui/goal-insights-card';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { useUser } from 'shared/hooks/use-user';
import { initialWeekStartsOn } from 'shared/types/week-days';

export type GoalInsightsCardProps = Omit<
  React.ComponentProps<typeof GoalInsightsCardUi>,
  'onMenuButton' | 'menuButtonRef' | 'goals' | 'lifeAreas' | 'weekStartsOn'
> & {};

export const GoalInsightsCard: React.FC<GoalInsightsCardProps> = ({
  goal,
  ...rest
}) => {
  const menuButtonRef = React.useRef<HTMLButtonElement>(null);
  const user = useUser();
  const lifeAreas = useLocalizedLifeAreas();
  const goals = useGoals();

  const { menuOpen, openMenu, closeMenu } = useOpenMenu();

  const weekStartsOn = user?.settings?.startOfWeek ?? initialWeekStartsOn;

  return (
    <>
      <GoalInsightsCardUi
        goal={goal}
        goals={goals}
        lifeAreas={lifeAreas}
        onMenuButton={openMenu}
        menuButtonRef={menuButtonRef}
        weekStartsOn={weekStartsOn}
        {...rest}
      />
      {menuOpen && (
        <GoalContextMenu
          id={goal.id}
          onClose={closeMenu}
          referenceElement={menuButtonRef}
        />
      )}
    </>
  );
};
