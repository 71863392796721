import { useUserInsights } from 'features/user';
import { useTimeByInsightsTimeframe } from 'shared/hooks/use-time-by-insights-timeframe';
import { useUser } from 'shared/hooks/use-user';
import { fallbackTimeframe } from 'shared/types/insights';
import { WeekDays } from 'shared/types/week-days';

export type Options = {
  quarterRoundedToWeeks?: boolean;
  weekEndsWithStartOfDay?: boolean;
};

export const useTimeframeTime = ({
  quarterRoundedToWeeks,
  weekEndsWithStartOfDay,
}: Options = {}) => {
  const user = useUser();
  const insights = useUserInsights();
  const timeframe = insights?.timeframe ?? fallbackTimeframe;
  const weekStartsOn = user?.settings?.startOfWeek ?? WeekDays.Monday;

  const { startDate, endDate } = useTimeByInsightsTimeframe(timeframe, {
    weekStartsOn,
    quarterRoundedToWeeks,
    weekEndsWithStartOfDay,
  });

  return { startDate, endDate, timeframe };
};
