import MuiTab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import { format } from 'date-fns';
import { useGoals } from 'features/goals';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useToday } from 'shared/contexts/today';
import { useActivePath } from 'shared/hooks/use-active-path';
import { QueryNavLink } from 'shared/lib/react-router-dom';
import { Paths } from 'shared/routes';

import { GoalsFilterOptions, goalsFilters } from '../../utils/goals-filter';
import * as Styled from './overview-navigation-tabs.style';

type NavigationPaths =
  | Paths.Goals
  | Paths.GoalsCompleted
  | Paths.GoalsArchived
  | Paths.GoalsRoadmap;

type NavigationNumbers = Record<NavigationPaths, number>;

const optionalPaths = [Paths.GoalsCompleted, Paths.GoalsArchived];

const navigationTabs: { label: string; path: NavigationPaths }[] = [
  { label: 'pages.goals.tabs.active.label', path: Paths.Goals },
  { label: 'pages.goals.tabs.roadmap.label', path: Paths.GoalsRoadmap },
  { label: 'pages.goals.tabs.completed.label', path: Paths.GoalsCompleted },
  { label: 'pages.goals.tabs.archived.label', path: Paths.GoalsArchived },
];

export const OverviewNavigationTabs: React.FC = () => {
  const { t } = useTranslation();
  const activePath = useActivePath();
  const today = useToday();
  const navigate = useNavigate();

  const goals = useGoals();
  const navigationNumbers = useMemo<NavigationNumbers>(
    () => ({
      [Paths.Goals]: goalsFilters[GoalsFilterOptions.Active](
        goals,
        today,
      ).filter(({ parentIds }) => !parentIds?.length).length,
      [Paths.GoalsRoadmap]: goalsFilters[GoalsFilterOptions.Roadmap](
        goals,
        today,
      ).filter(({ parentIds }) => !parentIds?.length).length,
      [Paths.GoalsCompleted]: goalsFilters[GoalsFilterOptions.Completed](
        goals,
        today,
      ).length,
      [Paths.GoalsArchived]: goalsFilters[GoalsFilterOptions.Archived](
        goals,
        today,
      ).filter(({ parentIds }) => !parentIds?.length).length,
    }),
    [goals, today],
  );

  const filteredTabs = useMemo(
    () =>
      navigationTabs.filter(
        ({ path }) => !optionalPaths.includes(path) || navigationNumbers[path],
      ),
    [navigationNumbers],
  );

  const activeIndex = useMemo(
    () => filteredTabs.findIndex(({ path }) => path === activePath),
    [activePath, filteredTabs],
  );

  useEffect(() => {
    if (
      activePath &&
      optionalPaths.includes(activePath) &&
      !navigationNumbers[activePath as NavigationPaths]
    ) {
      navigate(Paths.Goals, { replace: true });
    }
  }, [activeIndex, activePath, filteredTabs, navigate, navigationNumbers]);

  return (
    <MuiTabs value={activeIndex} variant="scrollable" scrollButtons={false}>
      {filteredTabs.map(({ label, path }) => (
        <MuiTab
          key={path}
          component={QueryNavLink}
          to={path}
          label={t(label, {
            year: format(today, 'yyyy'),
          })}
          icon={
            navigationNumbers[path] ? (
              <Styled.Badge $active={activePath === path}>
                {navigationNumbers[path]}
              </Styled.Badge>
            ) : undefined
          }
          iconPosition="end"
          replace
        />
      ))}
    </MuiTabs>
  );
};
