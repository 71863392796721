import { useMemo } from 'react';
import { Entry } from 'shared/lib/recharts';

export type Options = {
  startValue?: number;
  endValue?: number;
};

export const useYAxisData = (
  data: Entry[],
  { startValue = 0, endValue }: Options,
) => {
  const domain = useMemo(() => {
    const entryValues = data
      .map(({ value }) => value)
      .filter(Boolean) as number[];
    const endVal = endValue ?? startValue + 1;
    const minValue = Math.min(...entryValues, startValue, endVal);
    const maxValue = Math.max(...entryValues, startValue, endVal);
    return [minValue, maxValue];
  }, [data, endValue, startValue]);

  const ticks = useMemo(
    () => [domain[0], domain[0] + (domain[1] - domain[0]) / 2, domain[1]],
    [domain],
  );

  return {
    domain,
    ticks,
  };
};
