import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const ChartContainer = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(theme.isMobile ? 3 : 6)}`};
`;

export const BarChartContainer = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacing(3)} ${theme.spacing(4)} ${theme.spacing(1)}`};

  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: ${({ theme }) => theme.spacing(3)};
  box-shadow: ${({ theme }) => theme.shadowBox.card};
  background: ${({ theme }) => theme.palette.backgroundExtended.card};
`;

export const BarChartHeader = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.subtitle2)};
  display: block;
  padding-bottom: ${({ theme }) => theme.spacing(1.5)};

  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CalendarContainer = styled.div`
  padding: ${({ theme }) =>
    `0 ${theme.spacing(theme.isMobile ? 3 : 6)} ${theme.spacing(2)}`};
`;
