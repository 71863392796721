import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import { useLifeAreaTodayReorder } from 'features/user';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconFormIcons, Inbox } from 'shared/assets/icons';
import { ListSectionLifeAreaHeader } from 'shared/components/ui/section-header-life-area';
import { SelfSortingTaskSection } from 'shared/components/ui/task-section';
import { useActiveTaskId } from 'shared/contexts/task-detail';
import { useToday } from 'shared/contexts/today';
import { ID } from 'shared/types/id';
import { DueDisplayOptions } from 'shared/types/task-base';

import { useLifeAreaTaskSections } from '../../hooks/use-life-area-task-sections';
import * as Styled from './life-area-grouping-tasks-list.style';

const displayDue = [DueDisplayOptions.Past, DueDisplayOptions.Future];

export const LifeAreaGroupingTasksList: React.FC = () => {
  const { t } = useTranslation();
  const today = useToday();
  const { sections } = useLifeAreaTaskSections();
  const reorderLifeArea = useLifeAreaTodayReorder();

  const selectedTask = useActiveTaskId();

  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();

  return (
    <Styled.Container>
      {sections.map((section) => {
        const onReorder = (ids: ID[]) => reorderLifeArea(section.id, ids);

        const lifeArea = 'lifeArea' in section ? section.lifeArea : undefined;
        const name =
          lifeArea?.name ?? t('general.sections.life-areas.other.title');

        return (
          <Styled.Section key={section.id}>
            <SelfSortingTaskSection
              goals={goals}
              lifeAreas={lifeAreas}
              today={today}
              lifeAreaId={lifeArea?.id}
              tasks={section.items}
              selectedTask={selectedTask}
              date={today}
              displayDue={displayDue}
              customHeader={
                <ListSectionLifeAreaHeader
                  id={section.id}
                  icon={lifeArea ? IconFormIcons[lifeArea.iconName] : Inbox}
                  name={name}
                  color={lifeArea?.color ?? undefined}
                  isFrozen={!!lifeArea?.frozenAt}
                  image={lifeArea?.visionPreview?.image}
                />
              }
              onReorder={onReorder}
              hideLifeArea
              headerHasImage
              canCreateTask
              canCollapse
            />
          </Styled.Section>
        );
      })}
    </Styled.Container>
  );
};
