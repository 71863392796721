import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';

import * as Styled from './insights-premium-banner.style';

export enum PremiumBannerTypes {
  Habit = 'habit',
  LifeArea = 'life-area',
}

export type InsightsPremiumBannerProps = {
  type?: PremiumBannerTypes;
};

export const InsightsPremiumBanner: React.FC<InsightsPremiumBannerProps> = ({
  type = PremiumBannerTypes.Habit,
}) => {
  const { t } = useTranslation();
  const openPremiumDialog = useOpenPremiumDialog();

  return (
    <Styled.Container>
      <Typography variant="h5">
        {t(
          type === PremiumBannerTypes.Habit
            ? 'pages.insights.sections.habits.premium.title'
            : 'pages.insights.sections.life-areas.premium.title',
        )}
      </Typography>

      <Typography variant="body2">
        {t(
          type === PremiumBannerTypes.Habit
            ? 'pages.insights.sections.habits.premium.description'
            : 'pages.insights.sections.life-areas.premium.description',
        )}
      </Typography>

      <Styled.ButtonContainer>
        <Button
          variant={Variants.Contained}
          color={Colors.Primary}
          onClick={openPremiumDialog}
          size={Sizes.Medium}
        >
          {t(
            type === PremiumBannerTypes.Habit
              ? 'pages.insights.sections.habits.premium.interactions.open-premium.label'
              : 'pages.insights.sections.life-areas.premium.interactions.open-premium.label',
          )}
        </Button>
      </Styled.ButtonContainer>
    </Styled.Container>
  );
};
