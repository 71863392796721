import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SliderButtons } from 'shared/components/ui/slider-buttons';
import { InsightsTimeframes } from 'shared/types/insights';

export type InsightsTimeframeSelectionProps = {
  isLight?: boolean;
  isFullWidth?: boolean;
  value: InsightsTimeframes;
  onChange: (timeframe: InsightsTimeframes) => void;
};

export const InsightsTimeframeSelection: React.FC<
  InsightsTimeframeSelectionProps
> = ({ value, onChange, isLight, isFullWidth }) => {
  const { t } = useTranslation();

  const periods = useMemo(
    () => [
      [
        {
          label: t(
            `pages.insights.period-selection.${InsightsTimeframes.Week}`,
          ),
          value: InsightsTimeframes.Week,
          tooltip: t(
            `pages.insights.period-selection.${InsightsTimeframes.Week}.tooltip`,
          ),
        },
        {
          label: t(
            `pages.insights.period-selection.${InsightsTimeframes.Month}`,
          ),
          value: InsightsTimeframes.Month,
          tooltip: t(
            `pages.insights.period-selection.${InsightsTimeframes.Month}.tooltip`,
          ),
        },
        {
          label: t(
            `pages.insights.period-selection.${InsightsTimeframes.Quarter}`,
          ),
          value: InsightsTimeframes.Quarter,
          tooltip: t(
            `pages.insights.period-selection.${InsightsTimeframes.Quarter}.tooltip`,
          ),
        },
        {
          label: t(
            `pages.insights.period-selection.${InsightsTimeframes.Year}`,
          ),
          value: InsightsTimeframes.Year,
          tooltip: t(
            `pages.insights.period-selection.${InsightsTimeframes.Year}.tooltip`,
          ),
        },
      ],
      [
        {
          label: t(
            `pages.insights.period-selection.${InsightsTimeframes.FourWeeks}`,
          ),
          value: InsightsTimeframes.FourWeeks,
          tooltip: t(
            `pages.insights.period-selection.${InsightsTimeframes.FourWeeks}.tooltip`,
          ),
        },
        {
          label: t(
            `pages.insights.period-selection.${InsightsTimeframes.TwelveWeeks}`,
          ),
          value: InsightsTimeframes.TwelveWeeks,
          tooltip: t(
            `pages.insights.period-selection.${InsightsTimeframes.TwelveWeeks}.tooltip`,
          ),
        },
        {
          label: t(
            `pages.insights.period-selection.${InsightsTimeframes.TwelveMonths}`,
          ),
          value: InsightsTimeframes.TwelveMonths,
          tooltip: t(
            `pages.insights.period-selection.${InsightsTimeframes.TwelveMonths}.tooltip`,
          ),
        },
      ],
    ],
    [t],
  );

  return (
    <SliderButtons
      optionGroups={periods}
      value={value}
      onOption={onChange}
      isLight={isLight}
      isFullWidth={isFullWidth}
    />
  );
};
