import { Paths } from 'shared/routes';

import { Active } from './active';
import { Archived } from './archived';
import { Completed } from './completed';
import { Roadmap } from './roadmap';

export const GoalsOverviewRoutes = [
  {
    path: Paths.Goals,
    element: <Active />,
  },
  {
    path: Paths.GoalsRoadmap,
    element: <Roadmap />,
  },
  {
    path: Paths.GoalsCompleted,
    element: <Completed />,
  },
  {
    path: Paths.GoalsArchived,
    element: <Archived />,
  },
];
