import { useQuery } from '@tanstack/react-query';
import { getPushNotificationMessage } from 'shared/services/local-storage';
import { ID } from 'shared/types/id';

export const queryKey = 'push-notification-message';

export const usePushNotificationMessageQuery = (uid?: ID) =>
  useQuery({
    queryKey: [queryKey, uid],
    queryFn: () => getPushNotificationMessage(uid),
  });
