import { startOfDay } from 'date-fns';
import { useSubGoals } from 'features/goals';
import { useTasks } from 'features/tasks';
import { useMemo } from 'react';
import { Goal } from 'shared/types/goal';
import { MetricEntry } from 'shared/types/goal-metric';
import { Timestamp } from 'shared/types/timestamp';
import { formatDateKey } from 'shared/utils/format-date-key';

import { useTimeframeDifference } from './use-timeframe-difference';

export type Options = {
  startDate: Timestamp;
  endDate: Timestamp;
};

export const useGoalTaskProgress = (
  goal: Goal,
  { startDate, endDate }: Options,
) => {
  const subGoals = useSubGoals(goal.id);
  const allTasks = useTasks(
    { goals: [goal.id, ...subGoals.map(({ id }) => id)] },
    !!goal.id,
  );
  const target = allTasks.length;

  const completedTasks = useMemo(
    () =>
      allTasks
        .filter(({ completedAt }) => !!completedAt)
        .sort(
          (taskA, taskB) =>
            taskA.completedAt!.getTime() - taskB.completedAt!.getTime(),
        ),
    [allTasks],
  );

  const entries = useMemo<MetricEntry[]>(() => {
    // get all data entries based on the timeframe
    const dateTasksMap = completedTasks
      .filter(({ completedAt }) => completedAt)
      .reduce<Record<string, MetricEntry>>(
        (acc, { completedAt }, currentIndex) => {
          const dateKey = formatDateKey(completedAt!);
          acc[dateKey] = acc[dateKey] ?? {
            id: dateKey,
            date: startOfDay(completedAt!),
            value: 0,
          };
          acc[dateKey].value = currentIndex + 1;

          return acc;
        },
        {},
      );

    // get a startDate from the goal
    const goalStartDate = startOfDay(goal.startDate ?? goal.createdAt);

    return [
      { id: formatDateKey(goalStartDate), date: goalStartDate, value: 0 },
      ...Object.values(dateTasksMap),
    ];
  }, [completedTasks, goal.createdAt, goal.startDate]);

  const timeframeDifference = useTimeframeDifference({
    entries,
    startDate,
    endDate,
    target,
  });

  const completionPercentage =
    Math.floor((completedTasks.length / allTasks.length) * 100) || 0;

  return {
    allTasks,
    entries,
    target,
    completionPercentage,
    timeframeDifference,
  };
};
