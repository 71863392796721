import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { GoalSortingMode, OrderDirection } from 'shared/types/sorting-mode';
import { sortItems } from 'shared/utils/sort-items';

import {
  columnTypeOrderAscendingMap,
  columnTypeOrderDescendingMap,
} from './column-type-to-sorting-function-map';

export type Options = {
  mode?: GoalSortingMode[];
  order?: ID[];
  direction?: OrderDirection;
  allGoals: Goal[];
};

export const getSortedGoals = (
  goals: Goal[],
  { mode = [], order = [], direction = OrderDirection.Asc, allGoals }: Options,
) => {
  let sortedGoals = goals.toSorted(
    (goalA, goalB) => goalA.createdAt.getTime() - goalB.createdAt.getTime(),
  );

  mode?.forEach((mode) => {
    if (mode === GoalSortingMode.Custom) {
      sortedGoals = sortItems(sortedGoals, order, 'id');
      return;
    }

    const sortingFn =
      direction === OrderDirection.Asc
        ? columnTypeOrderAscendingMap({ goals: allGoals })[mode]
        : columnTypeOrderDescendingMap({ goals: allGoals })[mode];
    if (sortingFn) {
      sortedGoals.sort(sortingFn);
    }
  });

  return sortedGoals;
};
