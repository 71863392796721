import styled, { css } from 'styled-components';

export const TabsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  justify-content: space-between;

  padding: ${({ theme }) => `0 ${theme.spacing(3)} 0 ${theme.spacing(6)}`};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(3)}`};
    `};
`;
