import {
  getSortedGoals,
  useCreateUnlimitedGoalsAllowed,
  useGoalControls,
  useGoals,
} from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import {
  useGoalSorting,
  useUpdateGoalOrder,
  useUserHadTrial,
} from 'features/user';
import React, { useMemo } from 'react';
import { SelfSortingGoalListSection } from 'shared/components/ui/goal-list-section';
import { GoalPremiumBanner } from 'shared/components/ui/goal-premium-banner';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useUser } from 'shared/hooks/use-user';
import { GoalSortingMode } from 'shared/types/sorting-mode';
import { GoalRoadmapSortingKeys } from 'shared/types/user-settings';

import { useActiveGoals } from '../../hooks/use-active-goals';
import { EmptyBanner } from '../empty-banner';
import * as Styled from './no-grouping-goal-list.style';

export type NoGroupingGoalListProps = {};

export const NoGroupingGoalList: React.FC<NoGroupingGoalListProps> = () => {
  const user = useUser();
  const allGoals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();
  const userHadTrial = useUserHadTrial();

  const updateOrder = useUpdateGoalOrder(GoalRoadmapSortingKeys.InProgress);

  const openPremiumDialog = useOpenPremiumDialog();
  const {
    allowed: createGoalAllowed,
    enforced: createGoalEnforced,
    limit: createGoalLimit,
    total: createGoalTotal,
  } = useCreateUnlimitedGoalsAllowed();
  const goalSorting = useGoalSorting(GoalRoadmapSortingKeys.InProgress);
  const { goals } = useActiveGoals();
  const { onUpdateGoalProgress } = useGoalControls(goals);

  const sortedGoals = useMemo(
    () =>
      getSortedGoals(goals, {
        mode: [user?.settings?.goalSortingMode].filter(
          Boolean,
        ) as GoalSortingMode[],
        order: goalSorting,
        allGoals,
      }),
    [allGoals, goalSorting, goals, user?.settings?.goalSortingMode],
  );

  const showPremiumBanner =
    !createGoalAllowed ||
    (!createGoalEnforced && createGoalLimit < createGoalTotal);

  return !sortedGoals.length ? (
    <EmptyBanner />
  ) : (
    <Styled.Container>
      <SelfSortingGoalListSection
        lifeAreas={lifeAreas}
        goals={sortedGoals}
        allGoals={allGoals}
        onPremium={openPremiumDialog}
        onReorder={updateOrder}
        onUpdateProgress={onUpdateGoalProgress}
      />

      {showPremiumBanner && (
        <GoalPremiumBanner
          onClick={openPremiumDialog}
          createGoalEnforced={createGoalEnforced}
          createGoalTotal={createGoalTotal}
          createGoalLimit={createGoalLimit}
          hadTrial={userHadTrial}
        />
      )}
    </Styled.Container>
  );
};
