import { format, isSameYear } from 'date-fns';
import { TFunction } from 'i18next';
import { Timestamp } from 'shared/types/timestamp';

export type Options = {
  startDate?: Timestamp;
  endDate?: Timestamp;
  isShort?: boolean;
  today: Timestamp;
  t: TFunction;
};

export const getStartTillEndLabel = ({
  startDate,
  endDate,
  isShort,
  today,
  t,
}: Options) => {
  const fallbackStartLabel = isShort
    ? undefined
    : t('general.labels.no-start-date');
  const startLabel = startDate
    ? format(
        startDate,
        isShort || isSameYear(today, startDate) ? 'd MMM' : 'd MMM yyyy',
      )
    : fallbackStartLabel;

  const fallbackEndLabel = isShort
    ? undefined
    : t('general.labels.no-deadline');
  const endLabel = endDate
    ? format(
        endDate,
        isShort || isSameYear(today, endDate) ? 'd MMM' : 'd MMM yyyy',
      )
    : fallbackEndLabel;

  if (!startDate && !endDate) {
    return t('general.labels.no-schedule');
  }

  return [startLabel, endLabel].filter(Boolean).join(' - ');
};
