import { useHabits } from 'features/tasks';
import { useMemo } from 'react';
import { useUser } from 'shared/hooks/use-user';
import { sortItems } from 'shared/utils/sort-items';

export const useInsightsHabits = () => {
  const user = useUser();
  const habits = useHabits();

  return useMemo(() => {
    const filteredHabits = habits.filter(
      ({ id, completedAt, archivedAt, parentIds, schedules }) => {
        const activeSchedule = schedules.find(({ endDate }) => !endDate);

        return (
          !!activeSchedule &&
          (user?.settings?.insights?.showHiddenItems ||
            !user?.settings?.insights?.sections?.habits?.hiddenItems?.includes(
              id,
            )) &&
          !parentIds?.length &&
          !archivedAt &&
          !completedAt
        );
      },
    );

    const sortedHabits = sortItems(
      filteredHabits,
      user?.settings?.habitSorting ?? [],
      'id',
    );

    return user?.settings?.insights?.sections?.habits?.order?.length
      ? sortItems(
          sortedHabits,
          user.settings.insights.sections.habits.order,
          'id',
        )
      : sortedHabits;
  }, [
    habits,
    user?.settings?.habitSorting,
    user?.settings?.insights?.sections?.habits?.hiddenItems,
    user?.settings?.insights?.sections?.habits?.order,
    user?.settings?.insights?.showHiddenItems,
  ]);
};
