import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowRight, BarChart2, CheckCircle } from 'shared/assets/icons';
import { Section } from 'shared/components/ui/section';
import { Colors, StatisticCard } from 'shared/components/ui/statistic-card';
import { Timestamp } from 'shared/types/timestamp';

import * as Styled from './statistics.style';

export type StatisticsProps = {
  target?: number;
  completionPercentage?: number;
  completions: Timestamp[];
  skips: Timestamp[];
};

export const Statistics: React.FC<StatisticsProps> = ({
  target,
  completionPercentage,
  completions,
  skips,
}) => {
  const { t } = useTranslation();
  return (
    <Section title={t('general.labels.task-statistics.statistics.title')}>
      <Styled.StatisticsContainer>
        <StatisticCard
          value={completions.length}
          target={target}
          icon={CheckCircle}
          iconColor={Colors.Primary}
          description={t(
            'general.labels.task-statistics.statistics.completions.label',
          )}
        />

        <StatisticCard
          value={completionPercentage}
          icon={BarChart2}
          iconColor={Colors.Info}
          description={t(
            'general.labels.task-statistics.statistics.completion-rate.label',
          )}
          isPercentage
        />

        <StatisticCard
          value={skips.length}
          icon={ArrowRight}
          iconColor={Colors.Warning}
          description={t(
            'general.labels.task-statistics.statistics.skips.label',
          )}
        />
      </Styled.StatisticsContainer>
    </Section>
  );
};
