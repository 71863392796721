import { useMemo } from 'react';
import { useLifescansQuery } from 'shared/hooks/queries/use-lifescans-query';
import { useUser } from 'shared/hooks/use-user';

export const useLifescans = () => {
  const user = useUser();

  const { data } = useLifescansQuery(user?.uid);

  return useMemo(() => data ?? [], [data]);
};
