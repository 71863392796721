import styled, { DefaultTheme } from 'styled-components';

import { Colors } from './types';

const getColor = (color: Colors, theme: DefaultTheme): string | undefined => {
  const colorsMap: Record<Colors, string> = {
    [Colors.Primary]: theme.palette.primary.main,
    [Colors.Info]: theme.palette.info.main,
    [Colors.Warning]: theme.palette.warning.main,
    [Colors.Error]: theme.palette.error.main,
    [Colors.Success]: theme.palette.success.main,
  };

  return colorsMap[color];
};

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};

  padding: ${({ theme }) => `0 0 ${theme.spacing(2.5)}`};
`;

export const StatusList = styled.ul`
  display: flex;

  margin: 0;
  padding: ${({ theme }) => `${theme.spacing(0.5)} ${theme.spacing(2)}`};

  list-style: none;
  border-radius: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) => theme.palette.background.paper};
`;

export const StatusItem = styled.li`
  &:after {
    content: '';
    display: inline-block;
    width: 1px;
    height: 1.2rem;
    margin: ${({ theme }) => `0 ${theme.spacing(2)}`};

    background: ${({ theme }) => theme.palette.divider};
  }

  &:last-child:after {
    content: none;
  }
`;

export const StatusContainer = styled.span`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const StatusIcon = styled.span<{ $color: Colors }>`
  color: ${({ theme, $color }) => getColor($color, theme)};
  font-size: 1.2rem;
  line-height: 62.5%;
`;
