import { useGoalControls, useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import React, { useMemo } from 'react';
import { GoalListSection } from 'shared/components/ui/goal-list-section';
import { GridContainer } from 'shared/components/ui/grid-container';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useToday } from 'shared/contexts/today';

import * as Styled from './completed.style';
import { EmptyCompleted } from './components/empty-completed';
import { TabPanel } from './components/tab-panel';
import { GoalsFilterOptions, goalsFilters } from './utils/goals-filter';

export const Completed: React.FC = () => {
  const today = useToday();
  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();
  const { onUpdateGoalProgress } = useGoalControls(goals);

  const openPremiumDialog = useOpenPremiumDialog();

  const filteredGoals = useMemo(
    () =>
      goalsFilters[GoalsFilterOptions.Completed](goals, today).sort(
        (goalA, goalB) =>
          goalB.completedAt!.getTime() - goalA.completedAt!.getTime(),
      ),
    [goals, today],
  );

  return (
    <GridContainer hasFlex1={!filteredGoals.length}>
      {!filteredGoals.length ? (
        <EmptyCompleted />
      ) : (
        <TabPanel role="tab-panel">
          <Styled.Container>
            <GoalListSection
              goals={filteredGoals}
              allGoals={goals}
              lifeAreas={lifeAreas}
              onPremium={openPremiumDialog}
              onUpdateProgress={onUpdateGoalProgress}
            />
          </Styled.Container>
        </TabPanel>
      )}
    </GridContainer>
  );
};
