import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PeriodSelection } from '../period-selection';
import { SettingsMenu } from '../settings-menu';
import * as Styled from './header.style';

export const Header: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Styled.Container>
      <Styled.Top>
        <Styled.Side>
          <Typography variant="h1">{t('pages.insights.title')}</Typography>
        </Styled.Side>

        <Styled.CenterDesktop>
          <PeriodSelection />
        </Styled.CenterDesktop>

        <Styled.Side $right>
          <SettingsMenu />
        </Styled.Side>
      </Styled.Top>

      <Styled.Bottom>
        <Styled.CenterMobile>
          <PeriodSelection isLight isFullWidth />
        </Styled.CenterMobile>
      </Styled.Bottom>
    </Styled.Container>
  );
};
