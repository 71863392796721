import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};

  padding: ${({ theme }) => `${theme.spacing(1)} 0 0`};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)};
`;

export const TitleBar = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};

  padding: ${({ theme }) => `0 ${theme.spacing(3)}`};
  width: 100%;
`;

export const TitleContainer = styled.div`
  flex: 1;
`;

export const CheckboxContainer = styled.div<{ $isFrozen: boolean }>`
  margin-top: ${({ theme }) => theme.spacing(0.25)};
  line-height: 62.5%;

  ${({ $isFrozen }) =>
    $isFrozen &&
    css`
      opacity: 0.3;
    `};
`;

export const MetaContainer = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacing(2)} ${theme.spacing(3)} 0 ${theme.spacing(8.5)}`};
`;

export const Body = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};

  padding-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const DescriptionContainer = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(6)} 0 ${theme.spacing(10)}`};
`;

export const SubTasks = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(2)} 0 ${theme.spacing(4)}`};
`;
