import React from 'react';
import { Toggle } from 'shared/components/ui/toggle';

import * as Styled from './popup-menu.style';

export type PopupMenuToggleProps = {
  label: string;
  onChange: (checked: boolean) => void;
  checked: boolean;
  id?: string;
};

export const PopupMenuToggle: React.FC<PopupMenuToggleProps> = ({
  label,
  checked,
  onChange,
  id = window.crypto.randomUUID(),
}) => {
  const _onChange = () => onChange(!checked);

  return (
    <Styled.ToggleLabel htmlFor={id}>
      {label}

      <Toggle value="" id={id} checked={checked} onChange={_onChange} />
    </Styled.ToggleLabel>
  );
};
