import styled from 'styled-components';

export const List = styled.ul`
  display: grid;
  gap: ${({ theme }) => theme.spacing(1.5)};
  list-style: none;

  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li``;
