import {
  confirmPasswordReset,
  signInWithEmailAndPassword,
  verifyPasswordResetCode,
} from 'firebase/auth';
import { Locales } from 'shared/assets/localization';
import { getGlobalAllowedSearchParameters } from 'shared/lib/react-router-dom';
import { ResetPasswordErrorTypes } from 'shared/types/reset-password-form';

import { createPushToken } from './create-push-token';
import { finishUserSignup } from './finish-user-signup';
import { getPushToken } from './get-push-token';
import { getUser } from './get-user';
import { getAuthentication } from './helpers';

export const completeSignupAndLogin = async (
  code: string,
  password: string,
  { name, locale }: { name?: string; locale?: Locales },
) => {
  const auth = await getAuthentication();

  try {
    const email = await verifyPasswordResetCode(auth, code);
    await confirmPasswordReset(auth, code, password);
    await signInWithEmailAndPassword(auth, email, password);
    const notificationsEnabled = Notification?.permission === 'granted';

    await finishUserSignup({
      uid: auth.currentUser?.uid,
      name,
      locale,
      hasNotifications: notificationsEnabled,
      queryParameters: Object.fromEntries(getGlobalAllowedSearchParameters()),
    });

    const user = await getUser();

    if (user && notificationsEnabled) {
      const token = await getPushToken(user.uid);
      token && (await createPushToken(user.uid, token, locale));
    }

    return user;
  } catch (e) {
    throw ResetPasswordErrorTypes.Unknown;
  }
};
