import {
  differenceInDays,
  endOfWeek,
  isSameWeek,
  startOfMonth,
} from 'date-fns';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BarRepresentOptions,
  TickRange,
} from 'shared/components/ui/time-bar-chart/types';
import { weekdaysNumberMap } from 'shared/constants';
import { useToday } from 'shared/contexts/today';
import { Entry } from 'shared/lib/recharts';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { formatNumberShort } from 'shared/utils/format-number-short';

import { BarChart } from './bar-chart';
import {
  dateTickFormatterDay,
  dateTickFormatterMonth,
  dateTickFormatterWeekDay,
} from './date-tick-formatter';
import * as Styled from './time-bar-chart.style';
import { useTickRange } from './use-tick-range';
import { useXAxisData } from './use-x-axis-data';
import { useYAxisData } from './use-y-axis-data';

export type TimeBarChartProps = {
  data: Entry[];
  weekStartsOn: WeekDays;
  startDate?: Timestamp | null;
  endDate?: Timestamp | null;
  startValue?: number | null;
  target?: number | null;
  showYAxis?: boolean;
};

export const TimeBarChart: React.FC<TimeBarChartProps> = memo(
  ({
    data,
    weekStartsOn,
    startDate,
    endDate,
    startValue,
    target,
    showYAxis,
  }) => {
    const { t } = useTranslation();
    const today = useToday();

    const { domain: xDomain } = useXAxisData(data, {
      startDate: startDate ?? undefined,
      endDate: endDate ?? undefined,
    });

    const { ticks: yTicks } = useYAxisData(data, {
      startValue: startValue ?? undefined,
      endValue: target ?? undefined,
    });

    const tickRange = useTickRange(xDomain);
    const xTicks = useMemo(() => {
      let mappedDates = data.map(({ date }) => date.getTime());
      if (tickRange === TickRange.Month) {
        mappedDates = mappedDates.filter((date) =>
          isSameWeek(
            date,
            startOfMonth(
              endOfWeek(date, {
                weekStartsOn: weekdaysNumberMap[weekStartsOn],
              }),
            ),
            {
              weekStartsOn: weekdaysNumberMap[weekStartsOn],
            },
          ),
        );
      }

      return mappedDates;
    }, [data, tickRange, weekStartsOn]);

    const barRepresents = useMemo(() => {
      const [firstEntry, secondEntry] = data;
      if (!firstEntry || !secondEntry) {
        return BarRepresentOptions.Day;
      }

      const dayDiff = differenceInDays(secondEntry.date, firstEntry.date);

      if (dayDiff < 7) {
        return BarRepresentOptions.Day;
      }
      if (dayDiff < 28) {
        return BarRepresentOptions.Week;
      }

      return BarRepresentOptions.Month;
    }, [data]);

    const dateTickFormatter = useMemo(
      () =>
        tickRange === TickRange.Day
          ? dateTickFormatterWeekDay
          : tickRange === TickRange.Week
            ? dateTickFormatterDay
            : dateTickFormatterMonth,
      [tickRange],
    );

    const xFormatter = (tick: number) =>
      dateTickFormatter({ date: new Date(tick), today, t, weekStartsOn });

    return (
      <Styled.Container>
        <BarChart
          data={data}
          yTicks={yTicks}
          yTickFormatter={showYAxis ? formatNumberShort : undefined}
          xTicks={xTicks}
          xTickFormatter={xFormatter}
          target={target ?? undefined}
          barRepresents={barRepresents}
          weekStartsOn={weekStartsOn}
        />
      </Styled.Container>
    );
  },
);
