import styled from 'styled-components';

export const StatisticsContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: ${({ theme }) => theme.spacing(2)};

  padding: ${({ theme }) =>
    `0 ${theme.spacing(theme.isMobile ? 3 : 6)} ${theme.spacing(0.5)}`};
`;
