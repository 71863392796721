import { useGoals } from 'features/goals';
import { useMemo } from 'react';
import { useToday } from 'shared/contexts/today';

import { GoalsFilterOptions, goalsFilters } from '../utils/goals-filter';

export const useRoadmapGoals = () => {
  const today = useToday();
  const goals = useGoals();

  return useMemo(
    () =>
      goalsFilters[GoalsFilterOptions.Roadmap](goals, today).filter(
        ({ parentIds }) => !parentIds?.length,
      ),
    [goals, today],
  );
};
