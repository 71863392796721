import React from 'react';
import { useTranslation } from 'react-i18next';
import { PremiumBanner } from 'shared/components/ui/premium-banner';

export type GoalPremiumBannerProps = {
  onClick: () => void;
  createGoalEnforced?: boolean;
  createGoalTotal: number;
  createGoalLimit: number;
  hadTrial?: boolean;
};

export const GoalPremiumBanner: React.FC<GoalPremiumBannerProps> = ({
  onClick,
  createGoalEnforced,
  createGoalTotal,
  createGoalLimit,
  hadTrial,
}) => {
  const { t } = useTranslation();
  return (
    <PremiumBanner
      title={t(
        createGoalEnforced
          ? 'pages.goals.premium-banner.title'
          : 'pages.goals.premium-banner.not-enforced.title',
        { total: createGoalTotal, limit: createGoalLimit },
      )}
      description={t(
        createGoalEnforced
          ? hadTrial
            ? 'pages.goals.premium-banner.description'
            : 'pages.goals.premium-banner.try-for-free.description'
          : 'pages.goals.premium-banner.not-enforced.description',
        { limit: createGoalLimit },
      )}
      onClick={onClick}
      isTrial={!hadTrial}
    />
  );
};
