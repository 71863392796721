import { css } from 'styled-components';

export const dragging = css`
  position: relative;

  > * > * {
    opacity: 0;
  }

  &:after {
    content: '';

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    border-radius: ${({ theme }) => theme.spacing(2)};
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
`;

export const hovered = css`
  &:after {
    content: '';

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    opacity: 0.5;

    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    border-radius: ${({ theme }) => theme.spacing(2)};
    background-color: ${({ theme }) => theme.palette.grey['50']};
  }
`;
