import { useGoalMetricProgress, useGoals } from 'features/goals';
import { useTimeframeTime } from 'features/user';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GoalInsightsCard } from 'shared/components/connected/goal-insights-card';
import { Goal } from 'shared/types/goal';
import { metricUnitFormatBase } from 'shared/types/metric-template';
import { getCompletionPercentage } from 'shared/utils/get-completion-percentage';
import { getGoalCompletion } from 'shared/utils/get-goal-completion';
import { getLocalizedStringValue } from 'shared/utils/get-localized-string-value';

import { useTimeframeDifference } from '../../hooks/use-timeframe-difference';

export type GoalInsightsMetricEntriesCardProps = {
  goal: Goal;
};

export const GoalInsightsMetricEntriesCard: React.FC<
  GoalInsightsMetricEntriesCardProps
> = ({ goal }) => {
  const { i18n } = useTranslation();
  const goals = useGoals();
  const { startDate, endDate } = useTimeframeTime({
    weekEndsWithStartOfDay: true,
  });

  const goalProgress = useGoalMetricProgress(goal);
  const timeframeDifference = useTimeframeDifference({
    entries: goalProgress,
    startDate,
    endDate,
    target: goal.metric?.targetValue ?? undefined,
  });

  const completionPercentage = useMemo(
    () =>
      getCompletionPercentage(
        getGoalCompletion(goal, {
          goals,
        }),
      ),
    [goal, goals],
  );

  const unitFormat = goal?.metric?.unit
    ? getLocalizedStringValue(
        goal.metric.unit.format ??
          `${metricUnitFormatBase} ${getLocalizedStringValue(goal.metric.unit.name, i18n.language)}`,
        i18n.language,
      )
    : undefined;

  return (
    <GoalInsightsCard
      goal={goal}
      entries={goalProgress}
      completionPercentage={completionPercentage}
      unitFormat={unitFormat}
      startValue={goal.metric?.startValue ?? undefined}
      target={goal.metric?.targetValue ?? undefined}
      timeframeDifference={timeframeDifference}
      startDate={startDate}
      endDate={endDate}
      isLink
    />
  );
};
