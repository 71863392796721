import Typography from '@mui/material/Typography';
import { format, isSameQuarter } from 'date-fns';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Timestamp } from 'shared/types/timestamp';

import * as Styled from './list-section-month-header.style';

export type ListSectionQuarterlyHeaderProps = {
  today: Timestamp;
  date: Timestamp;
  amount?: number;
};

export const ListSectionQuarterlyHeader: React.FC<ListSectionQuarterlyHeaderProps> =
  React.memo(({ today, date, amount }) => {
    const { t } = useTranslation();

    const label = useMemo(() => {
      if (date.getTime() === new Date(0).getTime()) {
        return t('general.labels.dates.unplanned');
      }

      if (isSameQuarter(date, today)) {
        return t('general.labels.dates.later-this-quarter');
      }

      return format(date, 'QQQ, yyyy');
    }, [date, t, today]);

    return (
      <Styled.Container>
        <Typography variant="h6" component="span">
          {label}
        </Typography>
        {!!amount && <Styled.Badge>{amount}</Styled.Badge>}
      </Styled.Container>
    );
  });
