import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataGridCreateRow } from 'shared/components/ui/data-grid-create-row';
import { GoalList } from 'shared/components/ui/goal-list';
import { GoalPremiumBanner } from 'shared/components/ui/goal-premium-banner';

export type GoalListSectionProps = React.ComponentProps<typeof GoalList> & {
  onPremium: () => void;
  onCreateGoal?: (e: React.MouseEvent) => void;
  createGoalAllowed?: boolean;
  createGoalEnforced?: boolean;
  createGoalLimit?: number;
  createGoalTotal?: number;
  showPremiumBanner?: boolean;
  hadTrial?: boolean;
};

export const GoalListSection: React.FC<GoalListSectionProps> = ({
  onPremium,
  onCreateGoal,
  createGoalAllowed,
  createGoalEnforced,
  createGoalLimit,
  createGoalTotal,
  showPremiumBanner,
  hadTrial,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <>
      <GoalList onPremium={onPremium} {...rest} />

      {showPremiumBanner &&
        createGoalTotal !== null &&
        createGoalTotal !== undefined &&
        createGoalLimit !== null &&
        createGoalLimit !== undefined && (
          <GoalPremiumBanner
            onClick={onPremium}
            createGoalEnforced={createGoalEnforced}
            createGoalTotal={createGoalTotal}
            createGoalLimit={createGoalLimit}
            hadTrial={hadTrial}
          />
        )}

      {!!onCreateGoal && (
        <DataGridCreateRow
          label={t('general.labels.goal-table.footer.create.label')}
          onClick={onCreateGoal}
          showPremiumLabel={!createGoalAllowed}
        />
      )}
    </>
  );
};
