export const sortItems = <Item>(
  items: Item[],
  order: Item[keyof Item][],
  key: keyof Item,
): Item[] => {
  // Maak een map van waarden naar hun overeenkomstige volgorde-index
  const orderMap = order.reduce<Record<string, number>>((acc, value, index) => {
    acc[value as string] = index;
    return acc;
  }, {});
  const orderedItems = items.filter((item) => order.includes(item[key]));
  const leftOverItems = items.filter((item) => !order.includes(item[key]));

  return [
    // Sort the items based on given key
    ...orderedItems.toSorted((a, b) => {
      const aOrder = orderMap[a[key] as string];
      const bOrder = orderMap[b[key] as string];
      return aOrder - bOrder;
    }),
    // add the leftover items to the bottom
    ...leftOverItems,
  ];
};
