import { IconFormNames } from 'shared/assets/icons';

export enum IconCategory {
  WorkAndCareer = 'work-and-career',
  Sport = 'sport',
  Health = 'health',
  MoneyAndFinance = 'money-and-finance',
  FunAndRelaxation = 'fun-and-relaxation',
  EducationAndLearning = 'education-and-learning',
  FamilyAndFriends = 'family-and-friends',
  LoveAndRelationships = 'love-and-relationships',
  Spirituality = 'spirituality',
  Other = 'other',
}

export type IconCategoryListItem = {
  category: IconCategory;
  icons: IconFormNames[];
};

export const iconCategoryList: IconCategoryListItem[] = [
  {
    category: IconCategory.WorkAndCareer,
    icons: [
      IconFormNames.WorkAndCareer,
      IconFormNames.LargeBuilding,
      IconFormNames.Chart,
      IconFormNames.Percentage,
      IconFormNames.LaptopCode,
      IconFormNames.Bus,
      IconFormNames.Car,
    ],
  },
  {
    category: IconCategory.Sport,
    icons: [
      IconFormNames.SportAndHealth,
      IconFormNames.SportRings,
      IconFormNames.SportFitnessBiceps,
      IconFormNames.SportSneakers,
      IconFormNames.Bicycle,
      IconFormNames.SportBaseball,
      IconFormNames.SportSlimWaist,
      IconFormNames.SportAmericanFootballHelmet,
      IconFormNames.SportSnowboard,
      IconFormNames.SportTennisRacquet,
      IconFormNames.SportSkatingShoes,
      IconFormNames.SportMartialArtsSwordFencing,
      IconFormNames.SportGolf,
      IconFormNames.SportHockey,
      IconFormNames.SportDumbbell,
      IconFormNames.SportJumpingRope,
      IconFormNames.SportFootball,
      IconFormNames.SportYoga,
      IconFormNames.SportSwimmingPool,
      IconFormNames.SportBoxingGlove,
      IconFormNames.SportTennisNet,
      IconFormNames.SportWaterpoloBall,
      IconFormNames.SportHockeyStickPuck,
      IconFormNames.SportRugbyBall,
      IconFormNames.SportFieldSoccerField,
      IconFormNames.SportSurfingBoard,
      IconFormNames.SportDivingMask,
      IconFormNames.SportSportsHelmet,
    ],
  },
  {
    category: IconCategory.Health,
    icons: [
      IconFormNames.Bed,
      IconFormNames.Stethoscope,
      IconFormNames.Heartbeat,
      IconFormNames.Teeth,
      IconFormNames.UserHeartHealth,
      IconFormNames.BandageShoulder,
      IconFormNames.HealthReport,
      IconFormNames.Medicine,
      IconFormNames.ForkKnife,
      IconFormNames.Fish,
      IconFormNames.Meat,
      IconFormNames.Apple,
      IconFormNames.Eggplant,
    ],
  },
  {
    category: IconCategory.MoneyAndFinance,
    icons: [
      IconFormNames.MoneyAndFinance,
      IconFormNames.Wallet,
      IconFormNames.Coins,
      IconFormNames.CoinsDocumentChart,
      IconFormNames.PiggyBank,
      IconFormNames.Bitcoin,
      IconFormNames.Dollar,
      IconFormNames.Euro,
      IconFormNames.Gbp,
      IconFormNames.Yen,
    ],
  },
  {
    category: IconCategory.FunAndRelaxation,
    icons: [
      IconFormNames.FunAndRelaxation,
      IconFormNames.MountainsCloud,
      IconFormNames.Plane,
      IconFormNames.Boat,
      IconFormNames.Map,
      IconFormNames.Globe,
      IconFormNames.TravelBag,
      IconFormNames.Train,
      IconFormNames.ChefHat,
      IconFormNames.ServingDome,
      IconFormNames.Croissant,
      IconFormNames.AsianFood,
      IconFormNames.Camera,
      IconFormNames.MusicNote,
      IconFormNames.HatMagician,
      IconFormNames.GameCardsDiamond,
      IconFormNames.ColorPalette,
      IconFormNames.Headphones,
      IconFormNames.Computer,
      IconFormNames.Phone,
      IconFormNames.GameController,
      IconFormNames.ThreeDGlasses,
      IconFormNames.Television,
      IconFormNames.Movies,
      IconFormNames.Microphone,
      IconFormNames.Campfire,
      IconFormNames.Hammer,
    ],
  },
  {
    category: IconCategory.EducationAndLearning,
    icons: [
      IconFormNames.PersonalDevelopment,
      IconFormNames.Glasses,
      IconFormNames.ExamAGradeSquare,
      IconFormNames.LanguageTranslateMessage,
      IconFormNames.Backpack,
      IconFormNames.GraduateHat,
      IconFormNames.Pen,
      IconFormNames.EducationAndLearning,
      IconFormNames.LabTube,
      IconFormNames.AiChip,
    ],
  },
  {
    category: IconCategory.FamilyAndFriends,
    icons: [
      IconFormNames.FamilyAndFriends,
      IconFormNames.HomeHouse,
      IconFormNames.Baby,
      IconFormNames.GroupPeople,
      IconFormNames.Dog,
      IconFormNames.Cat,
      IconFormNames.ChampagneGlasses,
      IconFormNames.Cake,
      IconFormNames.GiftBox,
    ],
  },
  {
    category: IconCategory.LoveAndRelationships,
    icons: [
      IconFormNames.LoveAndRelationships,
      IconFormNames.HomeHouseKey,
      IconFormNames.WeddingRings,
      IconFormNames.Pendant,
      IconFormNames.LoveChat,
      IconFormNames.PlaceholderIcon,
    ],
  },
  {
    category: IconCategory.Spirituality,
    icons: [IconFormNames.Spirituality],
  },
  {
    category: IconCategory.Other,
    icons: [
      IconFormNames.Target,
      IconFormNames.Repeat,
      IconFormNames.RewardCup,
      IconFormNames.Rocket,
      IconFormNames.Fireworks,
      IconFormNames.Stars,
      IconFormNames.Sunrise,
      IconFormNames.Sun,
      IconFormNames.Leaf,
      IconFormNames.Store,
      IconFormNames.ShoppingBag,
      IconFormNames.Package,
      IconFormNames.ShoppingCart,
    ],
  },
];
