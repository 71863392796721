import {
  addDays,
  endOfWeek,
  isBefore,
  isSameDay,
  isSameMonth,
  isSameWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { useMemo } from 'react';
import { weekdaysNumberMap } from 'shared/constants';
import { useUser } from 'shared/hooks/use-user';
import { Entry } from 'shared/lib/recharts';
import { InsightsTimeframes } from 'shared/types/insights';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';

export type Options = {
  timeframe: InsightsTimeframes;
  startDate: Timestamp;
  endDate: Timestamp;
  completions: Timestamp[];
};

export const useHabitEntries = ({
  timeframe,
  startDate,
  endDate,
  completions,
}: Options) => {
  const user = useUser();
  const weekStartsOn = user?.settings?.startOfWeek ?? WeekDays.Monday;

  const entries = useMemo(() => {
    if (timeframe === InsightsTimeframes.Month) {
      return undefined;
    }
    // get all days between start and end date
    const dates: Date[] = [];

    for (
      let currentDate = startOfDay(startDate);
      isBefore(currentDate, endDate) || isSameDay(currentDate, endDate);
      currentDate = startOfDay(addDays(currentDate, 1))
    ) {
      dates.push(currentDate);
    }

    // reduce the dates to the expected timeframe dates
    const dayTransform = (date: Timestamp) => startOfDay(date).getTime();
    const weekTransform = (date: Timestamp) =>
      endOfWeek(date, {
        weekStartsOn: weekdaysNumberMap[weekStartsOn],
      }).getTime();
    const monthTransform = (date: Timestamp) => startOfMonth(date).getTime();
    const quaterWeekTransform = (date: Timestamp) => {
      const dateStartOfWeek = startOfWeek(date, {
        weekStartsOn: weekdaysNumberMap[weekStartsOn],
      });
      return isBefore(dateStartOfWeek, startDate)
        ? startDate.getTime()
        : dateStartOfWeek.getTime();
    };
    const isSameWeekFn = (dateA: Timestamp, dateB: Timestamp) =>
      isSameWeek(dateA, dateB, {
        weekStartsOn: weekdaysNumberMap[weekStartsOn],
      });

    const timeframeMap = {
      [InsightsTimeframes.Week]: {
        dateTransform: dayTransform,
        isSameFn: isSameDay,
      },
      [InsightsTimeframes.Quarter]: {
        dateTransform: quaterWeekTransform,
        isSameFn: isSameWeekFn,
      },
      [InsightsTimeframes.FourWeeks]: {
        dateTransform: weekTransform,
        isSameFn: isSameWeekFn,
      },
      [InsightsTimeframes.TwelveWeeks]: {
        dateTransform: weekTransform,
        isSameFn: isSameWeekFn,
      },
      [InsightsTimeframes.Year]: {
        dateTransform: monthTransform,
        isSameFn: isSameMonth,
      },
      [InsightsTimeframes.TwelveMonths]: {
        dateTransform: monthTransform,
        isSameFn: isSameMonth,
      },
    };

    const { dateTransform } = timeframeMap[timeframe];
    const uniqueDates = Array.from(
      new Set(dates.map((date) => dateTransform(date))),
    ).map((dateTime) => new Date(dateTime));

    const groupedCompletionsMap = new Map<number, number>();
    completions.forEach((completion) => {
      const transformedDate = dateTransform(completion);
      groupedCompletionsMap.set(
        transformedDate,
        (groupedCompletionsMap.get(transformedDate) ?? 0) + 1,
      );
    });

    return uniqueDates.map<Entry>((date) => ({
      date,
      value: groupedCompletionsMap.get(dateTransform(date)) ?? 0,
    }));
  }, [completions, endDate, startDate, timeframe, weekStartsOn]);

  return {
    entries,
  };
};
