import React from 'react';
import { usePremiumFeatureAllowed } from 'shared/hooks/use-premium-feature-allowed';
import { Habit } from 'shared/types/habit';
import { LimitKeys } from 'shared/types/limit-keys';

import { HabitInsightsMetricCard } from './habit-insights-metric-card';
import { HabitInsightsPreviewCard } from './habit-insights-preview-card';

export type HabitInsightsCardProps = {
  habit: Habit;
};

export const HabitInsightsCard: React.FC<HabitInsightsCardProps> = ({
  habit,
}) => {
  const { allowed } = usePremiumFeatureAllowed(LimitKeys.Insights, {});

  if (!allowed) {
    return <HabitInsightsPreviewCard habit={habit} />;
  }

  return <HabitInsightsMetricCard habit={habit} />;
};
