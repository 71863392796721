import { isSameDay } from 'date-fns';
import React from 'react';
import { useToday } from 'shared/contexts/today';

import * as Styled from './x-axis-tick.style';

export type TickProps = {
  x?: number;
  y?: number;
  payload?: {
    value: Date | number;
  };
  dateFormatter: (tick: number) => string;
};

export const XAxisTick: React.FC<TickProps> = ({
  x,
  y,
  payload,
  dateFormatter,
}) => {
  const today = useToday();

  if (!payload) {
    return null;
  }

  const date = new Date(payload.value);
  const isToday = isSameDay(date, today);

  return (
    <Styled.SvgText
      x={x}
      y={y}
      dy={16}
      orientation="bottom"
      textAnchor="middle"
      $isHighlighted={isToday}
    >
      {dateFormatter(payload.value.valueOf())}
    </Styled.SvgText>
  );
};
