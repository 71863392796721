import { useUpdateGoal } from 'features/goals';
import { useCallback, useMemo } from 'react';
import {
  QuarterlyOrder,
  useUpdateGoalRoadmapOrderMutation,
} from 'shared/hooks/queries/use-update-goal-roadmap-order-mutation';
import { Goal } from 'shared/types/goal';
import { GoalDateSection } from 'shared/types/orderable-section';

export const useHandleGoalRoadmapReorder = (sections: GoalDateSection[]) => {
  const { submit: updateGoal } = useUpdateGoal();
  const { mutate: updateOrder } = useUpdateGoalRoadmapOrderMutation();

  const oldSections = useMemo(() => structuredClone(sections), [sections]);

  return useCallback(
    (newSections: GoalDateSection[], movedGoal: Goal) => {
      const newSection = newSections.find(({ items }) =>
        items.find(({ id }) => id === movedGoal.id),
      );

      const oldSection = oldSections.find(({ items }) =>
        items.find(({ id }) => id === movedGoal.id),
      );

      if (!newSection || !oldSection) {
        // data error
        return;
      }

      if (newSection.startDate.getTime() !== oldSection.startDate.getTime()) {
        updateGoal({ id: movedGoal.id, startDate: newSection.startDate });
      }

      const ordersToUpdate = [
        {
          quarterlyStartDate: newSection.startDate,
          ids: newSection.items.map(({ id }) => id),
        },
        newSection.id !== oldSection.id
          ? {
              quarterlyStartDate: oldSection.startDate,
              ids: oldSection.items
                .filter(({ id }) => id !== movedGoal.id)
                .map(({ id }) => id),
            }
          : undefined,
      ].filter(Boolean) as QuarterlyOrder[];

      updateOrder(ordersToUpdate);
    },
    [oldSections, updateGoal, updateOrder],
  );
};
