import { isBefore, isSameDay } from 'date-fns';
import { getSortedGoals, useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import { useMemo } from 'react';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { Goal } from 'shared/types/goal';
import { GoalGroupBy, GoalSortingMode } from 'shared/types/sorting-mode';
import { GoalRoadmapSortingKeys } from 'shared/types/user-settings';
import { sortItems } from 'shared/utils/sort-items';

export const useInsightsGoals = () => {
  const today = useToday();
  const lifeAreas = useLocalizedLifeAreas();
  const goals = useGoals();
  const user = useUser();

  const sortedLifeAreas = useMemo(
    () => sortItems(lifeAreas, user?.settings?.lifeAreaSorting ?? [], 'id'),
    [lifeAreas, user?.settings?.lifeAreaSorting],
  );

  return useMemo(() => {
    const filteredGoals = goals.filter(
      ({ id, completedAt, archivedAt, startDate, parentIds }) =>
        (user?.settings?.insights?.showHiddenItems ||
          !user?.settings?.insights?.sections?.goals?.hiddenItems.includes(
            id,
          )) &&
        !parentIds?.length &&
        !archivedAt &&
        !completedAt &&
        !!startDate &&
        (isBefore(startDate, today) || isSameDay(startDate, today)),
    );

    const order =
      user?.settings?.goalGroupBy === GoalGroupBy.None
        ? user?.settings?.goalRoadmapSorting?.[
            GoalRoadmapSortingKeys.InProgress
          ] ?? []
        : [
            ...sortedLifeAreas
              .map(
                ({ id }) =>
                  user?.settings?.goalGroupSorting?.lifeArea?.sorting?.[id] ??
                  [],
              )
              .flat(1),
            ...(user?.settings?.goalGroupSorting?.lifeArea?.sorting?.['null'] ??
              []),
          ];

    let sortedGoals = getSortedGoals(filteredGoals, {
      mode: [user?.settings?.goalSortingMode].filter(
        Boolean,
      ) as GoalSortingMode[],
      order,
      allGoals: goals,
    });

    if (user?.settings?.insights?.sections?.goals?.order?.length) {
      sortedGoals = sortItems(
        sortedGoals,
        user.settings.insights.sections.goals.order,
        'id',
      );
    }

    return user?.settings?.insights?.showActiveSubGoals
      ? sortedGoals.reduce<Goal[]>((acc, goal) => {
          acc.push(
            ...[
              goal,
              ...((goal.goalSorting
                ?.map((subGoalId) =>
                  goals.find(
                    ({ id, completedAt, archivedAt, startDate }) =>
                      id === subGoalId &&
                      !archivedAt &&
                      !completedAt &&
                      !!startDate,
                  ),
                )
                .filter(Boolean) as Goal[]) ?? []),
            ],
          );
          return acc;
        }, [])
      : sortedGoals;
  }, [
    goals,
    sortedLifeAreas,
    today,
    user?.settings?.goalGroupBy,
    user?.settings?.goalGroupSorting?.lifeArea?.sorting,
    user?.settings?.goalRoadmapSorting,
    user?.settings?.goalSortingMode,
    user?.settings?.insights?.sections?.goals?.hiddenItems,
    user?.settings?.insights?.sections?.goals?.order,
    user?.settings?.insights?.showActiveSubGoals,
    user?.settings?.insights?.showHiddenItems,
  ]);
};
