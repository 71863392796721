import React from 'react';
import { SortableContainer } from 'shared/components/ui/sortable-container';
import { DraggableOptions } from 'shared/types/draggable-types';

import { GoalListCard, GoalListCardProps } from './goal-list-card';

export const DndGoalListCard: React.FC<GoalListCardProps> = ({
  goal,
  ...rest
}) => {
  return (
    <SortableContainer
      id={goal.id}
      type={DraggableOptions.Goal}
      hasBigBorderRadius
    >
      <GoalListCard goal={goal} {...rest} />
    </SortableContainer>
  );
};
