import { ID } from 'shared/types/id';

export enum PushNotificationMessageRequestState {
  Asked = 'asked',
  NotAsked = 'not-asked',
}

const pushNotificationMessageKey = 'push-notification-message';

export const getPushNotificationMessage = (
  uid?: ID,
): PushNotificationMessageRequestState => {
  const messageStatesPerUidString = window.localStorage.getItem(
    pushNotificationMessageKey,
  );
  let statePerUid: Record<ID, PushNotificationMessageRequestState>;

  try {
    statePerUid = JSON.parse(messageStatesPerUidString ?? '') ?? {};
  } catch (e) {
    // old states; overwrite
    statePerUid = {};
  }

  return statePerUid[uid!] ?? PushNotificationMessageRequestState.NotAsked;
};

export const setPushNotificationMessage = (
  uid: ID,
  state: PushNotificationMessageRequestState,
) => {
  const messageStatesPerUidString = window.localStorage.getItem(
    pushNotificationMessageKey,
  );
  let statePerUid: Record<ID, PushNotificationMessageRequestState>;

  try {
    statePerUid = JSON.parse(messageStatesPerUidString ?? '') ?? {};
  } catch (e) {
    // old states; overwrite
    statePerUid = {};
  }

  statePerUid[uid] = state;

  window.localStorage.setItem(
    pushNotificationMessageKey,
    JSON.stringify(statePerUid),
  );
};
