import { addDays, endOfDay, endOfMonth, startOfMonth } from 'date-fns';
import { useTimeframeTime } from 'features/user';
import React, { useMemo } from 'react';
import { Frequency, RRule } from 'rrule';
import { HabitInsightsCard } from 'shared/components/connected/habit-insights-card';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { MetricEntry } from 'shared/types/goal-metric';
import { Habit } from 'shared/types/habit';
import { InsightsTimeframes } from 'shared/types/insights';
import { WeekDays, workWeek } from 'shared/types/week-days';
import { getRruleWeekDays } from 'shared/utils/get-rrule-week-days';

const target = 10;
const currentValue = 7;

export type HabitInsightsPreviewCardProps = {
  habit: Habit;
};

export const HabitInsightsPreviewCard: React.FC<
  HabitInsightsPreviewCardProps
> = ({ habit }) => {
  const user = useUser();
  const today = useToday();
  const { timeframe } = useTimeframeTime();

  const entries = useMemo<MetricEntry[] | undefined>(
    () =>
      timeframe === InsightsTimeframes.Month
        ? undefined
        : [
            {
              id: window.crypto.randomUUID(),
              date: addDays(today, -7),
              value: 5,
            },
            {
              id: window.crypto.randomUUID(),
              date: addDays(today, -5),
              value: 3,
            },
            {
              id: window.crypto.randomUUID(),
              date: addDays(today, -2),
              value: 10,
            },
            {
              id: window.crypto.randomUUID(),
              date: today,
              value: currentValue,
            },
          ],
    [timeframe, today],
  );

  const occurrences = useMemo(() => {
    const rrule = new RRule({
      freq: Frequency.WEEKLY,
      byweekday: getRruleWeekDays(workWeek),
      dtstart: startOfMonth(today),
      until: endOfMonth(today),
    });
    return rrule.all();
  }, [today]);
  const { skips, completions } = useMemo(() => {
    const randomOccurrences = occurrences.sort(() => Math.random() - 0.5);
    const amountOfSkips = Math.floor(randomOccurrences.length * 0.1); // 10% of occurrences

    return {
      skips: randomOccurrences.slice(0, amountOfSkips),
      completions: randomOccurrences.slice(
        amountOfSkips,
        randomOccurrences.length * 0.7,
      ), // 60% of occurrences
    };
  }, [occurrences]);

  const startDate = addDays(today, -7);
  const endDate = endOfDay(today);

  return (
    <HabitInsightsCard
      habit={habit}
      startDate={startDate}
      endDate={endDate}
      entries={entries}
      currentValue={currentValue}
      target={target}
      weekStartsOn={user?.settings?.startOfWeek ?? WeekDays.Monday}
      skips={skips}
      completions={completions}
      calendarOccurrences={occurrences}
      isPreview
    />
  );
};
