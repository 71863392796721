import { endOfDay, isAfter, isBefore, isSameDay } from 'date-fns';
import { useCallback } from 'react';
import { useToday } from 'shared/contexts/today';
import { useUpdateTaskMutation } from 'shared/hooks/queries/use-update-task-mutation';
import { useTrackEvents } from 'shared/hooks/use-track-events';
import {
  ActionEvents,
  taskTypeToTrackingTaskType,
} from 'shared/services/tracking';
import { Habit } from 'shared/types/habit';
import { RepeatingTask } from 'shared/types/repeating-task';
import { Timestamp } from 'shared/types/timestamp';

export const useCompleteHabitDate = () => {
  const today = useToday();
  const { mutate: updateTask } = useUpdateTaskMutation();
  const track = useTrackEvents();

  return useCallback(
    (
      habit: Habit | RepeatingTask,
      date: Timestamp = new Date(),
      // when habit-overview is updated, newImplementation should be removed and everything should work like that
      newImplementation = false,
    ) => {
      if (isAfter(date, endOfDay(today))) {
        return;
      }

      if (newImplementation) {
        // sort the schedules so we are sure that they are in order
        const sortedSchedules = habit.schedules.toSorted(
          (scheduleA, scheduleB) =>
            scheduleA.startDate.getTime() - scheduleB.startDate.getTime(),
        );

        const newSchedules = sortedSchedules.map((schedule, index) => {
          // filter the date out of all schedule completions and skips
          // todo: habit-failures - filter failures as well
          const completions =
            schedule.completions?.filter(
              (completion) => !isSameDay(date, completion),
            ) ?? [];
          const skips =
            schedule.skips?.filter((skip) => !isSameDay(date, skip)) ?? null;

          // get the previous schedule to see if the date falls between this and the previous schedule
          const prevSchedule = sortedSchedules[index - 1];

          const startPointSchedule =
            prevSchedule?.endDate ?? schedule.startDate;

          // check if the date is after the schedule startPoint and before the schedule endDate
          if (
            isBefore(date, startPointSchedule) ||
            (schedule.endDate && isAfter(date, schedule.endDate))
          ) {
            return { ...schedule, completions, skips };
          }

          // if not outside the range, add the completion (if it wasn't an uncomplete)
          if (completions.length === schedule.completions.length) {
            completions.push(date);
          }

          return { ...schedule, completions, skips };
        });

        // update the task and finish
        updateTask({ ...habit, schedules: newSchedules });
        return;
      }

      const scheduleToUpdate = habit.schedules.find(({ endDate }) => !endDate);
      if (!scheduleToUpdate) {
        return habit;
      }

      const filteredCompletions = scheduleToUpdate.completions.filter(
        (completion) => !isSameDay(completion, date),
      );
      if (filteredCompletions.length === scheduleToUpdate.completions.length) {
        filteredCompletions.push(date);

        // Uncomplete sub-tasks when completing a habit, so these tasks can be completed again in the future
        habit.childIds?.forEach((childId) => {
          updateTask({
            id: childId,
            completedAt: null,
          });
        });
      }

      // todo: habit-failures - filter failures as well
      scheduleToUpdate.skips =
        scheduleToUpdate.skips?.filter((skip) => !isSameDay(skip, date)) ??
        null;
      scheduleToUpdate.completions = filteredCompletions;

      track(ActionEvents.TaskComplete, {
        type: taskTypeToTrackingTaskType[habit.type],
      });

      updateTask(habit);
      return habit;
    },
    [today, track, updateTask],
  );
};
