import { useMemo } from 'react';
import { isBetween } from 'shared/lib/date-fns';
import { MetricEntry } from 'shared/types/goal-metric';
import { Timestamp } from 'shared/types/timestamp';

export type Options = {
  entries: MetricEntry[];
  startDate: Timestamp;
  endDate: Timestamp;
  target?: number;
};

export const useTimeframeDifference = ({
  entries,
  startDate,
  endDate,
  target,
}: Options) =>
  useMemo(() => {
    if (!target) {
      return 0;
    }
    const entriesCurrentTimeframe = entries
      .filter(({ date }) => isBetween(date, startDate, endDate))
      .sort((entryA, entryB) => entryA.date.getTime() - entryB.date.getTime());
    const firstEntry = entriesCurrentTimeframe[0];
    const lastEntry = entriesCurrentTimeframe.pop();

    if (!firstEntry || !lastEntry || firstEntry.value === lastEntry.value) {
      return 0;
    }

    const currentPercentage = (lastEntry.value / target) * 100;

    const startPercentage = (firstEntry.value / target) * 100;

    return currentPercentage - startPercentage || 0;
  }, [entries, target, startDate, endDate]);
