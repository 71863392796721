import { getSortedGoals, useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import { useMemo } from 'react';
import { useUser } from 'shared/hooks/use-user';
import { lifeAreaOtherID } from 'shared/types/orderable-section';
import { GoalSortingMode } from 'shared/types/sorting-mode';
import { sortItems } from 'shared/utils/sort-items';

import { useActiveGoals } from './use-active-goals';

export const useLifeAreaActiveGoalSections = () => {
  const user = useUser();
  const lifeAreas = useLocalizedLifeAreas();
  const allGoals = useGoals();

  const { goals } = useActiveGoals();

  const sections = useMemo(
    () =>
      [
        ...sortItems(
          lifeAreas,
          user?.settings?.lifeAreaSorting ?? [],
          'id',
        ).map(({ id }) => ({
          id,
          items: getSortedGoals(
            goals.filter(({ lifeAreaId }) => lifeAreaId === id),
            {
              mode: [user?.settings?.goalSortingMode].filter(
                Boolean,
              ) as GoalSortingMode[],
              order:
                user?.settings?.goalGroupSorting?.lifeArea?.sorting?.[id] ?? [],
              allGoals,
            },
          ),
        })),
        // other group for life areas as wel
        {
          id: lifeAreaOtherID,
          items: getSortedGoals(
            goals.filter(({ lifeAreaId }) => !lifeAreaId),
            {
              mode: [user?.settings?.goalSortingMode].filter(
                Boolean,
              ) as GoalSortingMode[],
              order:
                user?.settings?.goalGroupSorting?.lifeArea?.sorting?.['null'] ??
                [],
              allGoals,
            },
          ),
        },
      ].filter(({ items }) => !!items.length),
    [
      allGoals,
      goals,
      lifeAreas,
      user?.settings?.goalGroupSorting?.lifeArea?.sorting,
      user?.settings?.goalSortingMode,
      user?.settings?.lifeAreaSorting,
    ],
  );

  return {
    sections,
    goals,
  };
};
