import { format } from 'date-fns';
import { DateFormatterOptions } from 'shared/lib/recharts';

export const dateTickFormatterWeekDay = ({ date }: DateFormatterOptions) => {
  return format(date, 'iiiii');
};

export const dateTickFormatterDay = ({ date }: DateFormatterOptions) => {
  return format(date, 'd');
};

export const dateTickFormatterDayInMonth = ({ date }: DateFormatterOptions) => {
  return format(date, 'MMM d');
};

export const dateTickFormatterMonth = ({ date }: DateFormatterOptions) => {
  return format(date, 'LLLLL');
};
