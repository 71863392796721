import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Container = styled.div<{
  $selectedWidth?: number;
  $selectedOffset?: number;
  $isLight: boolean;
  $isFullWidth: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;

  padding: ${({ theme }) => theme.spacing(1)};
  width: ${({ $isFullWidth }) => ($isFullWidth ? '100%' : 'auto')};

  border-radius: ${({ theme }) => theme.spacing(3)};
  border: 1px solid ${({ theme }) => theme.palette.grey[100]};
  background: ${({ theme, $isLight }) =>
    $isLight ? theme.palette.background.default : 'transparent'};

  color: ${({ theme }) => theme.palette.text.primary};

  ${({ theme, $selectedWidth, $selectedOffset }) =>
    $selectedWidth &&
    $selectedOffset &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: ${theme.spacing(1)};

        height: calc(100% - ${theme.spacing(2)});
        width: ${$selectedWidth}px;

        transform: translateX(calc(${$selectedOffset}px - ${theme.spacing(1)}));
        background: ${({ theme }) => theme.palette.brown[100]};
        border-radius: ${({ theme }) => theme.spacing(2)};
        transition:
          transform 200ms ease-in-out,
          width 200ms ease-in-out;
      }
    `}
`;

export const Divider = styled.div`
  min-width: 1px;
  width: 1px;
  height: 1.2rem;

  margin: ${({ theme }) => `0 ${theme.spacing(2)}`};
  background: ${({ theme }) => theme.palette.divider};
`;

export const Button = styled.button<{ $selected: boolean }>`
  flex: 1;
  position: relative;
  ${({ theme }) => typographyToCss(theme.typography.buttonSmall)};
  height: 2.4rem;

  margin: 0;
  padding: ${({ theme }) => `0 ${theme.spacing(3)}`};

  cursor: pointer;
  border: none;
  background: transparent;
  transition: color 200ms ease-in-out;

  color: ${({ theme, $selected }) =>
    $selected ? theme.palette.text.primary : theme.palette.text.secondary};

  &:hover {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;
