import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataGridCreateRow } from 'shared/components/ui/data-grid-create-row';
import { SortMode } from 'shared/hooks/use-data-grid-sorting';
import { ID } from 'shared/types/id';

import { DataHeaderRow } from './data-header-row';
import { DataRow } from './data-row';
import * as Styled from './life-area-data-grid.style';
import { ColumnType, RowData } from './types';
import { useColumns } from './use-columns';

export type LifeAreaDataGridViewProps = {
  rows: RowData[];
  columnTypes?: ColumnType[];
  sortMode: SortMode;
  columnToSort?: ColumnType;
  onSortChange: (columnType: ColumnType) => void;
  onLifeArea?: (id: ID) => void;
  onEditLifeArea?: (id: ID) => void;
  onArchiveLifeArea?: (id: ID) => void;
  onDeleteLifeArea?: (id: ID) => void;
  onCreateLifeArea?: (e: React.MouseEvent) => void;
  hasDndRows?: boolean;
  showPremiumLabel?: boolean;
  hideHeader?: boolean;
  isFrozen?: boolean;
  isArchived?: boolean;
};

export const LifeAreaDataGridView: React.FC<LifeAreaDataGridViewProps> = ({
  rows,
  columnTypes,
  sortMode,
  columnToSort,
  onSortChange,
  onLifeArea,
  onEditLifeArea,
  onArchiveLifeArea,
  onDeleteLifeArea,
  onCreateLifeArea,
  hasDndRows,
  showPremiumLabel,
  hideHeader,
  isFrozen,
  isArchived,
}) => {
  const { t } = useTranslation();
  const columns = useColumns(columnTypes);

  return (
    <Styled.Container>
      {!hideHeader && (
        <DataHeaderRow
          columns={columns}
          sortMode={sortMode}
          onSortChange={onSortChange}
          columnToSort={columnToSort}
        />
      )}

      <Styled.Body>
        {rows.map((data) => (
          <Styled.BodyItem key={data.id}>
            <DataRow
              columns={columns}
              data={data}
              onLifeArea={onLifeArea}
              onEditLifeArea={data.isTemplate ? undefined : onEditLifeArea}
              onDeleteLifeArea={data.isTemplate ? undefined : onDeleteLifeArea}
              onArchiveLifeArea={onArchiveLifeArea}
              isSortable={!!hasDndRows}
              isFrozen={isFrozen}
              isArchived={isArchived}
            />
          </Styled.BodyItem>
        ))}

        {!!onCreateLifeArea && (
          <DataGridCreateRow
            label={t('general.labels.life-area-table.footer.create.label')}
            onClick={onCreateLifeArea}
            showPremiumLabel={showPremiumLabel}
          />
        )}
      </Styled.Body>
    </Styled.Container>
  );
};
