import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};

  padding: ${({ theme }) => `${theme.spacing(6)} ${theme.spacing(5)}`};

  border-radius: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) => theme.palette.background.default};
  box-shadow: ${({ theme }) => theme.shadowBox.menu};

  text-align: center;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const ButtonContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacing(2)};
`;
