import { scaleLinear } from '@visx/scale';
import { useMemo } from 'react';
import { Entry } from 'shared/lib/recharts';

export type Options = {
  data: Entry[];
  height: number;
  target?: number;
};

export const useYScale = ({ data, height, target }: Options) =>
  useMemo(() => {
    const highestValue = data.toSorted(
      (entryA, entryB) => entryB.value - entryA.value,
    )[0].value;

    const maxValue = Math.max(
      ...([1, highestValue, target].filter(Boolean) as number[]),
    );

    return scaleLinear({
      domain: [0, maxValue],
      range: [height, 0],
    });
  }, [height, data, target]);
