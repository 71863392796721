import { useLocalizedLifeAreas } from 'features/life-areas';
import { useMemo } from 'react';
import { useUser } from 'shared/hooks/use-user';
import { sortItems } from 'shared/utils/sort-items';

export const useInsightsLifeAreas = () => {
  const user = useUser();
  const lifeAreas = useLocalizedLifeAreas();

  return useMemo(() => {
    const filteredLifeAreas = lifeAreas.filter(
      ({ id, archivedAt }) =>
        !archivedAt &&
        (user?.settings?.insights?.showHiddenItems ||
          !user?.settings?.insights?.sections?.lifescan?.hiddenItems?.includes(
            id,
          )),
    );

    const sortedLifeAreas = sortItems(
      filteredLifeAreas,
      user?.settings?.lifeAreaSorting ?? [],
      'id',
    );

    return user?.settings?.insights?.sections?.lifescan?.order?.length
      ? sortItems(
          sortedLifeAreas,
          user.settings.insights.sections.lifescan.order,
          'id',
        )
      : sortedLifeAreas;
  }, [
    lifeAreas,
    user?.settings?.insights?.sections?.lifescan?.hiddenItems,
    user?.settings?.insights?.sections?.lifescan?.order,
    user?.settings?.insights?.showHiddenItems,
    user?.settings?.lifeAreaSorting,
  ]);
};
