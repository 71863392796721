import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Paths } from 'shared/routes';
import { ID } from 'shared/types/id';
import { replaceUrlParams } from 'shared/utils/replace-url-params';
import { useTheme } from 'styled-components';

import * as Styled from './cta-container.style';

export type CtaContainerProps = {
  id: ID;
  onClick?: (id: ID) => void;
  isFrozen?: boolean;
  children: React.ReactNode;
};

export const CtaContainer: React.FC<CtaContainerProps> = ({
  id,
  onClick,
  isFrozen,
  children,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  if (isFrozen || onClick) {
    const _onClick = onClick ? () => onClick(id) : undefined;
    return (
      <Styled.ClickContainer onClick={_onClick}>
        {children}
      </Styled.ClickContainer>
    );
  }

  if (theme.isTouchDevice) {
    const _onClick = () => navigate(replaceUrlParams(Paths.GoalDetail, { id }));
    return (
      <Styled.ClickContainer onClick={_onClick}>
        {children}
      </Styled.ClickContainer>
    );
  }

  return (
    <Styled.LinkContainer to={replaceUrlParams(Paths.GoalDetail, { id })}>
      {children}
    </Styled.LinkContainer>
  );
};
