import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { Tooltip } from 'shared/components/ui/tooltip';
import { useForwardedRef } from 'shared/hooks/use-forwarded-ref';

import * as Styled from './slider-buttons.style';

export type Option<Value extends string> = {
  label: string;
  value: Value;
  tooltip?: string;
};

type ButtonProps = {
  label: string;
  value: string;
  tooltip?: string;
  selected: boolean;
  onClick: (value: string) => void;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ label, value, tooltip, selected, onClick }, ref) => {
    const _ref = useForwardedRef(ref);

    const _onClick = () => onClick(value);

    return (
      <>
        <Styled.Button ref={_ref} onClick={_onClick} $selected={selected}>
          {label}
        </Styled.Button>
        {!!tooltip && <Tooltip label={tooltip} referenceElement={_ref} />}
      </>
    );
  },
);

export type SliderButtonsProps<Value extends string> = {
  optionGroups: Option<Value>[][];
  value?: Value;
  onOption: (value: Value) => void;
  isLight?: boolean;
  isFullWidth?: boolean;
};

export const SliderButtons = <Value extends string>({
  optionGroups,
  value,
  onOption,
  isLight,
  isFullWidth,
}: SliderButtonsProps<Value>): ReturnType<React.FC> => {
  const allOptions = useMemo(() => optionGroups.flat(1), [optionGroups]);
  const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);

  const [selectedDimensions, setSelectedDimensions] = useState<{
    width: number;
    offsetLeft: number;
  } | null>(null);

  useEffect(() => {
    const selectedIndex = allOptions.findIndex(
      (option) => option.value === value,
    );
    if (selectedIndex !== -1 && buttonRefs.current[selectedIndex]) {
      const { offsetWidth: width, offsetLeft } =
        buttonRefs.current[selectedIndex]!;
      setSelectedDimensions({ width, offsetLeft });
    }
  }, [value, allOptions]);

  return (
    <Styled.Container
      $selectedWidth={selectedDimensions?.width}
      $selectedOffset={selectedDimensions?.offsetLeft}
      $isLight={!!isLight}
      $isFullWidth={!!isFullWidth}
    >
      {optionGroups.map((options, index) => (
        <React.Fragment key={`group-${index}`}>
          {options.map((option) => {
            const optionIndex = allOptions.findIndex(
              ({ value }) => value === option.value,
            );
            const buttonRef = (el: HTMLButtonElement | null) =>
              (buttonRefs.current[optionIndex] = el);

            const _onOption = () => onOption(option.value);

            return (
              <Button
                key={option.value}
                label={option.label}
                value={option.value}
                tooltip={option.tooltip}
                selected={option.value === value}
                onClick={_onOption}
                ref={buttonRef}
              />
            );
          })}
          {index !== optionGroups.length - 1 && <Styled.Divider />}
        </React.Fragment>
      ))}
    </Styled.Container>
  );
};
