import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Locales } from 'shared/assets/localization';
import { useCreatePushNotificationMutation } from 'shared/hooks/queries/use-create-push-notification-mutation';
import { usePushNotificationMessageQuery } from 'shared/hooks/queries/use-push-notification-message-query';
import { useSetPushNotificationMessageMutation } from 'shared/hooks/queries/use-set-push-notification-message-mutation';
import { useNotificationPermission } from 'shared/hooks/use-notification-permission';
import { useUser } from 'shared/hooks/use-user';
import { PushNotificationMessageRequestState } from 'shared/services/local-storage';

import { RequestNotificationMessage } from './request-notification-message';
import {
  RequestPushNotificationsContext,
  RequestPushNotificationsContextProps,
} from './request-push-notifications-context';

export type RequestPushNotificationsProviderProps = {
  children: React.ReactNode;
};

export const RequestPushNotificationsProvider: React.FC<
  RequestPushNotificationsProviderProps
> = ({ children }) => {
  const { i18n } = useTranslation();
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [permission] = useNotificationPermission();

  const user = useUser();
  const { data: messageState } = usePushNotificationMessageQuery(user?.uid);
  const { mutate: setMessageState } = useSetPushNotificationMessageMutation();
  const { mutate: requestPermission } = useCreatePushNotificationMutation();

  const closeMessage = () => {
    if (!user?.uid) {
      return;
    }

    setMessageState({
      uid: user?.uid,
      state: PushNotificationMessageRequestState.Asked,
    });

    setMessageOpen(false);
  };

  const onSubmit = async () => {
    if (!user?.uid) {
      return;
    }
    requestPermission({
      userId: user.uid,
      locale: user.settings?.language ?? (i18n.language as Locales),
    });
    setMessageState({
      uid: user.uid,
      state: PushNotificationMessageRequestState.Asked,
    });
    setMessageOpen(false);
  };

  const value = useMemo<RequestPushNotificationsContextProps>(() => ({}), []);

  useEffect(() => {
    if (
      permission === 'prompt' &&
      messageState !== PushNotificationMessageRequestState.Asked
    ) {
      setMessageOpen(true);
    }
  }, [messageState, permission]);

  return (
    <RequestPushNotificationsContext.Provider value={value}>
      {children}
      {messageOpen && (
        <RequestNotificationMessage
          onCancel={closeMessage}
          onSubmit={onSubmit}
        />
      )}
    </RequestPushNotificationsContext.Provider>
  );
};
